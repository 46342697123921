/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import { Component } from 'react';
import Axios from 'axios';
import { withRouter } from 'utilities/withRouterWrapper';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as UploadFilesActions from 'actions/UploadFilesActions';
import UploadedFilesList from 'components/UploadedFiles/UploadedFilesList';
import {
  ACCOUNT,
  ACCOUNT_BANK_ACCOUNTS,
  MIXPANEL_ACCOUNT_PAGE_EVENT,
} from 'constants/constants';
import {
  getCpaPreferences,
  isUserEmployee,
  sortArraybyProperty,
  getFirmType,
  getCurrentPath,
  getStorageData,
} from 'utilities/utils';
import { trackMixpanelEvent } from 'Mixpanel/mixpanelfn';
import HeaderTabs from 'common/HeaderTabs';
import InFocus from 'components/Infocus';
import EmailsSection from 'components/Email/components/EmailsSection';
import NoteList from 'components/Notes/NoteList';
import TaskList from 'components/Tasks/TaskList';
import AccountMessageList from 'components/Messages/Inbox/UserMessageList';
import Billing from 'components/Billing';
import TimelineActivity from 'components/TimelineActivity';
import { withStorageData } from 'utilities/withStorageData';
import AccountRelationshipPage from './AccountRelationship/AccountRelationshipPage';
import AccountInfo from './AccountInfo';
import AccountLoginsList from './AccountLogins/AccountLoginsList';
import Payroll from './Payroll/Payroll';
import {
  showIndividualAdditionInformationForm,
  decryptBankAccounts,
} from './AccountCreation/AccountUtilities';
import { alertsSortedByPriority } from './AlertMessages';
import InvoiceAlertBox from './InvoiceAlert';
import {
  individualAccountPageTabs,
  accountPageTabs,
  liteAccountTabs,
  liteClientAccountTabs,
  clientAccountPageIfIsBillingContact,
  clientIndividualAccountPageTabs,
  clientAccountPageTabs,
} from './AccountTabsData';

const getTabs = (isBillingContact) => {
  if (getFirmType() === 'liscio-lite') {
    if (isUserEmployee()) {
      return liteAccountTabs;
    }
    return liteClientAccountTabs;
  }
  if (isUserEmployee()) {
    return individualAccountPageTabs;
  }
  if (!isUserEmployee() && isBillingContact) {
    return clientAccountPageIfIsBillingContact;
  }
  return [clientIndividualAccountPageTabs[0]];
};

class AccountPage extends Component {
  constructor(props) {
    super(props);

    const { params } = props;

    this.state = {
      activeTabIndex: 0,
      tabs: getTabs(false),
      loading: true,
      accountId: params ? params.id : undefined,
      // TODO: __HOMEDATA-AUDIT
      isEmployee: getStorageData().is_employee,
      alertMessage: null,
      isAccountOwner: false,
      isBillingContact: false,
      accountQbCustomerId: null,
    };
  }

  UNSAFE_componentWillMount() {
    const { isEmployee } = this.state;
    const { params, location } = this.props;

    const accountId = params ? params.id : undefined;

    if (accountId && isEmployee) {
      let activeTabName;
      if (params && params.email_id && params.email_id !== '') {
        activeTabName =
          location.pathname && location.pathname.split('/').slice(-2)[0];
      } else {
        activeTabName =
          location.pathname && location.pathname.split('/').slice(-1)[0];
      }
      const checkActiveTab =
        activeTabName === 'invoices' || activeTabName === 'recurring'
          ? 'billing/invoices'
          : activeTabName;
      const _activeTabIndex = accountPageTabs.filter(
        (item) => item.value === checkActiveTab,
      );
      if (_activeTabIndex.length === 1) {
        this.setState({ activeTabIndex: _activeTabIndex[0].index });
      } else if (_activeTabIndex.length === 0) {
        this.setState({ activeTabIndex: 0 });
      }
    }
  }

  componentDidMount() {
    this.getAccountData(this.props);
  }

  getAccountData = (propData) => {
    trackMixpanelEvent(MIXPANEL_ACCOUNT_PAGE_EVENT);
    document.title = 'Account';
    const accountId =
      propData.match && propData.params ? propData.params.id : undefined;
    if (accountId) {
      Axios.get(`${ACCOUNT}/${accountId}`).then((res) => {
        this.setState({ loading: false });
        if (res.status === 200 && res.data.status === 200 && res.data.data) {
          const accountDetails = res.data.data;
          const { is_billing_contact } = accountDetails;
          this.setState({ isBillingContact: is_billing_contact }, () => {
            const { isBillingContact } = this.state;
            this.setState({
              tabs: getTabs(isBillingContact),
            });
          });
          const { account, ...additionalAccountInfo } = accountDetails;
          this.extractAlertMessages(additionalAccountInfo);
          const { name } = account;
          const { qb_customer_id } = accountDetails;
          let { hash } = propData.location;
          const { pathname } = propData.location;
          if (propData.location.hash.includes('/notes')) {
            hash = '/notes';
          } else if (propData.location.hash.includes('/emails')) {
            hash = '/emails';
          }
          const isAccountIndividual = showIndividualAdditionInformationForm(
            accountDetails.account,
            getCpaPreferences().individual_entity_type,
          );
          if (getFirmType() === 'liscio-lite') {
            if (
              propData.location &&
              propData.location.hash &&
              propData.location.hash.length
            ) {
              const tabIndex = this.state.tabs.findIndex(
                (each) => `#${each.value}` === propData.location.hash,
              );
              this.changeActiveTab(this.state.tabs[tabIndex]);
            }
          } else if (this.state.isEmployee) {
            const accPageTabs = accountPageTabs;
            const indPageTabs = individualAccountPageTabs;
            let tabList = [];

            tabList = isAccountIndividual
              ? indPageTabs
              : getFirmType() !== 'cpa'
              ? indPageTabs
              : accPageTabs;

            this.setState({ tabs: tabList }, () => {
              if (
                propData.location &&
                propData.location.hash &&
                propData.location.hash.length
              ) {
                let tabIndex = -1;
                tabIndex =
                  propData.location.hash.indexOf('#multiple') > -1
                    ? this.state.tabs.findIndex(
                        (each) => `/${each.value}` === '/files',
                      )
                    : this.state.tabs.findIndex(
                        (each) => `/${each.value}` === hash,
                      );
                if (propData.location.hash.includes('#notes?id')) {
                  tabIndex = this.state.tabs.findIndex(
                    (each) => each.value === 'notes',
                  );
                }
                this.changeActiveTab(this.state.tabs[tabIndex]);
              }
            });
          } else {
            let { tabs } = this.state;
            let isAccountOwner = false;
            if (accountDetails.is_account_owner) {
              tabs = isAccountIndividual
                ? clientIndividualAccountPageTabs
                : getFirmType() !== 'cpa'
                ? clientIndividualAccountPageTabs
                : clientAccountPageTabs;
              isAccountOwner = true;
            }
            this.setState(
              {
                tabs,
                isAccountOwner,
              },
              () => {
                const pathArr = pathname.split('/');
                const tabSelect = pathArr[3];
                const tabIndex = this.state.tabs.findIndex((each) =>
                  each.value.includes(tabSelect),
                );
                this.changeActiveTab(this.state.tabs[tabIndex]);
              },
            );
          }
          this.setState({
            name,
            accountQbCustomerId: qb_customer_id,
            data: accountDetails,
          });
          document.title = `Account - ${name}`;
        } else {
          this.props.navigate('/accounts');
        }
      });
      Axios.get(`${ACCOUNT_BANK_ACCOUNTS}/${accountId}`).then((res) => {
        if (res.status === 200 && res.data && res.data.data) {
          this.setState({
            bankAccountData: sortArraybyProperty(
              decryptBankAccounts(res.data.data),
              'id',
            ),
          });
        }
      });
    }
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { match, params } = this.props;
    const { isEmployee } = this.state;
    const { location } = nextProps;
    const accountId = match && params ? params.id : undefined;
    let activeTabName = 'details';

    if (nextProps?.params?.id !== accountId) {
      this.setState({ accountId: nextProps.params.id, loading: true });
      this.getAccountData(nextProps);
    }

    if (accountId && isEmployee) {
      if (params && nextProps.params.email_id) {
        activeTabName =
          location.pathname && location.pathname.split('/').slice(-2)[0];
      } else {
        activeTabName =
          location.pathname && location.pathname.split('/').slice(-1)[0];
      }

      const checkActiveTab =
        activeTabName === 'invoices' || activeTabName === 'recurring'
          ? 'billing/invoices'
          : activeTabName;

      const _activeTabIndex = accountPageTabs.filter(
        (item) => item.value === checkActiveTab,
      );

      if (_activeTabIndex.length === 1) {
        this.setState({ activeTabIndex: _activeTabIndex[0].index });
      } else if (_activeTabIndex.length === 0) {
        this.setState({ activeTabIndex: 0 });
      }
    }
  }

  extractAlertMessages = (accountAlertInfo) => {
    const alertMessage =
      alertsSortedByPriority.find((message) => !!accountAlertInfo[message]) ||
      null;
    this.setState({ alertMessage });
  };

  changeActiveTab = (tabDetails) => {
    let activeTabDetails = tabDetails;
    if (!tabDetails) {
      activeTabDetails = this.state.tabs[0];
    }
    this.setState({
      activeTabIndex: activeTabDetails.index,
    });
    let val = `/${activeTabDetails.value}`;
    if (this.props.location.hash.indexOf('/multiple') > -1) {
      val = this.props.location.hash;
    } else if (
      this.props.location.hash.includes('/emails?id') &&
      this.props.location.hash.includes(activeTabDetails.value)
    ) {
      val = this.props.location.hash;
    } else if (this.props.location.hash.includes('#notes?id')) {
      val = `/notes${this.props.location.hash}`;
    }
    this.props.navigate(`/accountdetails/${this.state.accountId}${val}`, {
      replace: true,
    });
  };

  handleAllAccountsBtn = () => {
    let url = localStorage.getItem('allAccountsButtonUrl');
    if (!url && url === null) {
      url = '/accounts';
    }
    this.props.UploadFilesActions.handleFilesData({});
    this.props.navigate(url);
  };

  render() {
    const {
      activeTabIndex,
      tabs,
      alertMessage,
      accountId,
      isAccountOwner,
      bankAccountData,
      data,
      name,
      accountQbCustomerId,
      loading,
    } = this.state;
    const { location, navigate } = this.props;
    const path = getCurrentPath(location);

    return (
      <div className="AccountDetails">
        {loading ? <div id="loading" /> : null}
        <header>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="javascript:void(0)" onClick={this.handleAllAccountsBtn}>
                All accounts
              </a>
            </li>
            <li className="breadcrumb-item">{name || null}</li>
          </ul>
        </header>
        <div>
          {!!alertMessage && (
            <InvoiceAlertBox
              alertMessage={alertMessage}
              accountId={accountId}
              isAccountOwner={isAccountOwner}
            />
          )}

          <HeaderTabs
            data={tabs}
            activeTab={tabs.findIndex((each) => each.index === activeTabIndex)}
            handleClick={(index, tabDetails) =>
              this.changeActiveTab(tabDetails)
            }
            customClasses="navtabs-bgColor navtabs-small justify-content-center"
          />
        </div>
        <div className="AccountPageContainer">
          {path === '/accountdetails/:id/activity' ? (
            <TimelineActivity requestType="account" id={accountId} />
          ) : null}
          {(path === '/accountdetails/:id' ||
            path === '/accountdetails/:id/details') &&
          data ? (
            <AccountInfo
              accountId={accountId}
              data={data}
              bankaccountsdata={bankAccountData}
            />
          ) : null}
          {isUserEmployee() &&
          path === '/accountdetails/:id/inFocus' &&
          data ? (
            <InFocus
              accountName={data.account.name}
              accountID={data.account.id}
              qbCustomerId={accountQbCustomerId}
              data={data}
              bankaccountsdata={bankAccountData}
            />
          ) : null}
          {path === '/accountdetails/:id/relationships' ? (
            <AccountRelationshipPage
              id={accountId}
              accountData={data && data.account}
            />
          ) : null}
          {path === '/accountdetails/:id/tasks' ? (
            <TaskList accountId={accountId} accountData={data} />
          ) : null}
          {path === '/accountdetails/:id/messages' ? (
            <AccountMessageList
              accountId={accountId}
              accountData={data}
              name={name}
            />
          ) : null}
          {path === '/accountdetails/:id/notes' ? (
            <NoteList notesof="account" id={accountId} accountData={data} />
          ) : null}
          {path === '/accountdetails/:id/payroll' && data ? (
            <Payroll data={data} id={accountId} />
          ) : null}
          {path === '/accountdetails/:id/files' ? (
            <UploadedFilesList accountId={accountId} accountData={data} />
          ) : null}
          {path === '/accountdetails/:id/logins' ? (
            <AccountLoginsList data={data} accountId={accountId} />
          ) : null}
          {path === '/accountdetails/:id/emails' ||
          path === '/accountdetails/:id/emails/:email_id' ? (
            <EmailsSection
              accountId={accountId}
              viewContext="account"
              props={this.props}
              location={location}
              navigate={navigate}
            />
          ) : null}
          {path === '/accountdetails/:id/billing/invoices' ||
          path === '/accountdetails/:id/billing/recurring' ? (
            <Billing
              accountId={accountId}
              accountName={name}
              qbCustomerId={accountQbCustomerId}
              viewContext="account"
              billingTabs={[
                {
                  value: 'invoices',
                  label: 'Invoices',
                  url: `/accountdetails/${accountId}/billing/invoices`,
                },
                {
                  value: 'recurring',
                  label: 'Recurring',
                  url: `/accountdetails/${accountId}/billing/recurring`,
                },
              ]}
              props={this.props}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  UploadFilesActions: bindActionCreators(UploadFilesActions, dispatch),
});
export default withStorageData(
  withRouter(connect(null, mapDispatchToProps)(AccountPage)),
);
