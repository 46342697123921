import React, { Component } from 'react';
import HeaderTabs from 'common/HeaderTabs_updated';
import { withStorageData } from 'utilities/withStorageData';
import TextLogs from './TextLogs';
import AddEditTags from './AddEditTags';
import Security from '../../ui/Admin/Security';

const PreferencesTabs = [
  {
    label: 'Security',
    value: 'security',
    tab: <Security/>
  },
  {
    label: 'Tags',
    value: 'tags',
    tab: <AddEditTags/>
  },
  {
    label: 'Text Logs',
    value: 'textLogs',
    tab: <TextLogs/>
  },
];

class Preferences extends Component {
  constructor(props) {
    super(props);
    const parsedUrl = new URL(window.location.href);
    const tab = parsedUrl.searchParams.get('tab');
    let tabIndex = 0;
    if (tab) {
      tabIndex = PreferencesTabs.findIndex((each) => each.value === tab);
    }
    this.state = { activeTab: tabIndex === undefined ? 0 : tabIndex };
  }

  componentDidMount() {
    document.title = 'Preferences';
    const { activeTab } = this.state;
    if (activeTab && PreferencesTabs[activeTab]) {
      this.props.navigate(
        `/preferences?tab=${PreferencesTabs[activeTab].value}`,
      );
    }
  }

  changeActiveTab = (key) => {
    this.setState({ activeTab: key });
    this.props.navigate(`/preferences?tab=${PreferencesTabs[key].value}`);
  };

  render() {
    const { activeTab } = this.state;
    return (
      <div id="content">
        <header className="pb-0 pt-0">
          <HeaderTabs
            data={PreferencesTabs}
            activeTab={activeTab}
            handleClick={this.changeActiveTab}
          />
        </header>
        <div>{PreferencesTabs[activeTab].tab}</div>
      </div>
    );
  }
}

export default withStorageData(Preferences);
