import axios from 'axios';
import {
  getCookie,
  AlertMessage,
  getSiteHostname,
  getSiteCompanyName,
} from 'utilities/utils';
import {
  APP_SUBDOMAINS,
  FIRM_SITE_URL,
  FIRM_SUBDOMAINS,
  HOME_API_PATH,
  LOGIN_WITH_MAGIC_LINK_API_PATH,
  MIXPANEL_LOGIN_EVENT,
  SWITCH_CPA,
} from 'constants/constants';
import {
  registerSuperProperties,
  trackMixpanelEvent,
} from 'Mixpanel/mixpanelfn';
import { getAuthSession, setAuthSession } from 'auth/session';

const sendLoginEvent = (type) => {
  if (getAuthSession()) {
    // TODO: __HOMEDATA-AUDIT
    const request = axios.get(HOME_API_PATH);
    request.then((response) => {
      const stored = response.data.data;
      stored.is_multiple_cpa = response.data.is_multiple_cpa;
      stored.multiple_cpa_data = response.data.cpa_data;
      localStorage.setItem(
        'release',
        JSON.stringify(response.data.release_version),
      );
      localStorage.setItem(`${getSiteHostname()} data`, JSON.stringify(stored));
      registerSuperProperties({});
      trackMixpanelEvent(MIXPANEL_LOGIN_EVENT, { type });
    });
  }
};

export const Authentication = async (component, values, remember_flag, api) => {
  component.setState({ loading: true });
  const errs = component.state.errors;

  const subdomain = getSiteCompanyName();
  const loginConfig = {
    api,
    subdomain,
    isAppSite: APP_SUBDOMAINS.includes(subdomain),
    isFirmSite: FIRM_SUBDOMAINS.includes(subdomain),
    hasSelectedCpa:
      values.cpa_id ||
      values.user?.cpa_id ||
      values.company_name ||
      values?.user?.company_name,
  };

  /**
   * This is a workaround for the new split auth working with multi-cpa mfa
   * users since we no longer get a token after mfa verification, but before
   * the user selects chich CPA to authenticate into. We store the initial
   * login response in state and conditionall make a request to switch cpa
   * if they choose a CPA they're not already authenticated to. These auth
   * responses are then used to determine which site the user gets routed to.
   */
  if (
    component.state.multiCpaMfaAuthToken &&
    component.state.multiCpaHomeData
  ) {
    // Do we need to switch cpas?
    const { multiCpaHomeData, multiCpaMfaAuthToken, multiCpaMfaResponse } =
      component.state;

    if (
      values?.user?.company_name &&
      multiCpaHomeData?.data?.company_url?.includes(values.user.company_name)
    ) {
      loginConfig.response = multiCpaMfaResponse;
    } else {
      // Switch CPA
      const switchToCpa = multiCpaHomeData.cpa_data.find(
        (cpa) =>
          cpa.custom_liscio_url.replace(/^https:\/\//, '') ===
          values.user.company_name,
      );
      const switchCpaResponse = await axios.post(
        SWITCH_CPA,
        {
          cpa_id: switchToCpa.id,
        },
        {
          headers: { Authorization: multiCpaMfaAuthToken },
        },
      );
      if (!switchCpaResponse?.data?.success) {
        // Switch CPA failure
        if (switchCpaResponse?.data?.message === 'No records found') {
          errs.fail =
            "Your account is not active... Please contact your firm's Liscio admin";
        } else {
          errs.fail =
            "Unable to login...please contact your firm's Liscio admin";
        }
        component.setState({ errors: errs, loading: false });
        return;
      }
      // Switch CPA Success
      loginConfig.response = switchCpaResponse;
    }
  }

  /**
   * Only make a request if we don't already have one from
   * multi-cpa mfa login above (which is most of the time)
   */
  if (!loginConfig.response) {
    loginConfig.response = await axios.post(loginConfig.api, values);
  }
  const { response } = loginConfig;
  const resdata = response.data;

  // Set initial login response cpa_data
  loginConfig.cpa_data = resdata.cpa_data;

  /**
   * After a successful login we need to fetch user homeData to determine
   * what to do with the user. This includes multi-cpa login.
   * This should only apply to the app/fim sites
   */
  if (resdata.auth_token) {
    try {
      const { data: homeData } = await axios.get(HOME_API_PATH, {
        headers: { Authorization: resdata.auth_token },
      });
      loginConfig.homeData = homeData;

      // Set current user role
      loginConfig.loginRole = homeData.data.is_employee ? 'firm' : 'client';

      // Set multi-cpa data when multi-cpa user and not explicitly set
      if (
        homeData.cpa_data &&
        homeData.cpa_data.length > 1 &&
        !values?.user?.company_name
      ) {
        loginConfig.cpa_data = homeData.cpa_data;
      }
    } catch {
      errs.fail = 'Network error. Please try again';
      component.setState({ errors: errs, loading: false });
    }
  }

  if (response.status === 202) {
    // Handle MFA required
    component.setState({
      loading: false,
      component: 'passCode',
      heading: 'Enter Passcode',
    });
  } else if (
    response.status === 200 &&
    loginConfig.cpa_data &&
    loginConfig.cpa_data.length > 0 &&
    !loginConfig.hasSelectedCpa
  ) {
    // Handle multi-cpa user on firm/app sites
    component.setState({
      loading: false,
      component: 'workspace',
      confirm: true,
      cpaData: loginConfig.cpa_data,
      accessToken: resdata.token ? resdata.token : '',
      // Set multi-cpa MFA state here if necessary
      // NOTE: This is terribly hacky, but that's the nature of all this auth already...
      multiCpaMfaResponse: response,
      multiCpaMfaAuthToken: resdata.auth_token,
      multiCpaHomeData: loginConfig.homeData,
    });
  } else if (response.status === 200) {
    component.setState({ errors: null });
    errs.success = 'Logging In ...';
    component.setState({ errors: errs });
    const authToken = response.data.auth_token;
    let ref = '/';
    if (localStorage.getItem('path')) {
      ref = localStorage.getItem('path');
      localStorage.clear();
    }

    setAuthSession({ auth_token: authToken });

    // send mixpanel login event
    if (values.user) {
      sendLoginEvent(
        values.user.login_type ? values.user.login_type : 'passcode',
      );
    }

    // Redirect user as needed after login success
    if (loginConfig.loginRole === 'firm' && !loginConfig.isFirmSite) {
      // Redirect firm users on non-firm user site
      const firmRedirect = `${FIRM_SITE_URL}`;
      window.location.href = firmRedirect;
    } else if (
      loginConfig.loginRole === 'client' &&
      (loginConfig.isAppSite || loginConfig.isFirmSite)
    ) {
      const clientRedirect = `${loginConfig.homeData.data.company_url}`;
      window.location.href = clientRedirect;
    } else if (
      getCookie(false, 'redirect_url') &&
      getCookie(false, 'redirect_url').length > 0
    ) {
      component.props.navigate(
        `/${getCookie(false, 'redirect_url').split('/').pop()}`,
      );
    } else {
      // Do normal app navigate
      component.props.navigate(ref);
    }
  } else {
    errs.fail = response.data.message;
    component.setState({ errors: errs, loading: false });
  }
};

// api for send message link and forgot password
export const LoginApis = (component, values, apiName) => {
  const request = axios.post(apiName, values);
  component.setState({ loading: true });
  request.then((response) => {
    const res = response.data;
    if (res.status === 200 && res.cpa_data && res.cpa_data.length > 0) {
      component.setState({
        loading: false,
        confirm: true,
        cpaData: res.cpa_data,
        cpaSelected: false,
      });
    } else if (res.status === 200) {
      component.setState(
        {
          loading: false,
          email: '',
          password: '',
          confirmMessage: res.message,
          cpaSelected: true,
        },
        () => {
          component.setState({ confirm: true });
        },
      );
    } else {
      AlertMessage('error', res.message, 3000);
      component.setState({ loading: false });
    }
  });
};

export const remoteMfaLogin = (component, data) => {
  component.setState({ loading: true });
  const request = axios.post(LOGIN_WITH_MAGIC_LINK_API_PATH, data);
  component.setState({ loading: false });
  request
    .then((res) => {
      if (res.data.auth_token) {
        const authToken = res.data.auth_token;
        setAuthSession({ auth_token: authToken });
        component.props.navigate('/dashboard');
      } else {
        const error = {};
        error.mail = res.data.message;
        component.setState({ errors: error });
      }
    })
    .catch(() => {
      const error = {};
      error.mail = 'Some error occured';
      component.setState({ errors: error });
    });
};
