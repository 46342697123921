import React, { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import TrustedDomains from './TrustedDomains';
import EnforcedMfaSetting from './EnforcedMfaSetting';

export default function Security () {
  const [isLoadingTrustedDomains, setIsLoadingTrustedDomains] = useState(true);
  const [isLoadingMfaSetting, setIsLoadingMfaSetting] = useState(true);
  const { mfaToggle } = useFlags();

  return (
    <>
      {isLoadingTrustedDomains && isLoadingMfaSetting ? <div id="loading" /> : null}

      <div id="content">
        { mfaToggle && <EnforcedMfaSetting setIsLoading={setIsLoadingMfaSetting}/> }
        <TrustedDomains setIsLoading={setIsLoadingTrustedDomains}/>
      </div>
    </>
  )
}
