import { Box } from '@mui/material';
import { RequestType, WorkflowRequestStatus } from 'gql/graphql';
import { useNavigate } from 'react-router-dom-latest';
import {
  IN_APP,
  REQUESTS_PAGE_ROUTES,
  REQUESTS_PAGE_TITLES,
} from 'components/Requests/requests.constants';
import { useEffect } from 'react';
import { PendingRequestsTable } from 'components/Requests/components/Tables/PendingRequestsTable';
import { useWorkflowRequestsQuery } from 'hooks/workflow-request-hooks';
import { usePagination } from 'components/Requests/components/RequestsBuilder/hooks/pagination';
import { useRequestsState } from 'components/Requests/state/request-state';
import { useMixpanel } from 'Mixpanel/Mixpanel';
import { MIXPANEL_REQUEST_LIST_VIEW_EVENT } from 'constants/constants';

export const PendingRequestsView = () => {
  useEffect(() => {
    document.title = REQUESTS_PAGE_TITLES.pending;
  }, []);
  const { paginationModel, setPaginationModel } = usePagination();
  const sortStatus = useRequestsState(
    (state) => state.sortStatus?.[WorkflowRequestStatus.Submitted],
  );
  const filters = useRequestsState((state) => state.filters);

  const pendingWorkflowRequest = useWorkflowRequestsQuery({
    status: WorkflowRequestStatus.Submitted,
    perPage: paginationModel.pageSize,
    page: paginationModel.page + 1,
    filterData: {
      assigneeIds: filters[WorkflowRequestStatus.Submitted].assignedTo,
      ownerIds: filters[WorkflowRequestStatus.Submitted].owners,
    },
    ...sortStatus,
  });

  const navigate = useNavigate();

  const onWorkflowClick = (id: string) => {
    navigate(
      `${REQUESTS_PAGE_ROUTES.requests}/${id}?type=${RequestType.Request}`,
      {
        state: { from: IN_APP },
      },
    );
  };

  const mixpanel = useMixpanel();
  useEffect(() => {
    if (mixpanel) {
      mixpanel.track(MIXPANEL_REQUEST_LIST_VIEW_EVENT, { filter: 'pending' });
    }
  }, [mixpanel]);

  return (
    <Box>
      <PendingRequestsTable
        query={pendingWorkflowRequest}
        onRowClick={onWorkflowClick}
        paginationModel={paginationModel}
        setPaginationModel={setPaginationModel}
      />
    </Box>
  );
};
