import { AlertMessage } from 'utilities/utils';
import { parseApiErrorType } from './parseApiErrorType';
import { parseApiErrorMessage } from './parseApiErrorMessage';
import { AuthErrorMessageTypes } from './v5-auth-error-message-types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isInvalidSignInMethodError = (error: any) => {
  const errorType = parseApiErrorType(error?.response?.data);

  return errorType === AuthErrorMessageTypes.invalid_sign_in_method;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const handleInvalidSignInMethodError = (error: any) => {
  if (isInvalidSignInMethodError(error)) {
    const errorMessage = parseApiErrorMessage(error?.response?.data);

    AlertMessage(
      'error',
      errorMessage || 'Unable to sign in with that method.',
    );
  }
};
