import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import styled from '@emotion/styled';
import { FIRM_SETTINGS } from '../../constants/constants';
import { AlertMessage } from '../../utilities/utils';

export interface EnforcedMfaProps {
  setIsLoading: (loading: boolean) => void
}

const Info = styled.div`
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 10px;
  font-size: .95em;
  line-height: normal;
`

export default function EnforcedMfaSetting ({ setIsLoading }: EnforcedMfaProps) {
  const [mfaSetting, setMfaSetting] = useState(false);
  const mfaSettingKey = 'enforce_mfa'

  const fetchMfaSetting = () => {
    const request = Axios.get(`${FIRM_SETTINGS}/${mfaSettingKey}`);
    request
      .then(response => {
        setMfaSetting(response.data.data);
      })
      .catch(error => {
        AlertMessage('error', error.message, 3000);
      })
      .finally(() => setIsLoading(false))
  };

  const updateMfaSetting = () => {
    const request = Axios.put(`${FIRM_SETTINGS}/${mfaSettingKey}`, {
      type: 'boolean',
      value: !mfaSetting
    });
    request
      .then(response => {
        setMfaSetting(response.data.data.value);
      })
  }

  useEffect(() => {
    document.title = 'Domains';
    fetchMfaSetting();
  }, []);

  return (
    <div className="center-wrapper">
      <h2>
        Multi-Factor Authentication (MFA)
      </h2>
      <div style={{ color: '#7d7d7e' }}>
        Require your organization&apos;s employee users to use a second factor when signing in such as an authentication app.
      </div>

      <div className="mfa-card mt-4">
        <div className="d-flex align-items-center mb-3">
          <div className="toggle-container mr-2">
            <input
              type="checkbox"
              className="toggle"
              checked={!!mfaSetting}
              onChange={updateMfaSetting}
            />
          </div>

          <div className="mfa-title">
            Require Multi-Factor Authentication
          </div>

          { mfaSetting &&
            <div className="green-badge ml-auto">
              ENABLED
            </div>
          }
        </div>

        { !mfaSetting &&
          <Info style={{ backgroundColor: '#E6F7FB' }}>
            Enabling multi-factor authentication will require all users in your firm to use a second factor to sign in.
            Signed in users who have not yet enabled MFA will be required to set it up immediately.
            Users who are not signed in and have not setup MFA will be required to set it up during their next sign in attempt.
          </Info>
        }

        { mfaSetting &&
          <Info style={{ backgroundColor: '#F5F3B4' }}>
            Disabling multi-factor authentication will not remove MFA for existing users.
            New users will not be required to add multi-factor authentication.
          </Info>
        }
      </div>
    </div>
  )
}
