/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API V5
 * OpenAPI spec version: v5
 */
import {
  useMutation
} from '@tanstack/react-query'
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult
} from '@tanstack/react-query'
import type {
  AuthorizedCpaUser,
  CpaSwitcher,
  ErrorObjectArray,
  ForbiddenErrorObject,
  PostApiV5FirmsCpaSwitcher400,
  Uuid
} from '.././schemas'
import postApiV5AuthCpaSwitcherMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5AuthCpaSwitcherErrorType } from '../../configOrvalAPIV5';
import postApiV5FirmsCpaSwitcherMutator from '../../configOrvalAPIV5';
import type { ErrorType as PostApiV5FirmsCpaSwitcherErrorType } from '../../configOrvalAPIV5';



/**
 * @summary switches to new cpa
 */
export const postApiV5AuthCpaSwitcher = (
    cpaSwitcher: CpaSwitcher,
 ) => {
      
      
      return postApiV5AuthCpaSwitcherMutator<AuthorizedCpaUser>(
      {url: `/api/v5/auth/cpa_switcher`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: cpaSwitcher
    },
      );
    }
  


export const getPostApiV5AuthCpaSwitcherMutationOptions = <TError = PostApiV5AuthCpaSwitcherErrorType<ErrorObjectArray | ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthCpaSwitcher>>, TError,{data: CpaSwitcher}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthCpaSwitcher>>, TError,{data: CpaSwitcher}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5AuthCpaSwitcher>>, {data: CpaSwitcher}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5AuthCpaSwitcher(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5AuthCpaSwitcherMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5AuthCpaSwitcher>>>
    export type PostApiV5AuthCpaSwitcherMutationBody = CpaSwitcher
    export type PostApiV5AuthCpaSwitcherMutationError = PostApiV5AuthCpaSwitcherErrorType<ErrorObjectArray | ForbiddenErrorObject>

    /**
 * @summary switches to new cpa
 */
export const usePostApiV5AuthCpaSwitcher = <TError = PostApiV5AuthCpaSwitcherErrorType<ErrorObjectArray | ForbiddenErrorObject>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5AuthCpaSwitcher>>, TError,{data: CpaSwitcher}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5AuthCpaSwitcher>>,
        TError,
        {data: CpaSwitcher},
        TContext
      > => {

      const mutationOptions = getPostApiV5AuthCpaSwitcherMutationOptions(options);

      return useMutation(mutationOptions);
    }
    /**
 * @summary Switch to new CPA
 */
export const postApiV5FirmsCpaSwitcher = (
    uuid: Uuid,
 ) => {
      
      
      return postApiV5FirmsCpaSwitcherMutator<AuthorizedCpaUser>(
      {url: `/api/v5/firms/cpa_switcher`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: uuid
    },
      );
    }
  


export const getPostApiV5FirmsCpaSwitcherMutationOptions = <TError = PostApiV5FirmsCpaSwitcherErrorType<PostApiV5FirmsCpaSwitcher400 | ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsCpaSwitcher>>, TError,{data: Uuid}, TContext>, }
): UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsCpaSwitcher>>, TError,{data: Uuid}, TContext> => {
const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof postApiV5FirmsCpaSwitcher>>, {data: Uuid}> = (props) => {
          const {data} = props ?? {};

          return  postApiV5FirmsCpaSwitcher(data,)
        }

        


  return  { mutationFn, ...mutationOptions }}

    export type PostApiV5FirmsCpaSwitcherMutationResult = NonNullable<Awaited<ReturnType<typeof postApiV5FirmsCpaSwitcher>>>
    export type PostApiV5FirmsCpaSwitcherMutationBody = Uuid
    export type PostApiV5FirmsCpaSwitcherMutationError = PostApiV5FirmsCpaSwitcherErrorType<PostApiV5FirmsCpaSwitcher400 | ErrorObjectArray>

    /**
 * @summary Switch to new CPA
 */
export const usePostApiV5FirmsCpaSwitcher = <TError = PostApiV5FirmsCpaSwitcherErrorType<PostApiV5FirmsCpaSwitcher400 | ErrorObjectArray>,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof postApiV5FirmsCpaSwitcher>>, TError,{data: Uuid}, TContext>, }
): UseMutationResult<
        Awaited<ReturnType<typeof postApiV5FirmsCpaSwitcher>>,
        TError,
        {data: Uuid},
        TContext
      > => {

      const mutationOptions = getPostApiV5FirmsCpaSwitcherMutationOptions(options);

      return useMutation(mutationOptions);
    }
    