/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// TODO: Keep the comments for future use
import React, { useCallback } from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemButton from '@mui/material/ListItemButton';

export default function HelpButton({ menuItem }: { menuItem: MenuItem }) {
  const handleClick = useCallback((e: React.MouseEvent) => {
    //@ts-expect-error global scope for userpilot
    const { userpilot } = window;
    if (userpilot) {
      e.preventDefault();
      userpilot.trigger('Sr3G26T');
    }
  }, []);

  return (
    <a
      rel="external noreferrer"
      target="_blank"
      href={menuItem.url}
      className="Sidebar__MenuListItem"
    >
      <ListItemButton
        key={menuItem.id}
        onClick={handleClick}
        data-selected={menuItem.url}
        data-testid={`sidebar__${menuItem?.label
          ?.toLowerCase()
          ?.split(' ')
          ?.join('_')}`}
      >
        <ListItemIcon className={menuItem.icon_class} />
        <ListItemText primary={menuItem.label.toUpperCase()} />
      </ListItemButton>
    </a>
  );
}
