/**
 * Mixpanel events
 */

import { useCallback, useMemo } from 'react';

import { WorkflowRequestFragment } from 'gql/graphql';
import { useGetHome } from 'hooks/sms-hooks';
import { useMixpanel } from './Mixpanel';

/**
 * Request Events
 */
export type RequestEventType =
  // When a user creates a new request
  | 'request_created'
  // When the request is assigned and dispatched
  | 'request_sent'
  // When a user opens a request
  | 'request_opened'
  // When a submitted request is accepted
  | 'request_completed'
  // When an SMS reminder for a requst is clicked
  | 'request_sms_reminder_clicked';

export type RequestEventSource =
  | 'blank'
  | 'import_organizer'
  | 'import_return'
  | 'template';
export type RequestEventProperties = {
  // Request id is required for request event tracking
  request_id: string;
  firm_id?: string;
  account_id?: string;
  owner_id?: string;
  state?: string;
  source?: RequestEventSource;
  // request type
  type?: string;
  // user role
  role?: string;
  num_questions?: number;
  num_sections?: number;
  num_assignees?: number;
  due_date?: string;
  percent_complete?: number;
};

/**
 * Hook for tracking request events in Mixpanel
 */
export const useTrackRequestEvent = () => {
  const mixpanel = useMixpanel();
  const { data: homeData } = useGetHome();

  const baseProperties = useMemo(
    () => ({
      role: homeData?.data?.role,
      firm_id: homeData?.data?.cpa_uuid,
    }),
    [homeData],
  );

  const trackRequestEvent = useCallback(
    (eventType: RequestEventType, eventProperties: RequestEventProperties) => {
      mixpanel?.track(eventType, {
        ...baseProperties,
        ...eventProperties,
      });
    },
    [baseProperties, mixpanel],
  );

  return { trackRequestEvent };
};

/**
 * Helper util to get workflow request section item count
 */
export const getWorkflowRequestSectionQuestionsCount = (
  workflowRequest?: Nullable<WorkflowRequestFragment>,
) => {
  const workflowQuestionCount = (
    workflowRequest?.workflow?.sections || []
  ).reduce((sum, section) => {
    const sectionItemsCount = (section?.items2 || []).length;
    const sectionConditionalItemsCount = (section?.items2 || []).reduce(
      (conditionalItemsSum, sectionItem) => {
        const sectionConditionsSum = (sectionItem?.conditions || []).reduce(
          (conditionItemsSum, condition) => {
            const conditionItemCount = condition?.items?.length || 0;
            return conditionItemsSum + conditionItemCount;
          },
          0,
        );

        return conditionalItemsSum + sectionConditionsSum;
      },
      0,
    );
    return sum + sectionItemsCount + sectionConditionalItemsCount;
  }, 0);
  return workflowQuestionCount;
};

/**
 * Helper util to map loaded workflowRequest to event properties
 */
export const mapWorkflowRequestToEventProperties = (
  workflowRequest?: Nullable<WorkflowRequestFragment>,
  override: Partial<RequestEventProperties> = {},
): RequestEventProperties => ({
  request_id: workflowRequest?.id,
  account_id: workflowRequest?.assignedAccount?.id,
  owner_id: workflowRequest?.assignedEmployees?.[0]?.id,
  state: workflowRequest?.status,
  // source: No good mapping property for source
  type: workflowRequest?.workflow?.type || undefined,
  num_assignees: workflowRequest?.assignedContacts?.length,
  num_sections: workflowRequest?.workflow?.sections?.length,
  num_questions: getWorkflowRequestSectionQuestionsCount(workflowRequest),
  due_date: workflowRequest?.dueDate,
  percent_complete: workflowRequest?.percentComplete,
  ...override,
});
