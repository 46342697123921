import React, { Component } from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom-latest';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { PhoneNumberField } from 'common/PhoneNumberField';
import InputField from 'common/InputField';
import { NAME, EMAIL } from 'utilities/regularexpressions';
import { SelectField, CheckboxField } from 'common/CustomFields';
import {
  getCpaPreferences,
  labelNamebyValue,
  AlertMessage,
  generateFullName,
  isUserEmployee,
  getStorageData,
  getFirmType,
  getCpaEmail,
  removeCookie,
} from 'utilities/utils';
import {
  EMPLOYEE,
  CHECK_PRIMARY_EMAIL,
  GET_DEFAULT_MESSAGE_RECIPIENT,
  MIXPANEL_INVITE_EVENT,
} from 'constants/constants';
import ConfirmationModal from 'common/ConfirmationModal';
import { trackMixpanelEvent } from 'Mixpanel/mixpanelfn';
import { websocket } from 'websocket';
import { clearAuthSession } from 'auth/session';
import { withStorageData } from 'utilities/withStorageData';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };

const userRoles = [
  {
    key: 'firm_employee',
    value: 'firm_employee',
    label: 'Firm Employee',
  },
  {
    key: 'firm_admin',
    value: 'firm_admin',
    label: 'Firm Administrator',
  },
  {
    key: 'specialist',
    value: 'specialist',
    label: 'Specialist',
  },
];

const userRolesWithFrontdesk = [
  {
    key: 'firm_employee',
    value: 'firm_employee',
    label: 'Firm Employee',
  },
  {
    key: 'firm_admin',
    value: 'firm_admin',
    label: 'Firm Administrator',
  },
  {
    key: 'firm_frontdesk',
    value: 'firm_frontdesk',
    label: 'Firm Front Desk',
  },
  {
    key: 'specialist',
    value: 'specialist',
    label: 'Specialist',
  },
];

class CreateUser extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formdata: { status: 'New' },
      errors: {},
      preferences: {},
      showModal: false,
      currentDefaultRecipient: {},
      sameAdminEmailUpdate: false,
    };
  }

  UNSAFE_componentWillMount() {
    if (!isUserEmployee()) {
      this.props.navigate('/');
    }
    let preferences = getCpaPreferences();
    // TODO: __HOMEDATA-AUDIT
    if (getStorageData().is_frontdesk_allowed && getFirmType() === 'cpa') {
      preferences = { ...preferences, user_role: userRolesWithFrontdesk };
    } else {
      preferences = { ...preferences, user_role: userRoles };
    }
    this.setState({ preferences });
  }

  componentDidMount() {
    const userId = window.location.pathname.split('user/edit/')[1];
    if (userId) {
      Axios.get(`${EMPLOYEE}/${userId}`).then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          if (
            res.data.data[0] &&
            res.data.data[0].phone &&
            res.data.data[0].phone.indexOf('+') < 0
          ) {
            res.data.data[0].phone = `+1${res.data.data[0].phone}`;
          }
          this.setState({
            formdata: res.data.data[0],
            initialValues: res.data.data[0],
            userId,
          });
        }
      });
    }
    this.getDefaultRecipient(3000);
  }

  getDefaultRecipient = (delay) => {
    setTimeout(() => {
      Axios.get(GET_DEFAULT_MESSAGE_RECIPIENT).then((res) => {
        if (res.status === 200 && res.data && res.data.data) {
          this.setState({ currentDefaultRecipient: res.data.data });
        }
      });
    }, delay);
  };

  handleInputFieldChange = (inputvalue, fieldname, index) => {
    let value =
      inputvalue && inputvalue !== null && inputvalue.length
        ? inputvalue
        : undefined;
    if (
      value &&
      value.length &&
      (fieldname === 'phone' || fieldname === 'fax_number')
    ) {
      value = value.length ? value : undefined;
    }
    const { formdata, errors } = this.state;
    if (index === 'checkbox') {
      this.setState(
        { formdata: { ...formdata, [fieldname]: !formdata[fieldname] } },
        () => (errors[fieldname] ? this.handleBlurChange(fieldname) : null),
      );
    } else {
      this.setState({ formdata: { ...formdata, [fieldname]: value } }, () =>
        errors[fieldname] || errors.primary_email_exist
          ? this.handleBlurChange(fieldname)
          : null,
      );
    }
  };

  handleSelect = (fieldname, inputvalue) => {
    const { value } = inputvalue && inputvalue !== null ? inputvalue : {};
    const { formdata, errors } = this.state;
    this.setState({ formdata: { ...formdata, [fieldname]: value } }, () =>
      errors[fieldname] ? this.handleBlurChange(fieldname) : null,
    );
  };

  handleBlurChange = () => {
    const { formdata, errors: existingErrors } = this.state;
    const errors = {};

    if (existingErrors.first_name && !formdata.first_name) {
      errors.first_name = 'Required';
    } else if (formdata.first_name && !NAME.test(formdata.first_name)) {
      errors.first_name = 'Enter valid name';
    }

    if (existingErrors.last_name && !formdata.last_name) {
      errors.last_name = 'Enter valid name';
    } else if (formdata.last_name && !NAME.test(formdata.last_name)) {
      errors.last_name = 'Enter valid name';
    }

    if (formdata.middle_name && !NAME.test(formdata.middle_name)) {
      errors.middle_name = 'Enter valid name';
    }

    if (formdata.phone && !isValidPhoneNumber(formdata.phone)) {
      errors.phone = 'Enter valid number';
    }

    if (
      formdata.email &&
      formdata.email !== null &&
      formdata.email.length &&
      !EMAIL.test(formdata.email)
    ) {
      errors.email = 'Invalid Email';
    } else if (
      this.state.errors.email &&
      (!formdata.email || formdata.email === null || !formdata.email.length)
    ) {
      errors.email = 'Email is required';
    }
    if (
      formdata.email &&
      formdata.email !== null &&
      formdata.email.length &&
      existingErrors.primary_email_exist
    ) {
      errors.primary_email_exist = existingErrors.primary_email_exist;
    }

    this.setState({ errors }, () =>
      !errors.primary_email ? this.validatePrimaryEmail() : null,
    );
  };

  handleSubmit = () => {
    const { formdata, initialValues } = this.state;
    let { showModal } = this.state;
    const errors = {};

    if (!formdata.first_name) {
      errors.first_name = 'Required';
    } else if (formdata.first_name && !NAME.test(formdata.first_name)) {
      errors.first_name = 'Enter valid name';
    }

    if (!formdata.last_name) {
      errors.last_name = 'Required';
    } else if (formdata.last_name && !NAME.test(formdata.last_name)) {
      errors.last_name = 'Enter valid name';
    }

    if (formdata.middle_name && !NAME.test(formdata.middle_name)) {
      errors.middle_name = 'Enter valid name';
    }

    if (formdata.phone && !isValidPhoneNumber(formdata.phone)) {
      errors.phone = 'Enter valid number';
    }

    if (
      formdata.email &&
      formdata.email !== null &&
      formdata.email.length &&
      !EMAIL.test(formdata.email)
    ) {
      errors.email = 'Invalid Email';
    } else if (
      !formdata.email ||
      formdata.email === null ||
      !formdata.email.length
    ) {
      errors.email = 'Email is required';
    } else if (
      formdata.email &&
      formdata.email !== null &&
      formdata.email.length &&
      this.state.errors.primary_email_exist
    ) {
      errors.primary_email_exist = this.state.errors.primary_email_exist;
    }

    if (
      formdata.email &&
      formdata.email !== null &&
      formdata.email.length &&
      !EMAIL.test(formdata.email)
    ) {
      errors.email = 'Invalid Email';
    } else if (
      this.state.errors.email &&
      (!formdata.email || formdata.email === null || !formdata.email.length)
    ) {
      errors.email = 'Email is required';
    }

    if (!formdata.role || formdata.role === null) {
      errors.role = 'Required';
    }

    this.setState({ errors });

    if (
      initialValues &&
      formdata &&
      initialValues.is_default_message_recipient &&
      formdata.is_default_message_recipient
    ) {
      showModal = true;
    }

    if (
      !Object.keys(errors).length &&
      ((formdata.is_default_message_recipient && showModal) ||
        (!formdata.is_default_message_recipient && !showModal))
    ) {
      Object.keys(formdata).forEach((key) => {
        if (formdata[key] === undefined) {
          formdata[key] = null;
        }
      });
      const {
        email: primary_email,
        is_signing_authority,
        is_default_message_recipient,
      } = formdata;
      if (
        this.state.userId &&
        getStorageData().role === 'firm_admin' &&
        initialValues &&
        initialValues !== undefined &&
        getCpaEmail() === initialValues.email &&
        getCpaEmail() !== primary_email
      ) {
        this.setState({ sameAdminEmailUpdate: true });
      } else {
        let response = null;
        const values = {
          primary_email,
          email: primary_email,
          is_signing_authority,
          is_default_message_recipient,
          employee: { ...formdata, primary_email },
        };
        if (this.state.userId) {
          response = Axios.put(`${EMPLOYEE}/${this.state.userId}`, values);
        } else {
          response = Axios.post(EMPLOYEE, values);
        }

        response
          .then((res) => {
            this.setState({ showModal: false });
            if (
              res.status === 200 &&
              res.data.status === 200 &&
              res.data.data
            ) {
              if (!this.state.userId) {
                trackMixpanelEvent(MIXPANEL_INVITE_EVENT, {
                  distinct_id: res.data.data[0].cpa_user_id,
                  type: 'USER_INVITE',
                });
              }
              const { id } = res.data.data[0];
              AlertMessage('success', res.data.message, 3000);
              this.props.navigate(`/userdetails/${id || this.state.userId}`);
            } else {
              AlertMessage('error', res.data.message, 3000);
            }
          })
          .catch(() => {
            AlertMessage(
              'error',
              'Something went wrong. Please try again',
              3000,
            );
          });
      }
    } else if (formdata.is_default_message_recipient) {
      this.setState({ showModal: true });
    }
  };

  handleSubmitLoggedOut = () => {
    this.setState({ loading: true });
    const { formdata } = this.state;
    Object.keys(formdata).forEach((key) => {
      if (formdata[key] === undefined) {
        formdata[key] = null;
      }
    });
    const {
      email: primary_email,
      is_signing_authority,
      is_default_message_recipient,
    } = formdata;
    const values = {
      primary_email,
      email: primary_email,
      is_signing_authority,
      is_default_message_recipient,
      employee: { ...formdata, primary_email },
    };
    const response = Axios.put(`${EMPLOYEE}/${this.state.userId}`, values);
    response
      .then(() => {
        this.setState({
          loading: false,
          showModal: false,
          sameAdminEmailUpdate: false,
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          showModal: false,
          sameAdminEmailUpdate: false,
        });
        clearAuthSession();
        removeCookie({}, 'redirect_url');
        websocket.consumer.disconnect();
        localStorage.clear();
        window.location.href = '/';
        // window.location.reload(true);
        // AlertMessage('error', 'Something went wrong. Please try again', 3000);
      });
  };

  showConfirmationModal = () => {
    this.setState({ showModal: false });
  };

  validatePrimaryEmail = () => {
    const { formdata, initialValues } = this.state;
    if (formdata.email) {
      let isEmailDifferent = true;
      if (
        formdata.email &&
        initialValues &&
        initialValues.email &&
        initialValues.email.toLowerCase() === formdata.email.toLowerCase()
      ) {
        isEmailDifferent = false;
        const { errors } = this.state;
        delete errors.primary_email_exist;
        this.setState({ errors: { ...errors } });
      }
      if (isEmailDifferent) {
        Axios.post(`${CHECK_PRIMARY_EMAIL}?emp_form=yes`, {
          primary_email: formdata.email.toLowerCase(),
        }).then((res) => {
          if (res.data.status === 200) {
            // eslint-disable-next-line react/no-access-state-in-setstate
            const { errors } = this.state;
            this.setState({
              errors: {
                ...errors,
                primary_email_exist: res.data.message
                  ? res.data.message
                  : 'Already used',
              },
            });
          } else {
            const { errors } = this.state;
            delete errors.primary_email_exist;
            this.setState({ errors: { ...errors } });
          }
        });
      }
    }
  };

  render() {
    const {
      formdata,
      errors,
      preferences,
      userId,
      currentDefaultRecipient,
      sameAdminEmailUpdate,
    } = this.state;
    const disabled =
      this.state.status && this.state.status.toLowerCase() === 'inactive';
    return (
      <div id="content">
        <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
        <header>
          <ul className="breadcrumb">
            {userId
              ? [
                  <li className="breadcrumb-item" key="allusers">
                    <Link to={`/userdetails/${userId}`}>
                      {generateFullName(formdata)}
                    </Link>
                  </li>,
                  <li className="breadcrumb-item" key="updateusers">
                    Update user
                  </li>,
                ]
              : [
                  <li className="breadcrumb-item" key="allusers">
                    <Link to="/admin">All users</Link>
                  </li>,
                  <li className="breadcrumb-item" key="updateusers">
                    Create new user
                  </li>,
                ]}
          </ul>
        </header>
        <div className="center-wrapper col-lg-8 col-xs-12 m-auto">
          <section>
            <div className="col-container mb-4 section-title">
              <h5>User Information</h5>
            </div>
            <form action="" autoComplete="nope">
              <div className="row">
                <InputField
                  onChange={this.handleInputFieldChange}
                  onBlur={this.handleBlurChange}
                  name="first_name"
                  label="First Name *"
                  type="text"
                  value={formdata.first_name}
                  error={errors.first_name}
                  className="col-sm-12 col-lg-4 col-md-12 form-group"
                  disabled={disabled}
                />
                <InputField
                  onChange={this.handleInputFieldChange}
                  onBlur={this.handleBlurChange}
                  name="middle_name"
                  label="Middle Name"
                  type="text"
                  value={formdata.middle_name}
                  error={errors.middle_name}
                  className="col-sm-12 col-lg-4 col-md-12 form-group"
                  disabled={disabled}
                />
                <InputField
                  onChange={this.handleInputFieldChange}
                  onBlur={this.handleBlurChange}
                  name="last_name"
                  label="Last Name *"
                  type="text"
                  value={formdata.last_name}
                  error={errors.last_name}
                  className="col-sm-12 col-lg-4 col-md-12 form-group"
                  disabled={disabled}
                />
              </div>
              <div className="row">
                <InputField
                  onChange={this.handleInputFieldChange}
                  onBlur={this.handleBlurChange}
                  name="title"
                  label="Job title"
                  type="text"
                  value={formdata.title}
                  error={errors.title}
                  className="col-sm-12 col-lg-4 col-md-12 form-group"
                  disabled={disabled}
                />
                <InputField
                  onChange={this.handleInputFieldChange}
                  onBlur={this.handleBlurChange}
                  name="status"
                  label="status"
                  type="text"
                  value={formdata.status}
                  error={errors.status}
                  className="col-sm-12 col-lg-4 col-md-12 form-group"
                  disabled
                />
                <InputField
                  onChange={this.handleInputFieldChange}
                  onBlur={this.handleBlurChange}
                  name="email"
                  label="Email *"
                  type="text"
                  value={formdata.email}
                  error={errors.email || errors.primary_email_exist}
                  className="col-sm-12 col-lg-4 col-md-12 form-group"
                  disabled={disabled}
                />
              </div>
              <div className="row">
                <PhoneNumberField
                  name="phone"
                  label="Phone number"
                  className="col-sm-12 col-lg-4 col-md-4 form-group"
                  onChange={(input) =>
                    this.handleInputFieldChange(input, 'phone')
                  }
                  value={formdata.phone}
                  error={errors.phone}
                  onBlur={this.handleBlurChange}
                />
                <SelectField
                  id="role"
                  name="role"
                  options={preferences.user_role}
                  handleChange={(input) => this.handleSelect('role', input)}
                  value={{
                    value: formdata.role,
                    label: labelNamebyValue(
                      formdata.role,
                      preferences.user_role,
                    ),
                  }}
                  className="col-sm-12 col-lg-4 col-md-4 form-group"
                  error={errors.role}
                  onBlur={this.handleBlurChange}
                  label="role *"
                />
              </div>
              <div className="row no-gutters">
                {getFirmType() !== 'liscio-lite' && (
                  <CheckboxField
                    name="is_signing_authority"
                    id="is_signing_authority"
                    type="checkbox"
                    className="col-sm-12 col-lg-4 col-md-4"
                    label="Signing Authority"
                    value={formdata.is_signing_authority}
                    onChange={(input) =>
                      this.handleInputFieldChange(
                        input,
                        'is_signing_authority',
                        'checkbox',
                      )
                    }
                  />
                )}
                <CheckboxField
                  name="is_default_message_recipient"
                  id="is_default_message_recipient"
                  type="checkbox"
                  label="Default Message Recipient"
                  className="col-sm-12 col-lg-4 col-md-4"
                  value={formdata.is_default_message_recipient}
                  onChange={(input) =>
                    this.handleInputFieldChange(
                      input,
                      'is_default_message_recipient',
                      'checkbox',
                    )
                  }
                />
              </div>
              <div className="formbtn justify-content-end">
                <div className="btn-wrap btn--leftspace">
                  <button
                    type="button"
                    className="btn btn-outline-light"
                    onClick={() => this.props.navigate(-1)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.handleSubmit}
                  >
                    {this.state.userId ? 'Update' : 'Create'}
                  </button>
                </div>
              </div>
            </form>
          </section>
        </div>
        {this.state.showModal ? (
          <ConfirmationModal
            isOpen
            headerText={
              currentDefaultRecipient.first_name
                ? `${generateFullName(
                    currentDefaultRecipient,
                  )} is the default message recipient`
                : undefined
            }
            messageText="Are you sure you want to change default message recipient?"
            yesBtnText="Yes"
            noBtnText="No"
            yesBtnClick={this.handleSubmit}
            noBtnClick={this.showConfirmationModal}
          />
        ) : null}
        {sameAdminEmailUpdate ? (
          <ConfirmationModal
            isOpen
            messageText="You will get logged out after changing the primary email"
            yesBtnText="Ok"
            noBtnText="Cancel"
            yesBtnClick={this.handleSubmitLoggedOut}
            noBtnClick={() => this.setState({ sameAdminEmailUpdate: false })}
          />
        ) : null}
      </div>
    );
  }
}

export default withStorageData(CreateUser);
