/* eslint-disable react/no-unused-state */
/* eslint-disable default-param-last */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import Axios from 'axios';
import {
  getOffSetsAndLimit,
  getCpaPreferences,
  isUserEmployee,
  AlertMessage,
  getBulkInviteMaxCount,
  generateFullName,
  sortSelectedList,
  getFirmType,
} from 'utilities/utils';
import Pagination from 'common/Pagination';
import {
  SEARCH_FOR_BULKENGAGEMENTLETTER,
  GET_SESSION_AGREEMENT,
  UPDATE_SESSION_AGREEMENT,
  REMOVE_SESSION_AGREEMENT,
  SEND_BULK_ENG_LETTER,
  REMOVE_ALL_SESSION_AGREEMENTS,
} from 'constants/constants';
import { sendMixpanelEvent } from 'Mixpanel/mixpanelfn';
import { generateUniqueHash } from 'common/NotesUtilities';
import ConfirmationModal from 'common/ConfirmationModal';
import NoRecords from 'common/NoRecords';
import { withRouter } from 'utilities/withRouterWrapper';
import LoadingOverlay from 'common/LoadingOverlay';
import { withStorageData } from 'utilities/withStorageData';
import SearchList from './SearchList';
import SearchSection from './SearchSection';
import DocumentSection from './DocumentSection';
import SignaturePreview from './SignaturePreview';

const removeContact = (array, element) =>
  array.filter(
    (e) =>
      !(
        e.contact.id === element.contact.id &&
        e.contact.account_id === element.contact.account_id
      ) && !element.checked,
  );

const updateListData = (listData, selectedData) => {
  if (selectedData.length) {
    listData.map((each) => {
      const eachone = each;
      eachone.checked = false;
      selectedData.map((e) => {
        if (
          e.contact.id === each.contact.id &&
          e.contact.account_id === each.contact.account_id
        ) {
          eachone.checked = true;
        }
        return e;
      });
      return eachone;
    });
  }
  return listData;
};

const bulkSignaturePageTableHeaders = [
  {
    value: 'last_name',
    label: 'Contact Name',
    isSortable: true,
    sortValue: 'last_name',
    className: ' col-auto flex-20',
    // flex: '0 0 20%',
    formatFn: (input) => generateFullName(input.contact, true),
  },
  {
    value: 'account_name',
    label: 'Account Name',
    isSortable: true,
    className: ' col-auto flex-20',
    sortValue: 'account_name',
    // flex: '0 0 20%',
    formatFn: (input) =>
      input.contact.account_name &&
      input.contact.account_name !== null &&
      input.contact.account_name.length > 0
        ? input.contact.account_name
        : '-',
  },
  {
    value: 'relationship_type',
    label: 'Relationship',
    isSortable: true,
    className: ' col-auto flex-10',
    sortValue: 'relationship_type',
    // flex: '0 0 18%',
    formatFn: (input) => input.relationship_type || '-',
  },
  {
    value: 'primary_email',
    label: 'Email',
    isSortable: true,
    className: ' col-auto flex-20',
    sortValue: 'primary_email',
    // flex: '0 0 21%',
    formatFn: (input) =>
      input.contact.primary_email &&
      input.contact.primary_email !== null &&
      input.contact.primary_email.length > 0
        ? input.contact.primary_email
        : '-',
  },
  {
    value: 'task_date',
    label: 'Last Sent Date',
    isSortable: true,
    className: ' col-auto flex-10',
    sortValue: '"created_at"',
    // flex: '0 0 15%',
    formatFn: undefined,
  },
  {
    value: 'title',
    label: 'Last Sent Title',
    isSortable: true,
    className: ' col-auto flex-15',
    sortValue: 'title',
    // flex: '0 0 20%',
    formatFn: undefined,
  },
];

const bulkSignatureLastPageTableHeaders = [
  {
    value: 'last_name',
    label: 'Contact Name',
    isSortable: true,
    sortValue: 'last_name',
    className: ' col-auto flex-30',
    // flex: '0 0 30%',
    formatFn: (input) => generateFullName(input.contact, true),
  },
  {
    value: 'account_name',
    label: 'Account Name',
    isSortable: true,
    className: ' col-auto flex-30',
    sortValue: 'account_name',
    // flex: '0 0 30%',
    formatFn: (input) =>
      input.contact.account_name &&
      input.contact.account_name !== null &&
      input.contact.account_name.length > 0
        ? input.contact.account_name
        : '-',
  },
  {
    value: 'primary_email',
    label: 'Email',
    isSortable: true,
    className: ' col-auto flex-22',
    sortValue: 'primary_email',
    // flex: '0 0 22%',
    formatFn: (input) =>
      input.contact.primary_email &&
      input.contact.primary_email !== null &&
      input.contact.primary_email.length > 0
        ? input.contact.primary_email
        : '-',
  },
];

class BulkSignature extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entityTypes: [...getCpaPreferences().entity_type],
      relationshipTypes: [...getCpaPreferences().contact_type],
      selectedEntityTypes: [],
      searchFieldText: '',
      sortBy: 'account_name',
      sortOrder: 'asc',
      pagination: 1,
      listData: [],
      selectedData: [],
      totalPages: 0,
      totalRecords: 0,
      maxCount: getBulkInviteMaxCount(
        getCpaPreferences().bulk_action_limit,
        'max_get_signatures',
      ),
      showOnlySelectedData: false,
      selectedAll: false,
      showDocumentSection: false,
      content: '',
      contentbeforeSave: '',
      templateChecked: false,
      templateEdited: false,
      doctype: '',
      onlyAccountOwners: false,
      showRouteChangeWarning: false,
      targetRouteLocation: '',
      uniqueId: generateUniqueHash(5),
    };
  }

  UNSAFE_componentWillMount() {
    document.title = 'Get Signature';
    if (!isUserEmployee() || getFirmType() === 'liscio-lite') {
      this.props.history.push('/');
    }
  }

  componentDidMount() {
    // const com = this;
    // TODO: React router v6 doesn't support history block. We need to find another solution.
    // this.showRouteWarning = this.props.history.block((targetLocation) => {
    //   // if (!this.state.showRouteChangeWarning && !this.state.targetRouteLocation) {
    //   //   this.setState({ showRouteChangeWarning: true, targetRouteLocation: targetLocation.pathname });
    //   //   return false;
    //   // }
    //   let res = false;
    //   if (targetLocation.pathname === com.state.targetRouteLocation) {
    //     this.setState({ showRouteChangeWarning: false, targetRouteLocation: '' });
    //     com.deleteAllAgreements(com.state.selectedData[0].session_id);
    //     res = true;
    //   } else if (targetLocation.pathname !== com.state.targetRouteLocation && com.state.selectedData && !com.state.selectedData.length) {
    //     // this.setState({ showRouteChangeWarning: true, targetRouteLocation: targetLocation.pathname });
    //     res = true;
    //   } else {
    //     this.setState({ showRouteChangeWarning: true, targetRouteLocation: targetLocation.pathname });
    //     res = false;
    //   }
    //   return res;
    // });
    if (window.CKEDITOR === undefined) {
      const script = document.createElement('script');
      script.src = 'https://turmericp.liscio.me/ckeditor/ckeditor.js';
      script.id = 'ckeditor';
      document.body.appendChild(script);
      script.onload = () => {
        if (window.CKEDITOR && !window.CKEDITOR.disableAutoInline) {
          window.CKEDITOR.disableAutoInline = true;
        }
      };
    }
  }

  componentWillUnmount() {
    console.log(
      'unmomt',
      this.state.selectedData && this.state.selectedData.length,
    );
    if (this.state.selectedData && this.state.selectedData.length > 0) {
      // this.showRouteWarning();
    }
  }

  getRecords = () => {
    const {
      pagination,
      sortBy,
      selectedEntityTypes,
      sortOrder,
      searchFieldText,
      selectedRelationshipTypes,
      onlyAccountOwners,
    } = this.state;
    this.setState({ loading: true });
    Axios.post(SEARCH_FOR_BULKENGAGEMENTLETTER, {
      page: pagination,
      sort_by: sortOrder,
      field: sortBy,
      entity_type: selectedEntityTypes,
      relationship_type: selectedRelationshipTypes,
      keyword: searchFieldText.toLowerCase(),
      only_account_owners: onlyAccountOwners,
    }).then((res) => {
      this.setState({ loading: false });
      if (res.data.status === 200) {
        this.setState(
          {
            listData: res.data.data.data,
            paginationstats: res.data.data,
            showresults: true,
            selectedAll: false,
            offset: getOffSetsAndLimit(res.data.data.total_entries, 25)[
              this.state.pagination - 1
            ],
            totalPages: Number(Math.ceil(res.data.data.total_entries / 25)),
            totalRecords: res.data.data.total_entries,
          },
          () => this.areAllContactsSelected(),
        );
      } else {
        this.setState({
          listData: null,
          totalRecords: 0,
        });
      }
    });
  };

  getSelectedContent = (data) => {
    this.setState({ content: data });
  };

  handleRouting = () => {
    const com = this;
    com.setState({ showRouteChangeWarning: false }, () => {
      com.props.navigate(this.state.targetRouteLocation);
    });
  };

  handleFilterCheckbox = (input, index, crossclick = false) => {
    const modifiedIndex = index - 1;
    const { entityTypes, areEntityTypesSelected } = this.state;
    const selectedEntityTypes = [];
    if (modifiedIndex > -1 || crossclick) {
      if (crossclick) {
        const object = entityTypes.find((t) => t.key === input);
        const indexx = entityTypes.indexOf(object);
        entityTypes[indexx] = {
          ...entityTypes[indexx],
          checked: !entityTypes[indexx].checked,
        };
      } else {
        entityTypes[modifiedIndex] = {
          ...entityTypes[modifiedIndex],
          checked: !entityTypes[modifiedIndex].checked,
        };
      }
      entityTypes.map((e) => {
        if (e.checked) {
          selectedEntityTypes.push(e.key);
        }
        return e.key;
      });
    } else {
      entityTypes.map((e) => {
        const each = e;
        if (!areEntityTypesSelected) {
          each.checked = true;
          selectedEntityTypes.push(e.key);
        } else {
          each.checked = false;
        }
        return each;
      });
    }
    this.setState({
      entityTypes,
      selectedEntityTypes,
      areEntityTypesSelected: selectedEntityTypes.length === entityTypes.length,
    });
  };

  clearAll = () => {
    this.setState({
      entityTypes: [...getCpaPreferences().entity_type],
      relationshipTypes: [...getCpaPreferences().contact_type],
      searchFieldText: '',
      areEntityTypesSelected: false,
      selectedEntityTypes: [],
      selectedRelationshipTypes: [],
      onlyAccountOwners: false,
    });
  };

  handleInputField = (input) => {
    this.setState({ searchFieldText: input.target.value });
  };

  handleSortBy = (sortBy) => {
    let { sortOrder } = this.state;
    const { selectedData, showOnlySelectedData } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }

    if (showOnlySelectedData) {
      this.setState({
        sortOrder,
        sortBy,
        selectedData: sortSelectedList(selectedData, sortBy, sortOrder),
      });
    } else {
      this.setState(
        {
          sortOrder,
          sortBy,
        },
        () => {
          this.getRecords();
        },
      );
    }
  };

  areAllContactsSelected() {
    let x = 0;
    const { listData } = this.state;
    listData.map((e) => {
      if (e.checked) x += 1;
      return e;
    });
    const checkbox = document.getElementById('selectallcheckbox');
    if (x === listData.length) {
      this.setState({ selectedAll: true });
      if (checkbox) checkbox.indeterminate = false;
    } else {
      this.setState({ selectedAll: false });
      if (checkbox) checkbox.indeterminate = true;
    }

    if (x === 0) {
      if (checkbox) checkbox.indeterminate = '';
    }
  }

  handleCheckbox = (index) => {
    const { listData } = this.state;
    let { selectedData } = this.state;
    const maxCount =
      this.state.maxCount === 0 ? 1000000 : parseInt(this.state.maxCount, 10);
    if (
      selectedData.length >= maxCount &&
      (listData[index].checked === undefined ||
        listData[index].checked === false)
    ) {
      this.setState({ showModal: true });
    } else {
      if (listData[index].checked) {
        listData[index].checked = false;
      } else {
        listData[index].checked = true;
      }
      if (listData[index].checked) {
        selectedData.push(listData[index]);
      } else {
        selectedData = removeContact(selectedData, listData[index]);
      }
      this.setState(
        {
          listData,
          selectedData,
        },
        () => this.areAllContactsSelected(),
      );
      if (
        (this.state.showerrormessgaefornextbtn ||
          this.state.showOnlySelectedData) &&
        !this.isAtleastOneContactSelected()
      ) {
        this.setState({ showOnlySelectedData: false });
      } else {
        this.setState({ showerrormessgaefornextbtn: false });
      }
    }
  };

  handleSelectAll = () => {
    const { listData, selectedAll } = this.state;
    let { selectedData } = this.state;
    const maxCount =
      this.state.maxCount === 0 ? 1000000 : parseInt(this.state.maxCount, 10);
    let isAllChecked = 0;
    let checked = false;
    listData.map((each) => {
      if (each.checked) {
        isAllChecked += 1;
      }
      return each;
    });
    if (!selectedAll && isAllChecked === 0) {
      checked = true;
    }
    if (selectedData.length === maxCount && checked) {
      this.setState({ showModal: true });
    } else {
      let count = selectedData.length;
      listData.map((each) => {
        const eachone = each;
        count += 1;
        if (count <= maxCount && checked) {
          eachone.checked = checked;
          selectedData.push(eachone);
        } else if (!checked) {
          eachone.checked = checked;
          selectedData = removeContact(selectedData, eachone);
        }
        return eachone;
      });
      this.setState(
        {
          listData,
          selectedData,
        },
        () => this.areAllContactsSelected(),
      );
    }
  };

  handlePageChange = (input) => {
    if (this.state.showOnlySelectedData) {
      this.updatePaginationDetails(input);
    } else {
      this.setState({ pagination: input }, () => this.getRecords());
    }
  };

  showSelectedData = (input, apiresponse = [], doctype, doc_ids) => {
    if (this.state.selectedData.length) {
      let { selectedData } = this.state;
      // to updated selecteddata with aggrement ids
      if (input && selectedData.length) {
        selectedData = selectedData.map((each) => {
          let eachone = each;
          apiresponse.map((e) => {
            if (
              e.contact_id === each.contact.id &&
              e.account_id === each.contact.account_id
            ) {
              eachone = { ...eachone, ...e };
            }
            return e;
          });
          return eachone;
        });
      }
      this.setState(
        {
          showOnlySelectedData: input,
          showDocumentSection: !input,
          selectedData,
          doctype,
          doc_ids,
        },
        () => this.updatePaginationDetails(1),
      );
    } else {
      AlertMessage(
        'error',
        'Please select atleast one contact to continue',
        3000,
      );
    }
    if (!input) {
      const { selectedData } = this.state;
      let { listData } = this.state;
      listData = updateListData(listData, selectedData);
      this.setState({ listData }, () => this.getRecords());
      this.deleteAllAgreements(this.state.selectedData[0].session_id);
    }
  };

  showDocumentSectionFn = (input) => {
    if (this.state.selectedData.length) {
      this.setState(
        {
          showOnlySelectedData: false,
          showDocumentSection: input,
        },
        () => this.areAllContactsSelected(),
      );
    } else {
      AlertMessage(
        'error',
        'Please select atleast one contact to continue',
        3000,
      );
    }
  };

  sendInvites = () => {
    const { doc_ids } = this.state;
    Axios.post(SEND_BULK_ENG_LETTER, { doc_ids }).then((res) => {
      if (res.status === 200 && res.data.status === 200) {
        AlertMessage('success', res.data.message, 2000);
        this.handleCancelBtn();
      } else {
        AlertMessage('error', res.data.message, 2000);
      }
      sendMixpanelEvent('SendBulkSignature', {
        message: res.data && res.data.message ? res.data.message : null,
      });
    });
  };

  handleDelete = (key, data) => {
    let { selectedData, listData } = this.state;
    const eachOne = data;
    eachOne.checked = false;
    this.deleteAgreement(eachOne.agreement_id);
    selectedData = removeContact(selectedData, eachOne);
    listData = updateListData(listData, selectedData);
    this.setState(
      {
        selectedData,
        showOnlySelectedData: selectedData.length,
        listData,
      },
      () => {
        this.updatePaginationDetails(
          selectedData.length < 26 ? 1 : this.state.pagination,
        );
        if (!selectedData.length) {
          this.getRecords();
        }
      },
    );
  };

  handleCancelBtn = () => {
    this.setState({
      entityTypes: [...getCpaPreferences().entity_type],
      relationshipTypes: [...getCpaPreferences().contact_type],
      selectedEntityTypes: [],
      selectedRelationshipTypes: [],
      searchFieldText: '',
      sortBy: 'last_name',
      sortOrder: 'asc',
      pagination: 1,
      listData: [],
      selectedData: [],
      totalPages: 0,
      totalRecords: 0,
      showOnlySelectedData: false,
      selectedAll: false,
      areEntityTypesSelected: false,
      onlyAccountOwners: false,
      uniqueId: generateUniqueHash(5),
      contentbeforeSave: '',
    });
  };

  showEngagementLetterPreview = (key) => {
    const { selectedData, pagination } = this.state;
    const index = pagination >= 2 ? 25 * (pagination - 1) + key : key;
    const agreementID = selectedData[index].agreement_id;
    Axios.get(`${GET_SESSION_AGREEMENT}/${agreementID}`).then((res) => {
      if (res.data.status === 200) {
        this.setState({
          content: res.data.data,
          agreementID,
          showSignatureModal: true,
        });
      }
    });
  };

  handleContentChange = (data) => {
    this.setState({
      content: data,
      templateEdited: true,
    });
  };

  saveContentChanges = () => {
    const { agreementID, content, showDocumentSection } = this.state;
    if (showDocumentSection) {
      this.setState({
        contentbeforeSave: this.state.content,
        showSignatureModal: false,
      });
    } else {
      Axios.put(`${UPDATE_SESSION_AGREEMENT}/${agreementID}`, { content }).then(
        (res) => {
          if (res.data.status === 200) {
            this.setState({ showSignatureModal: false });
          }
        },
      );
    }
  };

  deleteAgreement = (agreementID) => {
    Axios.delete(`${REMOVE_SESSION_AGREEMENT}/${agreementID}`).then(() => {});
  };

  deleteAllAgreements = (sessionId) => {
    Axios.delete(`${REMOVE_ALL_SESSION_AGREEMENTS}/${sessionId}`).then(
      () => {},
    );
  };

  resetDocumentSection = () => {
    this.setState({
      templateEdited: false,
      templateChecked: false,
      contentbeforeSave: false,
    });
  };

  removeSignatureModal = () => {
    this.setState({ showSignatureModal: false });
  };

  showSignatureModal = (input, templateCheck, data, doctyp) => {
    this.setState(
      {
        content: data,
        contentbeforeSave: data,
        templateChecked: templateCheck,
        doctype: doctyp,
      },
      () => {
        this.setState({ showSignatureModal: input });
      },
    );
  };

  updatePaginationDetails = (pagination) => {
    let pageNumber = pagination;
    const { showOnlySelectedData, listData, selectedData } = this.state;
    const total_entries = showOnlySelectedData
      ? selectedData.length
      : listData.length;
    if (Number(Math.ceil(total_entries / 25)) < pagination) {
      pageNumber -= 1;
    }
    this.setState({
      offset: getOffSetsAndLimit(total_entries, 25)[pageNumber - 1],
      totalPages: Number(Math.ceil(total_entries / 25)),
      totalRecords: total_entries,
      pagination: pageNumber,
    });
  };

  searchKeyPress = (e) => {
    if (e.charCode === 13) {
      this.hanldeSearchBtn();
    }
  };

  hanldeSearchBtn = () => {
    this.setState(
      {
        selectedData: [],
        pagination: 1,
      },
      () => {
        this.getRecords();
      },
    );
  };

  handleRelationship = (input, index, crossclick = false) => {
    const modifiedIndex = index;
    const { relationshipTypes, arerelationshipTypesSelected } = this.state;
    const selectedRelationshipTypes = [];
    if (modifiedIndex > -1 || crossclick) {
      if (crossclick) {
        const object = relationshipTypes.find((t) => t.key === input);
        const indexx = relationshipTypes.indexOf(object);
        relationshipTypes[indexx] = {
          ...relationshipTypes[indexx],
          checked: !relationshipTypes[indexx].checked,
        };
      } else {
        relationshipTypes[modifiedIndex] = {
          ...relationshipTypes[modifiedIndex],
          checked: !relationshipTypes[modifiedIndex].checked,
        };
      }
      relationshipTypes.map((e) => {
        if (e.checked) {
          selectedRelationshipTypes.push(e.key);
        }
        return e.key;
      });
    } else {
      relationshipTypes.map((e) => {
        const each = e;
        if (!arerelationshipTypesSelected) {
          each.checked = true;
          selectedRelationshipTypes.push(e.key);
        } else {
          each.checked = false;
        }
        return each;
      });
    }
    this.setState({
      relationshipTypes,
      selectedRelationshipTypes,
      arerelationshipTypesSelected:
        selectedRelationshipTypes.length === relationshipTypes.length,
    });
  };

  handleAccountOwnerCheckbox = () => {
    this.setState({ onlyAccountOwners: !this.state.onlyAccountOwners });
  };

  render() {
    const {
      entityTypes,
      searchFieldText,
      listData,
      selectedEntityTypes,
      sortBy,
      sortOrder,
      offset,
      selectedAll,
      showModal,
      showOnlySelectedData,
      selectedData,
      maxCount,
      showDocumentSection,
      showSignatureModal,
      content,
      doctype,
      areEntityTypesSelected,
      relationshipTypes,
      selectedRelationshipTypes,
      onlyAccountOwners,
      showRouteChangeWarning,
      uniqueId,
    } = this.state;
    return (
      <div className="BulkSignature">
        <header>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">Bulk Action</li>
            <li className="breadcrumb-item">Get Signatures</li>
          </ul>
        </header>
        <div className="center-wrapper">
          {this.state.loading ? <LoadingOverlay /> : null}
          <section>
            {!showOnlySelectedData && !showDocumentSection ? (
              <SearchSection
                maxCount={maxCount}
                selectedEntityTypes={selectedEntityTypes}
                selectedRelationshipTypes={selectedRelationshipTypes}
                searchFieldText={searchFieldText}
                entityTypes={[
                  {
                    value: 'Select All',
                    label: 'Select All',
                    checked: areEntityTypesSelected,
                  },
                ].concat(entityTypes)}
                relationshipTypes={relationshipTypes}
                selectedData={selectedData}
                handleInputField={this.handleInputField}
                hanldeSearchBtn={this.hanldeSearchBtn}
                handleFilterCheckbox={this.handleFilterCheckbox}
                handleRelationship={this.handleRelationship}
                clearAll={this.clearAll}
                searchKeyPress={this.searchKeyPress}
                handleAccountOwnerCheckbox={this.handleAccountOwnerCheckbox}
                onlyAccountOwners={onlyAccountOwners}
                placeHolder="Search Account"
              />
            ) : null}
            {showOnlySelectedData ? (
              <div className="pb-4 section-title section-title--fullwidth">
                <div className="col-container">
                  <div className="col">
                    <h5 className="text-small">
                      Document would be sent to the following Contacts:
                    </h5>
                  </div>
                </div>
              </div>
            ) : null}
            {listData === undefined || listData === null ? <NoRecords /> : null}
            {listData &&
            listData !== null &&
            this.state.totalRecords &&
            !showDocumentSection ? (
              <SearchList
                tableHeaders={
                  showOnlySelectedData
                    ? bulkSignatureLastPageTableHeaders
                    : bulkSignaturePageTableHeaders
                }
                listData={
                  showOnlySelectedData
                    ? [...selectedData.slice(offset[0] - 1, offset[1])]
                    : listData
                }
                sortBy={sortBy}
                sortOrder={sortOrder}
                handleSortBy={this.handleSortBy}
                handleCheckbox={
                  showOnlySelectedData
                    ? this.showEngagementLetterPreview
                    : this.handleCheckbox
                }
                handleSelectAll={this.handleSelectAll}
                selectedAll={selectedAll}
                showCheckbox={!showOnlySelectedData}
                showDeleteIcon={showOnlySelectedData}
                handleDelete={this.handleDelete}
                uniquekey={(input) =>
                  input.contact.id.toString() +
                  input.contact.account_id.toString()
                }
                showPreviewIcon
                showActionDiv={showOnlySelectedData}
              />
            ) : null}
            {listData &&
            listData !== null &&
            this.state.totalRecords &&
            !showDocumentSection ? (
              <Pagination
                currentPage={this.state.pagination}
                totalPages={this.state.totalPages}
                totalRecords={this.state.totalRecords}
                handlePageChange={this.handlePageChange}
                handleSelectAll={this.handleSelectAll}
                selectedAll={selectedAll}
                offset={offset[0]}
                off={offset[1]}
              />
            ) : null}
            <DocumentSection
              showDocumentSectionFn={this.showDocumentSectionFn}
              showSelectedData={this.showSelectedData}
              showThis={showDocumentSection}
              selectedData={selectedData}
              getSelectedContent={this.getSelectedContent}
              showSignatureModal={this.showSignatureModal}
              resetDocumentSection={this.resetDocumentSection}
              data={this.state}
              uniqueId={uniqueId}
            />
            {listData &&
            listData !== null &&
            this.state.totalRecords &&
            !showOnlySelectedData &&
            !showDocumentSection ? (
              <div className="formbtn BulkSignatureButtonGroup">
                <div className="btn-wrap btn--leftspace">
                  <button
                    type="button"
                    className="btn btn-outline-light"
                    onClick={this.handleCancelBtn}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.showDocumentSectionFn(true)}
                  >
                    Next Step
                  </button>
                </div>
              </div>
            ) : null}
            {showOnlySelectedData ? (
              <div className="formbtn BulkSignatureButtonGroup">
                <div className="btn-wrap btn--leftspace">
                  <button
                    type="button"
                    className="btn btn-outline-light"
                    onClick={() => this.showSelectedData(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.sendInvites}
                  >
                    Send Document
                  </button>
                </div>
              </div>
            ) : null}
          </section>
        </div>
        {showModal ? (
          <ConfirmationModal
            isOpen={showModal}
            messageText={`You can select up to ${this.state.maxCount} contacts`}
            noBtnClick={() => {
              this.setState({ showModal: false });
            }}
            noBtnText="Close"
          />
        ) : null}
        {showSignatureModal ? (
          <SignaturePreview
            content={content}
            handleCancelButton={this.removeSignatureModal}
            handleContentChange={this.handleContentChange}
            saveContentChanges={this.saveContentChanges}
            doctype={doctype}
          />
        ) : null}
        {showRouteChangeWarning ? (
          <ConfirmationModal
            isOpen={showRouteChangeWarning}
            messageText="Are you sure you want to quit?"
            noBtnClick={() => {
              this.setState({
                showRouteChangeWarning: false,
                targetRouteLocation: '',
              });
            }}
            noBtnText="Close"
            yesBtnText="Proceed"
            yesBtnClick={this.handleRouting}
          />
        ) : null}
      </div>
    );
  }
}

export default withStorageData(withRouter(BulkSignature));
