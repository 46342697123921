/* eslint-disable no-param-reassign */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable consistent-return */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-class-component-methods */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { bindActionCreators } from 'redux';
import { withRouter } from 'utilities/withRouterWrapper';
import { Button, Modal, Label, ModalHeader, ModalBody } from 'reactstrap';
import { Line } from 'rc-progress';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import FileSaver from 'file-saver';
import moment from 'moment';
import UploadFiles from 'api/UploadFiles';
import { autoTagApi } from 'api/AutoTag';
import PreviewAllAttachments from 'common/PreviewAllAttachments';
import PdfViewer from 'components/PdfViewer/PdfViewer';
import LiscioVaultList from 'components/Files/LiscioVaultList';
import { NUMBER } from 'utilities/regularexpressions';
import ContactCard from 'common/ContactCard';
import AccountCard from 'common/AccountCard';
import {
  EDIT,
  EDIT_RECURRING,
  PAYMENTS,
  OAUTH_ACCESS_TOKENS,
  THIRD_PARTY_APPS,
  ADD_NEW_FILE_TO_TASK,
  INVOICE_RETRIEVAL_AND_CREATION,
  NEVER,
  MIXPANEL_INVOICE_VOID_EVENT,
  MIXPANEL_INVOICE_PAID_EVENT,
  PAID,
  VOID,
  TASK_CLOSED,
} from 'constants/constants';
import * as actions from 'actions/TaskActions';
import * as TaskApi from 'api/TaskApi';
import DetailBottom from 'components/Tasks/Edit/DetailBottom';
import ConfirmModal from 'common/ConfirmationModal';
import { AttachementPortion } from 'common/CustomFields';
import PageNotFound from 'common/PageNotFound';
import {
  AlertMessage,
  getCpaPreferences,
  getFirmType,
  isClient,
  getMLData,
  getStorageData,
} from 'utilities/utils';
import * as NotificationActions from 'actions/NotificationActions';
import * as paginationActions from 'actions/PaginationActions';
import TaskPdfLayout from 'components/Tasks/Edit/TaskPdfLayout';
import ClientLiscioVault from 'components/UploadedFiles/ClientLiscioVault.jsx';
import PaymentModal from 'components/Tasks/PaymentsModal/PaymentsModal';
import liscioSmallest from 'images/lisciosmallest.png';
import { trackMixpanelEvent } from 'Mixpanel/mixpanelfn';
import InvoiceModal from 'components/Billing/elements/InvoiceModal/InvoiceModal';
import { recurringFrequencyStr } from 'components/Billing/billingUtils';
import { withStorageData } from 'utilities/withStorageData';
import InvoiceDetailsTop from './InvoiceDetailsTop';
import MarkAsPaidModal from './MarkAsPaidModal';
import InvoiceTaskDetailsDropdown from './InvoiceTaskDetailsDropdown';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
const isIE = /* @cc_on!@ */ false || !!document.documentMode;
let ML_APP_DATA = {};
let accessToken = '';

class InvoiceTaskDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      load: false,
      uploadFiles: [],
      documents: [],
      commentIdHash: [],
      comments: [],
      commentsActivity: [],
      commentsHistory: [],
      taskStatus: '',
      filter: 'my_activity',
      activeArray: 'commentsActivity',
      activeTab: 0,
      showAttachmentModal: false,
      selectedAttachment: '',
      homedata: {},
      delAttachmentModal: false,
      confirmationModal: false,
      attachmentDetails: '',
      markDiv: true,
      noTask: false,
      isDocumentUpload: false,
      // get signature states start here
      showTemplate: false,
      getContentData: false,
      modalState: 'Edit',
      contentAfterChange: '',
      templatePdf: '',
      uploadedFilesLength: 0,
      uploadedFilesCount: 0,
      yearId: '',
      yearValue: '',
      selectedTags: [],
      selectedDocuments: [],
      isFileModalSubmit: false,
      popUp: false,
      type: 'Invoice',
      newDocs: [],
      errors: {},
      previewPopUp: true,
      previewScreen: false,
      previewDocument: null,
      monthId: '',
      monthValue: '',
      signDocumentModal: false,
      openOnedrive: false,
      thirdPartyApps: [],
      accessToken: '',
      sharedFilesList: [],
      folderSequence: [],
      onedriveFilesActiveTab: 0,
      noSharedFiles: false,
      googleDriveModal: false,
      contactCardID: null,
      contactCardUniqIdentifier: null,
      accountCardID: null,
      accountCardUniqIdentifier: null,
      isInvoiceModalOpen: false,
      isConfirmationModalOpen: false,
      showMarkAsPaidModal: false,
    };
    this.toggle = this.toggle.bind(this);
    this.startUpload = this.startUpload.bind(this);
    this.changeActiveTab = this.changeActiveTab.bind(this);
    this.fetchtaskDetail = this.fetchtaskDetail.bind(this);
    this.closeTask = this.closeTask.bind(this);
    this.cancelConfirm = this.cancelConfirm.bind(this);
    this.openAttachment = this.openAttachment.bind(this);
    this.delAttachmentModal = this.delAttachmentModal.bind(this);
    this.handleInvoiceModalOpen = this.handleInvoiceModalOpen.bind(this);
    this.handleInvoiceModalClose = this.handleInvoiceModalClose.bind(this);
    this.updateAutoTagData = this.updateAutoTagData.bind(this);
    this.autoTagsUpdated = false;
  }

  componentDidMount() {
    document.title = 'Billing';
    ML_APP_DATA = getMLData();
    if (getFirmType() === 'liscio-lite') {
      this.props.navigate('/');
    }
    this.fetchtaskDetail(true);
    this.props.actions.receivedNotification(false);
    // TODO: __HOMEDATA-AUDIT
    const homeDataObj = getStorageData();
    if (homeDataObj) {
      this.setState({ homedata: homeDataObj });
    }
    // action cable for task detail channel
    // const cookies = new Cookies();
    if (!isClient()) {
      this.setState({ loading: true });
      const request = axios.get(THIRD_PARTY_APPS);
      request
        .then((response) => {
          if (response.data.success === 200) {
            this.setState({
              thirdPartyApps: response.data.data.length
                ? response.data.data
                : [],
            });
          } else {
            this.setState({ loading: false });
          }
        })
        .catch((err) => {
          console.log('err', err);
          this.setState({ loading: false });
        });
    }

    this.showSuccessMessage();
  }

  // re-render component whenever the url changes
  componentDidUpdate(prevProps) {
    document.title = `Billing - ${this.props.taskDetail.subject}`;
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.fetchtaskDetail(true);
    }

    if (
      this.props.taskDetail &&
      this.props.taskDetail.invoice_amount &&
      this.state.invoicePaid
    ) {
      this.trackInvoicePaidEvent(this.props);
      this.setState({ invoicePaid: false });
    }
  }

  openPopUp = () => {
    this.setState({ popUp: !this.state.popUp });
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      Object.keys(nextProps.taskDetail) &&
      (nextProps.taskDetail.task_type_key === 'get_signature' ||
        nextProps.taskDetail.task_type_key === 'invoice') &&
      !this.state.getContentData
    ) {
      this.setState({
        contentAfterChange: nextProps.taskDetail.agreement,
        templatePdf: nextProps.taskDetail.pdf ? nextProps.taskDetail.pdf : '',
        getContentData: true,
        paymentStatus: nextProps.taskDetail.payment_status,
      });
      if (nextProps.taskDetail.task_type_key === 'invoice') {
        this.getPaymentStatus(nextProps.taskDetail.id);
      }
    }
  }

  componentWillUnmount() {
    if (this.TaskCable && this.myTaskSubscription) {
      this.TaskCable.subscriptions.remove(this.myTaskSubscription);
    }
    this.props.actions.fetchDetail({});
  }

  showSuccessMessage = () => {
    const currentUrl = new URL(window.location.href);
    const isSuccess = currentUrl.searchParams.get('success');
    const sessionId = currentUrl.searchParams.get('session_id');
    if (isSuccess && !sessionId) {
      AlertMessage('success', 'Invoice Paid Successfully');
      this.setState({ invoicePaid: true });
    }
  };

  trackInvoicePaidEvent = (props) => {
    const { invoice_amount, is_recurring, frequency, recurring_interval } =
      props.taskDetail;
    trackMixpanelEvent(MIXPANEL_INVOICE_PAID_EVENT, {
      amount: invoice_amount / 100,
      type: is_recurring ? 'recurring' : 'onetime',
      frequency,
      recurring_interval,
    });
  };

  handleGoBack = () => {
    const { taskDetail, navigate, location } = this.props;
    const currentUrl = new URL(window.location.href);
    const isSuccess = currentUrl.searchParams.get('success');

    if (isSuccess) {
      navigate(`/accountdetails/${taskDetail.account_id}/billing/invoices`);
    } else if (
      location.state &&
      location.state.source &&
      location.state.source === 'inFocus' &&
      location.state.accountsId
    ) {
      navigate(`/accountdetails/${location.state.accountsId}`);
    } else {
      navigate(-1);
    }
  };

  onSuccess = () => {
    this.handleOnedriveFiles('close');
  };

  onCancel = () => {
    this.handleOnedriveFiles('close');
  };

  onedriveSharedFileHandle = (item, itemType = '') => {
    const head = {
      headers: { Authorization: `Bearer ${this.state.accessToken}` },
    };
    if (
      ((item.folder && item.folder !== undefined) ||
        (!item.folder && item.folder === undefined)) &&
      !item.file
    ) {
      // this.setState({ loading: true });
      if (item.id === 'shared') {
        this.sharedFolder(1);
      } else {
        axios
          .get(
            `https://graph.microsoft.com/v1.0/drives/${item.parentReference.driveId}/items/${item.id}/children?expand=thumbnails`,
            head,
          )
          .then((response) => {
            if (response.status === 200) {
              let sequence = this.state.folderSequence;
              const index = sequence.findIndex((x) => x.active === true);
              if (index > -1) {
                sequence[index].active = false;
                this.setState({ folderSequence: sequence });
              }
              if (itemType === 'folder') {
                const indx = sequence.findIndex((x) => x.id === item.id);
                if (indx > -1) {
                  sequence[indx].active = true;
                  sequence = sequence.slice(0, indx + 1);
                  this.setState({ folderSequence: sequence });
                }
              } else {
                const seq = {
                  name: item.name,
                  id: item.id,
                  parentReference: { driveId: item.parentReference.driveId },
                  active: true,
                  folder: true,
                };
                sequence.push(seq);
              }
              this.setState({
                sharedFilesList: response.data.value,
                folderSequence: sequence,
              });
            } else {
              // this.setState({ loading: false });
            }
          });
      }
    } else {
      const checkboxState = this.state.sharedFilesList;
      const index = checkboxState.findIndex((x) => x.id === item.id);
      if (index > -1) {
        checkboxState[index].checked = !checkboxState[index].checked;
        this.setState({ sharedFilesList: checkboxState }, () => {
          // console.log('pali', this.state.sharedFilesList);
        });
      }
    }
  };

  setAttachmentNull = (index = '') => {
    if (index && index === 'detailUpload') {
      const uploadFile = document.getElementById('upload-detail');
      uploadFile.value = null;
    } else {
      const uploadFile = document.getElementById('upload_doc');
      uploadFile.value = null;
    }
  };

  getAuthenticationToken = () => accessToken;

  getPaymentStatus = (id) => {
    axios.get(`${PAYMENTS}/${id}`).then((res) => {
      console.log(res.data.data);
    });
  };

  async updateAutoTagData() {
    try {
      const token = await autoTagApi('login', ML_APP_DATA);
      if (
        !token ||
        (typeof token === 'object' && Object.keys(token).length === 0)
      ) {
        // console.log('errorApi',token)
        // console.log('res', response);
        // console.log(response)
        // await setAutotagAccessToken(response.token)
        // console.log(await getAccessToken())
      }
      const filesResponse = await Promise.all(
        this.state.newDocs.map((doc) =>
          autoTagApi('results', { file: doc.doc_name }, true),
        ),
      );
      if (
        filesResponse &&
        filesResponse[0] &&
        filesResponse[0].status &&
        filesResponse[0].status === 401
      ) {
        return this.updateAutoTagData();
      }
      const autoTagData = filesResponse.reduce(
        (acc, cv) => ({
          tags: acc.tags.concat(
            cv.Suggested_tags ? cv.Suggested_tags[0][0] : [],
          ),
          // months: acc.months.concat(cv.month || []),
          // years: acc.years.concat(cv.year+"" || []),
          yearsAndMonths: acc.yearsAndMonths.concat(
            [
              {
                month: cv.month || '',
                year: `${cv.year}` || '',
              },
            ] || [],
          ),
        }),
        { yearsAndMonths: [], tags: [] },
      );
      const selectedTags = getCpaPreferences().upload_tags.filter((tag) =>
        // return autoTagData.tags.find(autoTag=>autoTag.match(new RegExp(tag.label,'gi')))
        autoTagData.tags.find(
          (autoTag) => autoTag.toLowerCase() === tag.label.toLowerCase(),
        ),
      );
      // console.log({selectedTags,pref:this.props.preferences})
      const filteredYearsAndMonths = autoTagData.yearsAndMonths.map((YMO) => ({
        year: getCpaPreferences().upload_file_year.find(
          (item) => item.label === YMO.year,
        ),
        month: getCpaPreferences().upload_file_month.find(
          (item) => item.label === YMO.month,
        ),
      }));
      this.setState({
        yearId: parseInt(filteredYearsAndMonths[0].year?.key, 10),
        yearValue: filteredYearsAndMonths[0].year?.label,
      });
      this.setState({
        monthId: filteredYearsAndMonths[0].month.key,
        monthValue: filteredYearsAndMonths[0].month.label,
      });
      this.setState({ selectedTags });
    } catch (error) {
      console.error(error);
      // if(error.message.match(/axios is not defined/gi)) this.updateAutoTagData();
    }
  }

  stateUpdate = (value, changetype = '', data = '') => {
    const stateUpdatedValues = { ...value };
    this.setState({
      stateUpdatedValues,
      yearId: '',
      yearValue: '',
      monthId: '',
      monthValue: '',
      selectedTags: [],
    });
    if (changetype === 'isFileModalTaskDetail') {
      this.setState({
        newDocs: [],
        errors: {},
        yearId: '',
        yearValue: '',
        monthId: '',
        monthValue: '',
        selectedTags: [],
      });
    }
    if (changetype === 'isFileModalSubmit' && data) {
      this.setState({
        yearId: value.yearId,
        yearValue: value.yearValue,
        monthId: value.monthId,
        monthValue: value.monthValue,
        selectedTags: data.tags,
        selectedDocuments: data.doc_ids,
        isFileModalSubmit: true,
      });
    }
  };

  handleOnedriveSelectedFiles = () => {
    const head = {
      headers: { Authorization: `Bearer ${this.state.accessToken}` },
    };
    const checkboxState = this.state.sharedFilesList;
    checkboxState.map((item) => {
      if (item.checked && item.checked !== undefined) {
        axios
          .get(
            `https://graph.microsoft.com/v1.0/drives/${item.parentReference.driveId}/items/${item.id}`,
            head,
          )
          .then((res) => {
            const selectedfile = [];
            selectedfile.push(res.data);
            this.startUpload(selectedfile, 'onedrive');
          });
      }
      this.handleOnedriveFiles('close');
      return null;
    });
  };

  handleOnedriveFiles = (toggle = undefined, item = {}) => {
    if (toggle === 'open') {
      const request = axios.get(`${OAUTH_ACCESS_TOKENS}/${item.key}`);

      request
        .then((response) => {
          if (response.status === 200) {
            accessToken = response.data.access_token;

            this.setState({
              openOnedrive: !this.state.openOnedrive,
              accessToken,
            });
          } else {
            AlertMessage(
              'error',
              `Please set up ${item.value} to use the cloud store.`,
              3000,
            );
          }
        })
        .catch((err) => {
          console.log('err', err);
        });
    } else if (toggle === 'close') {
      this.setState({
        openOnedrive: !this.state.openOnedrive,
        sharedFilesList: [],
        folderSequence: [],
        onedriveFilesActiveTab: 0,
      });
    }
  };

  fetchtaskDetail = (loading) => {
    const url = window.location.pathname.split('/');
    if (url) {
      const id = url[url.length - 1];
      const { state } = this.props.location;
      this.props.actions.fetchtaskDetail(id, this, loading, '', state);
    }
  };

  updateTaskDetail = (data) => {
    this.setState({
      documents: data.documents,
      comments: data.comments,
      commentsActivity: data.my_activity,
      commentsHistory: data.history,
      taskStatus: data.status,
    });
    this.props.actions.fetchDetail(data);
  };

  cancelUpload = (filename) => {
    this.state[`${filename}sourceToken`].cancel();
  };

  cancelAllUploads() {
    const component = this;
    if (component.state.uploadFiles.length > 0) {
      const tmpHash = {};
      tmpHash.uploadFiles = [];
      const { uploadFiles } = component.state;
      uploadFiles.map((file) => {
        component.state[`${file}sourceToken`].cancel();
        return null;
      });
      component.setState(tmpHash);
    }
    if (component.state.newDocs.length) {
      const docIds = [];
      let x;
      let count = 0;
      for (x = 0; x < component.state.newDocs.length; x += 1) {
        const { id } = component.state.newDocs[x];
        const indx = component.state.documents.findIndex((y) => y.id === id);
        if (indx !== -1) {
          docIds.push(component.state.documents[x].id);
          const { match } = this.props;
          const taskId = match.params.id;
          let commentId;
          const { commentIdHash } = this.state;
          for (let i = 0; i < commentIdHash.length; i += 1) {
            if (
              commentIdHash[i].attachment_id === component.state.documents[x].id
            ) {
              commentId = commentIdHash[i].comment_id;
            }
          }
          this.setState({ delAttachmentModal: false });
          TaskApi.deleteTaskEditAttachment(
            component.state.documents[x].id,
            this,
            taskId,
            commentId,
          );
          count += 1;
          if (count === component.state.newDocs.length) {
            component.setState({ newDocs: [] });
          }
        }
      }
      // TaskApi.cancelDocuments(component, data, 'tasks');
    }
    if (this.state.errors.documents) {
      const err = this.state.errors;
      err.documents = '';
      this.setState({ errors: err });
    }
  }

  changeActiveTab = (tabIndex, each) => {
    this.setState({
      activeTab: tabIndex,
      filter: each.value,
      [each.array]: this.props.taskDetail[each.value] || [],
      activeArray: each.array,
    });
  };

  delAttachmentModal = (item) => {
    this.setState({
      delAttachmentModal: true,
      attachmentDetails: item,
    });
  };

  closeDelModal = () => {
    this.setState({ delAttachmentModal: false });
  };

  downloadAttachments = () => {
    const url = this.state.documents;
    const zip = new JSZip();
    let count = 0;
    url.map((item) => {
      const urlPath = item.aws_url_origional;
      // const fileName = item.file_name;.
      let fileName = item.file_name;
      if (fileName.split('.').length === 1) {
        fileName = `${item.file_name}.${item.content_type.split('/')[1]}`;
      }
      JSZipUtils.getBinaryContent(urlPath, (err, data) => {
        if (err) {
          throw err;
        }
        const convertTime = moment()
          .add(moment().utcOffset(), 'minutes')
          .format('YYYY-MM-DD HH:mm:ss');
        zip.file(fileName, data, {
          binary: true,
          createFolders: false,
          date: new Date(convertTime),
        });
        count += 1;
        if (count === url.length) {
          zip.generateAsync({ type: 'blob' }).then((content) => {
            FileSaver.saveAs(content, 'all_docs.zip');
          });
        }
      });
      return true;
    });
  };

  toggle = () => {
    const { navigate, location } = this.props;
    const { taskStatus } = this.state;
    if (location.state?.from) {
      if (
        location.state.from &&
        (location.state.from === 'uploadlist' ||
          location.state.from === 'notify' ||
          location.state.from === 'edocpreview')
      ) {
        navigate(-1);
      } else {
        navigate(location.state.from, {
          isBack: true,
          state: { Status: taskStatus },
        });
      }
    } else {
      this.fetchtaskDetail(true);
    }
  };

  googleDrive = (stat = '') => {
    if (stat === 'false') {
      this.setState({ googleDriveModal: false });
    } else {
      this.setState((prevState) => ({
        googleDriveModal: !prevState.googleDriveModal,
      }));
    }
  };

  googleDriveLoader = (stat = '') => {
    if (stat === 'false') {
      this.setState({ loading: false });
    } else if (stat === 'true') {
      this.setState({ loading: true });
    } else {
      this.setState((prevState) => ({ loading: !prevState.loading }));
    }
  };

  uploadfile = (uploadFile, fro = '') => {
    this.startUpload(uploadFile, fro);
  };

  startUpload = (uploadFile, fro = '') => {
    let i;
    const component = this;
    let maxsizeInBytes = 629145600;
    let maxsizeInMb = 600;
    if (
      getCpaPreferences() &&
      getCpaPreferences().attachment &&
      getCpaPreferences().attachment[0].label &&
      NUMBER.test(getCpaPreferences().attachment[0].label)
    ) {
      maxsizeInBytes = getCpaPreferences().attachment[0].label * 1024 * 1024;
      maxsizeInMb = getCpaPreferences().attachment[0].label;
    }
    component.setState({
      isDocumentUpload: true,
      uploadedFilesLength: this.state.uploadedFilesLength + uploadFile.length,
    });
    const onUploadProgress = (uploadedFile) => (progress) => {
      const percentage = Math.floor((progress.loaded * 100) / progress.total);
      const key = `percentCompleted${uploadedFile}`;
      this.setState({ [key]: percentage });
    };
    for (i = 0; i < uploadFile.length; i += 1) {
      const data = new FormData();
      const { params } = this.props;
      const { id } = params;
      if (fro === 'onedrive' || fro === 'sharepoint') {
        // this.googleDriveLoader('false');
        data.append('aws_url_file_name', uploadFile[i].name);
        data.append('aws_url', uploadFile[i]['@microsoft.graph.downloadUrl']);
      } else if (fro === 'google_drive' || fro === 'smart_vault') {
        // this.googleDriveLoader('false');
        data.append('aws_url_file_name', uploadFile[i].name);
        data.append('aws_url', uploadFile[i].file);
      } else {
        data.append('aws_url', uploadFile[i], uploadFile[i].name);
      }
      data.append('task_id', id);
      data.append('is_draft', true);
      if (uploadFile[i].size > maxsizeInBytes) {
        alert(`File exceeds limit of ${maxsizeInMb}MB`);
        return false;
      }
      const { CancelToken } = axios;
      const source = CancelToken.source();
      const files = component.state.uploadFiles;
      const keys = `${uploadFile[i].name}sourceToken`;
      files.push(uploadFile[i].name);
      const fileSize = uploadFile[i].size;
      const fileSizeKey = `fileSize${uploadFile[i].name}`;
      component.setState({
        uploadFiles: files,
        [keys]: source,
        [fileSizeKey]: fileSize,
      });
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        cancelToken: source.token,
        onUploadProgress: onUploadProgress(uploadFile[i].name),
      };
      TaskApi.addDocument(
        data,
        component,
        config,
        uploadFile[i].name,
        'taskDetail',
        fro,
      );
      if (component.state.errors.documents) {
        const err = {};
        err.documents = '';
        component.setState({ errors: err });
      }
    }
    return true;
  };

  uploadDocDetail = () => {
    const uploadFile = document.getElementById('upload-detail').files;
    this.startUpload(uploadFile);
  };

  uploadDocument = () => {
    const uploadFile = document.getElementById('upload_doc').files;
    this.startUpload(uploadFile);
  };

  showProgressBar = () => {
    const { uploadFiles } = this.state;
    let progressbar;
    if (uploadFiles !== undefined) {
      progressbar = uploadFiles.map((item) => {
        const returnValue = [];
        const percentCompleted = this.state[`percentCompleted${item}`];
        const getSize = this.state[`fileSize${item}`] / 1024;
        const trimSize = getSize.toFixed(2);
        const fileSize = this.state[`fileSize${item}`]
          ? `${trimSize} KB`
          : null;
        returnValue.push(
          <div key={item} className="file-drop-upload file-drop-inprogress">
            <div className="file-drop-type">
              <i className="icon-notes" aria-hidden="true" />
            </div>
            <div className="file-drop-info">
              <div className="file-drop-detail">
                <span className="fileName">{`${item} (${percentCompleted}%)`}</span>
                <span className="fileweight">{fileSize}</span>
                <Line
                  percent={percentCompleted}
                  strokeWidth="4"
                  strokeColor="#767676"
                />
              </div>
              <a
                className="file-action"
                onClick={() => this.cancelUpload(item)}
                href="javascript:void(0)"
              >
                <i className="icon-close2" />
              </a>
            </div>
          </div>,
        );
        return returnValue;
      });
      return progressbar;
    }
  };

  deleteAttachment = () => {
    const item = this.state.attachmentDetails;
    const { params } = this.props;
    const taskId = params.id;
    let commentId;
    const { commentIdHash } = this.state;
    for (let i = 0; i < commentIdHash.length; i += 1) {
      if (commentIdHash[i].attachment_id === item.id) {
        commentId = commentIdHash[i].comment_id;
      }
    }
    this.setState({ delAttachmentModal: false });
    TaskApi.deleteTaskEditAttachment(item.id, this, taskId, commentId);
  };

  openAttachment = async (c, isLink) => {
    await UploadFiles.showOpenAttachment(c, this, isLink);
  };

  previewOpenAttachment = (doc, isLink) => {
    if (isLink) {
      doc = JSON.parse(decodeURIComponent(doc));
    }
    this.setState({ previewScreen: true, previewDocument: doc });
  };

  previewCloseAttachment = (data) => {
    const { documents } = this.state;
    if (data?.editedFileName) {
      documents.map((doc) => {
        if (doc.id === data?.selectedItem.id && data?.editedFileName) {
          doc.doc_name = data.editedFileName;
        }
        return doc;
      });
      this.fetchtaskDetail(true);
    }
    this.setState({
      previewScreen: false,
      previewDocument: null,
      documents,
    });
  };

  attachmentModalClose = () => {
    this.setState({ showAttachmentModal: false, selectedAttachment: '' });
  };

  markComplete = () => {
    const details = this.props.taskDetail;
    if (details.status === 'Open' || details.status === 'Review') {
      this.setState({ confirmationModal: true });
    }
  };

  closeTask = () => {
    const { match } = this.props;
    const taskId = match.params.id;
    const data = { task_id: taskId };
    this.setState({ load: true });
    TaskApi.markComplete(this, data, true);
  };

  archiveTask = () => {
    const { match } = this.props;
    const taskId = match.params.id;
    const data = {
      task_id: taskId,
      is_archive: true,
    };
    this.setState({ load: true });
    TaskApi.markComplete(this, data);
  };

  reopenTask = () => {
    const { match } = this.props;
    const taskId = match.params.id;
    TaskApi.reOpen(this, taskId);
    this.setState({ markDiv: true });
  };

  cancelConfirm = () => {
    this.setState({ confirmationModal: false });
  };

  handleFileDrop = (files, e) => {
    if (e) {
      const uploadFile = files;
      this.startUpload(uploadFile);
      this.setState({ popUp: true });
      return (
        <AttachementPortion
          name="taskDetail"
          isEdit={this.state.taskStatus === 'Closed'}
          handleFileDrop={this.handleFileDrop}
          data={this.state}
          setAttachmentNull={this.setAttachmentNull}
          uploadDocument={this.uploadDocument}
          uploadPopUp="true"
          uploadFiles={this.state.uploadFiles}
          documents={this.state.documents}
          cancelUpload={this.cancelUpload}
          openAttachment={this.openAttachment}
          delAttachmentModal={this.delAttachmentModal}
          component={this}
          dataProps={this.props.taskDetail}
          sharedFolder={this.sharedFolder}
          onedriveSharedFileHandle={this.onedriveSharedFileHandle}
          handleOnedriveSelectedFiles={this.handleOnedriveSelectedFiles}
        />
      );
    }
  };

  handleForceUpdate = () => {
    this.fetchtaskDetail(true);
    this.props.actions.receivedNotification(false);
  };

  handlePrintPdf = () => {
    this.setState({ renderPdfLayout: !this.state.renderPdfLayout });
  };

  openVault = (input, vaultListData) => {
    this.setState({
      showVault: input,
      vaultListData: vaultListData || this.state.vaultListData,
    });
  };

  manageState = (data, vaultListData) => {
    const { documents } = this.state;
    const { match } = this.props;
    const taskId = match.params.id;
    const docs = [];
    const doc_ids = [];
    data.documents.map((e) => {
      let sendToBackend = true;
      let justChangeStatus = false;
      documents.map((existing_doc) => {
        if (existing_doc.id === e.id) {
          if (existing_doc.is_active === e.is_active) {
            justChangeStatus = false;
            sendToBackend = false;
          } else {
            justChangeStatus = true;
          }
          existing_doc.is_active = true;
        }
        return existing_doc;
      });
      if (sendToBackend) {
        if (!justChangeStatus) {
          docs.push(e);
        }
        doc_ids.push(e.id);
      }
      return e;
    });
    this.setState({
      documents: documents.concat(docs),
      showVault: false,
      vaultListData,
    });
    if (doc_ids && doc_ids.length) {
      axios
        .post(ADD_NEW_FILE_TO_TASK, { documents: doc_ids, task_id: taskId })
        .then((response) => {
          if (response.data.status === 200) {
            AlertMessage('success', response.data.message, 3000);
            const newDocuments = this.state.documents;
            const newDocumnts = this.state.newDocs;
            newDocumnts.unshift(response.data.data);
            this.setState({ documents: newDocuments, newDocs: newDocumnts });
            const tmpHash = {};
            const hash = this.state.commentIdHash;
            tmpHash.attachment_id = response.data.data.id;
            tmpHash.comment_id = response.data.comment_hash.id;
            hash.push(tmpHash);
            this.setState({ commentIdHash: hash });
            const commntActivity = this.state.commentsActivity;
            const commntHistory = this.state.commentsHistory;
            commntActivity.unshift(...response.data.comment_hash);
            commntHistory.unshift(...response.data.comment_hash);
            this.setState({
              commentsActivity: commntActivity,
              commentsHistory: commntHistory,
            });
          }
        });
    }
  };

  sharedFolder = (key) => {
    if (key === 0) {
      this.setState({
        sharedFilesList: [],
        folderSequence: [],
        onedriveFilesActiveTab: 0,
        noSharedFiles: false,
      });
    } else if (key === 1) {
      const head = {
        headers: { Authorization: `Bearer ${this.state.accessToken}` },
      };
      this.setState({ sharedFilesList: [], folderSequence: [] });
      axios
        .get('https://graph.microsoft.com/v1.0/me/drive/sharedWithMe', head)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.value.length === 0) {
              this.setState({ loading: false, noSharedFiles: true });
              // AlertMessage('error', 'No items found in shared list', 1000);
            } else {
              const seq = this.state.folderSequence;
              seq.push({
                name: 'Shared',
                id: 'shared',
                parentReference: { driveId: 'shared' },
                active: true,
                folder: true,
              });
              this.setState({ folderSequence: seq });
              response.data.value.map((item) => {
                const data = item.remoteItem;
                const sharedFiles = this.state.sharedFilesList;
                sharedFiles.push(data);
                this.setState({
                  sharedFilesList: sharedFiles,
                  onedriveFilesActiveTab: 1,
                });
                return null;
              });
              // this.setState({ loading: false });
            }
          } else {
            // this.setState({ loading: false });
          }
        });
    }
  };

  /** INVOICE MODAL RELATED CODE */

  handleInvoiceModalOpen = () => {
    this.setState({ isInvoiceModalOpen: true });
  };

  handleInvoiceModalClose = () => {
    this.setState({ isInvoiceModalOpen: false });
    this.fetchtaskDetail(false);
  };

  handleConfirmationModalOpen = () => {
    this.setState({ isConfirmationModalOpen: true });
  };

  handleConfirmationModalClose = () => {
    this.setState({ isConfirmationModalOpen: false });
  };

  handleVoidCancel = () => {
    const {
      invoice_amount,
      is_recurring,
      frequency,
      recurring_interval,
      payment_methods,
    } = this.props.taskDetail;
    trackMixpanelEvent(MIXPANEL_INVOICE_VOID_EVENT, {
      amount: invoice_amount / 100,
      type: is_recurring ? 'recurring' : 'onetime',
      state: 'cancelled',
      frequency,
      recurring_interval,
      payment_methods: payment_methods.value,
    });
    this.handleConfirmationModalClose();
  };

  // edit invoice details
  editInvoice = () => {
    this.handleInvoiceModalOpen();
  };

  voidInvoice = () => {
    this.handleConfirmationModalOpen();
  };

  handleVoidInvoice = async () => {
    const {
      id,
      invoice_amount,
      is_recurring,
      frequency,
      recurring_interval,
      payment_methods,
    } = this.props.taskDetail;
    try {
      const response = await axios.delete(
        `${INVOICE_RETRIEVAL_AND_CREATION}/${id}`,
      );
      if (response.status === 200) {
        AlertMessage('success', response.data.message);
        this.fetchtaskDetail(true);
        this.handleConfirmationModalClose();
      }
      trackMixpanelEvent(MIXPANEL_INVOICE_VOID_EVENT, {
        amount: invoice_amount / 100,
        type: is_recurring ? 'recurring' : 'onetime',
        state: response.status === 200 ? 'voided' : 'error',
        frequency,
        recurring_interval,
        payment_methods: payment_methods.value,
      });
    } catch (err) {
      trackMixpanelEvent(MIXPANEL_INVOICE_VOID_EVENT, {
        amount: invoice_amount / 100,
        type: is_recurring ? 'recurring' : 'onetime',
        state: 'error',
        frequency,
        recurring_interval,
        payment_methods: payment_methods.value,
      });
      AlertMessage('error', 'Something went wrong');
      this.handleConfirmationModalClose();
      console.error();
    }
  };

  markAsPaid = () => {
    this.setState({ showMarkAsPaidModal: true });
  };

  handleClose = () => {
    this.setState({ showMarkAsPaidModal: false });
  };

  getInvoiceModalFormState = () => {
    const {
      assigned_to_user,
      owner,
      for_account,
      invoice_number,
      invoice_amount,
      invoice_date,
      invoice_due_date,
      documents,
      payment_methods,
      payment_terms,
      payment_items,
      description,
      message,
      start_date,
      end_date,
      next_charge_date,
      is_payment_authorized,
      has_recurred,
      due_on_day_of_month,
      is_recurring,
      no_of_child_invoices,
      frequency,
      recurring_interval,
      id,
    } = this.props.taskDetail;

    const formState = {
      contact: assigned_to_user,
      owner,
      account: for_account,
      customTemplateInput: description,
      invoiceNumber: invoice_number,
      amount: invoice_amount,
      invoiceDate: new Date(invoice_date),
      dueDate: new Date(invoice_due_date),
      message,
      documents,
      paymentMethods: payment_methods,
      paymentTerms: payment_terms,
      item: payment_items,
      recurringStartDate: start_date ? new Date(start_date) : undefined,
      recurringEndDate: end_date || NEVER,
      nextChargeDate: next_charge_date ? new Date(next_charge_date) : undefined,
      isPaymentAuthorized: is_payment_authorized,
      hasRecurred: has_recurred,
      onSelectedDay: { label: due_on_day_of_month, value: due_on_day_of_month },
      isRecurring: is_recurring,
      childInvoicesCount: no_of_child_invoices,
      frequency,
      recurringInterval: {
        label: recurring_interval,
        value: recurring_interval,
      },
      id,
    };

    return formState;
  };

  manageRecurringInvoice = () => {
    const {
      navigate,
      taskDetail: { parent_task_id },
    } = this.props;
    navigate(`/billing/recurring/${parent_task_id}`);
  };

  renderMoreOptions = () => {
    const data = this.state.homedata;
    const {
      is_recurring,
      invoice_status,
      is_parent_task,
      has_recurred,
      qb_invoice_id,
    } = this.props.taskDetail;
    const { taskStatus } = this.state;

    const showMoreOptions =
      data.is_employee &&
      invoice_status !== PAID &&
      invoice_status !== VOID &&
      taskStatus !== TASK_CLOSED &&
      !has_recurred;

    const showManageRecurringOption = !is_parent_task && is_recurring;

    return (
      <>
        {showManageRecurringOption && (
          <div className="dropdown dropupWrap dropupWrap--right">
            <a
              className="btn btn-sm btn-link btn--onlyicon dropdown-toggle"
              data-toggle="dropdown"
            >
              <i className="icon-more" aria-hidden="true" />
              <span className="dropupWrap--caret" />
            </a>
            <InvoiceTaskDetailsDropdown
              voidInvoice={this.voidInvoice}
              invoiceStatus={invoice_status}
              qb_invoice_id={qb_invoice_id}
              manageRecurringInvoice={this.manageRecurringInvoice}
            />
          </div>
        )}

        {showMoreOptions && !showManageRecurringOption && (
          <div className="dropdown dropupWrap dropupWrap--right">
            <a
              className="btn btn-sm btn-link btn--onlyicon dropdown-toggle"
              data-toggle="dropdown"
            >
              <i className="icon-more" aria-hidden="true" />
              <span className="dropupWrap--caret" />
            </a>
            <InvoiceTaskDetailsDropdown
              editInvoice={this.editInvoice}
              voidInvoice={this.voidInvoice}
              markAsPaid={this.markAsPaid}
              isRecurring={is_recurring}
              invoiceStatus={invoice_status}
              isParentTask={is_parent_task}
              qb_invoice_id={qb_invoice_id}
            />
          </div>
        )}
      </>
    );
  };

  /* END */

  render() {
    const { match, taskDetail } = this.props;
    const keys = Object.keys(taskDetail);
    const {
      subject,
      task_type_color,
      task_type_value,
      is_recurring,
      invoice_status,
      is_parent_task,
      recurring_interval,
    } = taskDetail;

    const {
      taskStatus,
      noTask,
      renderPdfLayout,
      homedata,
      contactCardID,
      accountCardID,
      contactCardUniqIdentifier,
      accountCardUniqIdentifier,
      isInvoiceModalOpen,
      isConfirmationModalOpen,
      documents,
      previewDocument,
    } = this.state;

    if (noTask) {
      return <PageNotFound name="message" message="(No Such Task Found)" />;
    }
    let activeDocuments = [];
    if (documents?.length > 0) {
      activeDocuments = documents.filter((item) => item.is_active);
    }
    if (keys.length && !noTask && !this.state.loading) {
      return (
        <div
          id="content"
          style={{ position: 'relative' }}
          className="InvoiceTaskDetail__Container"
        >
          <div className="center-wrapper">
            {/* will show loading icom when user clicks om archive or close task */}
            {this.state.load && <div id="loading" />}
            <div className="mb-3 pb-3 section-title section-title--bottomborder">
              <div className="col-container">
                <div className="col infoHeader">
                  <h6 role="presentation" className="stepBacklink">
                    <a onClick={this.handleGoBack} href="javascript:;">
                      <i className="icon-left-arrow" /> Back
                    </a>
                  </h6>
                </div>
                <div className="col">
                  <div className="d-flex flex-nowrap btn--leftspace">
                    {this.renderMoreOptions()}
                  </div>
                </div>
              </div>
            </div>
            <div className="taskWrap">
              <div className="taskWrap--inner mb-5">
                <div className="contentHasicon mt-4 mb-4">
                  <div className="contentHasicon--inner clrGreen align-self-center">
                    <i
                      className="iconCircle iconCircle--green icon-tickmark_trans_o"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="taskWrap--header flex-grow-1">
                    <div className="row">
                      <div className="col-md-6 col-sm-12">
                        <span className={`badge ${task_type_color}`}>
                          {task_type_value}
                        </span>
                        <h2 className="mainHeading mb-0">{subject}</h2>
                      </div>
                      <div className="col-md-6 col-sm-12 align-self-center text-right ">
                        <div className="row">
                          {taskDetail &&
                          isClient() &&
                          ((invoice_status &&
                            ['unpaid', 'failed'].includes(
                              invoice_status.toLowerCase(),
                            )) ||
                            is_parent_task) ? (
                            <div className="col">
                              <PaymentModal
                                data={taskDetail}
                                updateTaskStatus={(
                                  taskstatus,
                                  paymentstatus,
                                ) => {
                                  this.setState(
                                    {
                                      taskStatus: taskstatus,
                                      paymentStatus: paymentstatus,
                                      showPaymentModal: false,
                                    },
                                    () => this.fetchtaskDetail(false),
                                  );
                                }}
                              />
                            </div>
                          ) : (
                            <div className="col" />
                          )}
                          <div>
                            <div className="date text-small mb-1">
                              {is_recurring ? (
                                <span className="text-body">
                                  <i className="icon-Recurring pr-1 vertical-middle text-primary" />
                                  Recurs{' '}
                                  {recurringFrequencyStr(recurring_interval)}
                                </span>
                              ) : null}
                            </div>
                            <div className="task-status ml-auto align-self-end">
                              <Label
                                htmlFor="status"
                                className="label-medium font-normal mb-0 mr-2 text-uppercase text-dark"
                              >
                                Status :
                              </Label>
                              <span className="tag tag--fill">
                                {invoice_status}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <InvoiceDetailsTop
                  data={taskDetail}
                  className="InvoiceTaskDetail"
                />
                <div className="formsetValue">
                  <div className="form-group form-inline-label mb-md-5">
                    <label
                      htmlFor="backdrop"
                      className="align-self-start mt-3 pt-1"
                    >
                      Attachments:
                    </label>
                    <div className="labelValue w-100">
                      <div className="dragWrap col-8 p-0">
                        <AttachementPortion
                          name="taskDetail"
                          isEdit={taskStatus === 'Closed'}
                          handleFileDrop={this.handleFileDrop}
                          data={this.state}
                          setAttachmentNull={this.setAttachmentNull}
                          uploadDocument={this.uploadDocument}
                          uploadPopUp="true"
                          uploadFiles={this.state.uploadFiles}
                          documents={this.state.documents}
                          cancelUpload={this.cancelUpload}
                          openAttachment={this.openAttachment}
                          delAttachmentModal={this.delAttachmentModal}
                          component={this}
                          dataProps={taskDetail}
                          openVault={this.openVault}
                          sharedFolder={this.sharedFolder}
                          onedriveSharedFileHandle={
                            this.onedriveSharedFileHandle
                          }
                          handleOnedriveSelectedFiles={
                            this.handleOnedriveSelectedFiles
                          }
                          getAuthenticationToken={this.getAuthenticationToken}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.taskStatus !== 'Draft' && (
                  <DetailBottom
                    toggle={this.toggle}
                    data={this.state}
                    changeActiveTab={this.changeActiveTab}
                    fetchtaskDetail={this.fetchtaskDetail}
                    updateTaskDetail={this.updateTaskDetail}
                    onDocumentClick={this.previewOpenAttachment}
                    taskId={taskDetail.id}
                    props={this.props}
                    isInvoiceTask
                  />
                )}
              </div>
              {match.pathname.includes('/task/detail') &&
                this.props.notificationAvailable && (
                  <div className="chat-Message">
                    <div className="Chat-Txt">
                      <img
                        src={liscioSmallest}
                        alt="Avatar"
                        className="avatar chat-Img"
                      />
                      <h2>Task Detail Updated</h2>
                    </div>
                    <div className="Chat-show">
                      <Button
                        onClick={this.handleForceUpdate}
                        className="btn-link btn--onlyicon btn btn-secondary cross-btn"
                      >
                        Show
                      </Button>
                      <span
                        onClick={() => {
                          this.props.actions.receivedNotification(false);
                        }}
                      >
                        <i className="icon-close2" aria-hidden="true" />
                      </span>
                    </div>
                  </div>
                )}
            </div>
            <Modal
              isOpen={this.state.showAttachmentModal}
              className="customModal customModal--w990 customModal--nopadding modalMxHeight"
            >
              <ModalHeader className="ModalHeader">
                <button
                  type="button"
                  onClick={() => this.attachmentModalClose()}
                  className="close"
                  aria-label="Close"
                >
                  <i className="icon-close2" aria-hidden="true" />
                </button>
              </ModalHeader>
              <ModalBody className="modalContent">
                <div className="modalContent--inner">
                  <div
                    className={
                      isIE ? 'left-inner-content' : 'sample-letter-preview'
                    }
                  >
                    <div className="letter-logo" />
                    {isIE ? (
                      <PdfViewer file={this.state.selectedAttachment} />
                    ) : (
                      <iframe
                        width="100%"
                        height="600"
                        name="myfilename"
                        src={this.state.selectedAttachment}
                        title="selected attachment"
                      />
                    )}
                  </div>
                </div>
              </ModalBody>
            </Modal>
            <ConfirmModal
              isOpen={this.state.delAttachmentModal}
              yesBtnClick={this.deleteAttachment}
              className="InvoiceDeleteAttachmentModal"
              noBtnClick={this.closeDelModal}
              headerText="You are about to delete the attached file."
              messageText="This action cannot be undone. Proceed?"
              yesBtnText="Yes"
              noBtnText="No"
            />
            <ConfirmModal
              isOpen={this.state.confirmationModal}
              className="customModal customModal--center customModal--alert customModal--w500 customModal--nopadding"
              messageText="You are about to mark this task as closed. Proceed?"
              yesBtnText="Proceed"
              noBtnText="Cancel"
              yesBtnClick={this.closeTask}
              noBtnClick={this.cancelConfirm}
            />
          </div>
          {this.state.previewScreen && (
            <Modal
              className="Md-full-width modal-FullH"
              isOpen={this.state.previewScreen}
            >
              <PreviewAllAttachments
                uploadType="task"
                fro="taskDetail"
                docs={activeDocuments}
                taskDetail={this.props.taskDetail}
                selectedDocument={previewDocument}
                closePreview={this.previewCloseAttachment}
                delAttachmentModal={this.delAttachmentModal}
              />
            </Modal>
          )}
          {renderPdfLayout ? (
            <TaskPdfLayout
              data={{ ...this.state, ...taskDetail }}
              handlePrintPdf={this.handlePrintPdf}
            />
          ) : null}
          {/* lisco vault */}
          <Modal
            isOpen={this.state.showVault}
            className="customModal customModal--nopadding"
          >
            <div className="ModalHeader">
              <button
                onClick={() => this.openVault(false)}
                type="button"
                className="close"
                aria-label="Close"
              >
                <i className="icon-close2" aria-hidden="true" />
              </button>
            </div>
            <ModalBody>
              {homedata && homedata.is_employee ? (
                <LiscioVaultList
                  updateDocuments={this.manageState}
                  openVault={this.openVault}
                  selecteddocuments={[...this.state.documents]}
                  isVisible={this.state.showVault}
                  getOnlyPrivateFilesinLiscioVault={
                    taskDetail &&
                    taskDetail.task_type_key &&
                    taskDetail.task_type_key === 'to_do'
                  }
                  vaultListData={this.state.vaultListData}
                  page="task"
                />
              ) : (
                <ClientLiscioVault
                  updateDocuments={this.manageState}
                  openVault={this.openVault}
                  selecteddocuments={[...this.state.documents]}
                  isVisible={this.state.showVault}
                  vaultListData={this.state.vaultListData}
                />
              )}
            </ModalBody>
          </Modal>
          {contactCardID && contactCardID !== null ? (
            <ContactCard
              id={contactCardID}
              uniqId={contactCardUniqIdentifier}
              page="taskdetail"
            />
          ) : null}
          {accountCardID && accountCardID !== null ? (
            <AccountCard
              id={accountCardID}
              uniqId={accountCardUniqIdentifier}
              page="taskdetail"
            />
          ) : null}
          {isInvoiceModalOpen && (
            <InvoiceModal
              isOpen={isInvoiceModalOpen}
              handleModalClose={this.handleInvoiceModalClose}
              editState={this.getInvoiceModalFormState()}
              mode={is_recurring && is_parent_task ? EDIT_RECURRING : EDIT}
            />
          )}
          {isConfirmationModalOpen && (
            <ConfirmModal
              isOpen={isConfirmationModalOpen}
              className="VoidInvoiceModal"
              headerText="Void Invoice"
              messageText="You are about to Void this invoice. This action cannot be undone. Proceed?"
              yesBtnText="Yes"
              noBtnText="No"
              yesBtnClick={this.handleVoidInvoice}
              noBtnClick={this.handleVoidCancel}
            />
          )}
          {this.state.showMarkAsPaidModal && (
            <MarkAsPaidModal
              openModal={this.state.showMarkAsPaidModal}
              handleClose={this.handleClose}
              fetchtaskDetail={this.fetchtaskDetail}
              paymentID={taskDetail.payment_id}
            />
          )}
        </div>
      );
    }

    return (
      <div id="loading" style={this.state.loading ? blockStyle : noneStyle} />
    );
  }
}

const mapStateToProps = (state) => ({
  taskDetail: state.taskReducer.taskDetail,
  notificationAvailable: state.taskReducer.notificationAvailable,
  tasks: state.taskReducer.tasks,
  taskStatus: state.taskReducer.taskStatus,
  task_page: state.paginationReducer.task_page,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(actions, dispatch),
  NotificationActions: bindActionCreators(NotificationActions, dispatch),
  pagination: bindActionCreators(paginationActions, dispatch),
});

export default withStorageData(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(InvoiceTaskDetails)),
);
