/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
// TODO: check react-s-alert
import moment from 'moment';
import cookies from 'js-cookie';
import { uniq } from 'lodash';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { REMOVESPECIALCHAR } from 'utilities/regularexpressions';
import { matchRoutes } from 'react-router-dom-latest';
import { toast } from 'react-toastify';
import {
  toastDefaultMessageMapper,
  TRUSTED_LISCIO_TLDS,
  IS_DEPLOYED_REVIEW_APP,
  REACT_APP_FAKE_HOSTNAME,
  REACT_APP_FAKE_COMPANY,
} from 'constants/constants';
import 'react-toastify/dist/ReactToastify.css';

/**
 * Resolve the site host.
 * Will use `REACT_APP_FAKE_HOSTNAME` if set or location
 * In local/review apps will also fall back to fake_company
 */
export const getSiteHostname = () => {
  const hostname = REACT_APP_FAKE_HOSTNAME || window.location.hostname || '';
  if (
    hostname === 'localhost' ||
    hostname === 'devolympus.herokuapp.com' ||
    hostname.includes('ngrok') ||
    IS_DEPLOYED_REVIEW_APP
  ) {
    return REACT_APP_FAKE_HOSTNAME || REACT_APP_FAKE_COMPANY;
  }

  return hostname;
};
/**
 * Resolve company name from site hostname.
 */
export const getSiteCompanyName = () => {
  const hostname = getSiteHostname();
  // Extract from hostname
  return hostname.split('.')[0];
};

// TODO: __HOMEDATA-AUDIT
export const getStorageData = () =>
  localStorage.getItem(`${getSiteHostname()} data`) !== null
    ? JSON.parse(localStorage.getItem(`${getSiteHostname()} data`))
    : {};

export const setUserInLocalStorage = (data) => {
  localStorage.setItem(`${getSiteHostname()} data`, JSON.stringify(data));
};

export const AlertMessage = (status, data, time = 1500, onClose = () => {}) => {
  const message = data || toastDefaultMessageMapper[status];

  return toast(message, {
    position: toast.POSITION.TOP_CENTER,
    type: status,
    hideProgressBar: true,
    autoClose: time,
    onClose,
  });
};

export const clearErrorMessages = () => {
  const x = document.getElementsByClassName('error_label');
  let i;
  for (i = 0; i < x.length; i += 1) {
    x[i].innerHTML = '';
  }
};

export const fileType = (item) => {
  let logo = 'icon-filetype-pdf';
  if (
    item.content_type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    item.content_type === 'application/vnd.ms-excel' ||
    item.content_type === 'application/x-ole-storage'
  ) {
    logo = 'icon-excel';
  } else if (
    item.content_type === 'application/msword' ||
    item.content_type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
    item.content_type === 'application/vnd.ms-word'
  ) {
    logo = 'icon-word';
  } else if (item.content_type === 'application/zip') {
    logo = 'icon-zip';
  } else if (
    item.content_type === 'application/vnd.ms-powerpoint' ||
    item.content_type ===
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ) {
    logo = 'icon-powerpoint';
  } else if (
    item.content_type === 'image/jpeg' ||
    item.content_type === 'image/png' ||
    item.content_type === 'image/gif'
  ) {
    logo = 'icon-image-preview';
  } else if (item.content_type === 'application/pdf') {
    logo = 'icon-filetype-pdf';
  } else {
    logo = 'icon-notes';
  }
  return logo;
};

export const browserIcon = (item) => {
  let icon = 'icon-Chrome';
  if (item === 'Chrome') {
    icon = 'icon-Chrome';
  } else if (item === 'Firefox') {
    icon = 'icon-firefox';
  } else if (item === 'Safari') {
    icon = 'icon-safari';
  } else {
    icon = 'icon-internet-explorer';
  }
  return icon;
};

export const operatingSystemIcon = (item) => {
  const deviceOs = item.device_os.toLowerCase();
  const appName = item.app_name.toLowerCase();
  let icon = '';
  if (
    deviceOs === 'other-0' &&
    (appName === 'firmdash' || appName === 'liscio client')
  ) {
    icon = 'icon-iphone';
  } else if (deviceOs.startsWith('win')) {
    icon = 'icon-Windows';
  } else if (deviceOs.startsWith('linux')) {
    icon = 'icon-linux';
  } else if (deviceOs.startsWith('mac') && appName === 'liscio') {
    icon = 'icon-computerscreen';
  } else if (deviceOs.startsWith('mac') && appName !== 'liscio') {
    icon = 'icon-Apple';
  } else if (deviceOs.startsWith('ios')) {
    icon = 'icon-iphone';
  } else if (deviceOs.startsWith('android')) {
    icon = 'icon-iphone';
  } else if (deviceOs.startsWith('chrome')) {
    icon = 'icon-Chrome';
  } else {
    icon = 'icon-computerscreen';
  }
  return icon;
};

export const getDeviceName = (item) => {
  const deviceOs = item.device_os.toLowerCase();
  const appName = item.app_name.toLowerCase();
  let deviceName = item.device_name ? item.device_name : '';
  if (!deviceName) {
    if (deviceOs.startsWith('ios')) {
      deviceName = 'iPhone/iPad';
    } else if (deviceOs.startsWith('android')) {
      deviceName = 'Android';
    } else if (deviceOs.startsWith('chrome')) {
      deviceName = 'Chrome';
    } else if (deviceOs.startsWith('win')) {
      deviceName = 'Windows';
    } else if (deviceOs.startsWith('mac')) {
      deviceName = 'Mac';
    } else if (deviceOs.startsWith('linux')) {
      deviceName = 'Linux';
    } else if (
      deviceOs === 'other-0' &&
      (appName === 'firmdash' || appName === 'liscio client')
    ) {
      deviceName = 'Unknown';
    } else {
      deviceName = 'Unknown';
    }
  }
  return deviceName;
};

export const titleCase = (st) => {
  const str = st.toLowerCase().split(' ');
  for (let i = 0; i < str.length; i += 1) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
  }
  return str.join(' ');
};

export const systemData = () => {
  const data = {};
  // si.system().then((result) => {
  //   data = result;
  // });
  return data;
};

export const sortingIcon = (selectedfilter, field, sort, className = '') => {
  if (className) {
    if (selectedfilter === field) {
      return 'sorting_asc';
    }
    return '';
  }
  if (selectedfilter === field && sort === 'desc') {
    return 'icon-down-arrow';
  }
  if (selectedfilter === field && sort === 'asc') {
    return 'icon-up-arrow';
  }
  return null;
};

export const getOffSetsAndLimit = (diff, pagelength) => {
  const offsetandrange = [];
  if (diff <= pagelength) {
    offsetandrange.push([1, diff]);
    return offsetandrange;
  }
  if (diff > pagelength) {
    const rem = diff % pagelength;
    let i = 0;
    if (rem === 0) {
      while (i < diff) {
        offsetandrange.push([i + 1, i + pagelength]);
        i += pagelength;
      }
    } else {
      while (i < diff) {
        offsetandrange.push([i + 1, i + pagelength]);
        i += pagelength;
      }
      offsetandrange[offsetandrange.length - 1][1] = i - pagelength + rem;
    }
    // [ [ 0, 50 ],[ 50, 100 ],[ 100, 150 ],[ 150, 200 ],[ 200, 250 ] ] will be the output
    return offsetandrange;
  }
};

export const captalizeText = (input) => {
  if (input !== undefined) {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }
  return '';
};

export const sortList = (list, sortby, desc) => {
  const output = list;
  output.sort((a, b) => {
    if (a[sortby] === null || b[sortby] === null) {
      const first = a[sortby] === null ? '' : a[sortby];
      const second = b[sortby] === null ? '' : b[sortby];
      if (first < second) return -1;
      if (first > second) return 1;
      return 0;
    }
    if (typeof a[sortby] === 'number' && typeof b[sortby] === 'number') {
      if (a[sortby] < b[sortby]) return -1;
      if (a[sortby] > b[sortby]) return 1;
    } else {
      if (a[sortby].toLowerCase() < b[sortby].toLowerCase()) return -1;
      if (a[sortby].toLowerCase() > b[sortby].toLowerCase()) return 1;
    }
    return 0;
  });
  if (desc === 'desc') {
    return output.reverse();
  }
  return output;
};

export const validateAddress = (each, addressFieldNames) => {
  const {
    addressLine1Name,
    addressLine2Name,
    cityName,
    stateName,
    countryName,
    zipName,
  } = addressFieldNames;
  const addressErrors = {};
  if (
    each !== null &&
    (each[addressLine1Name] ||
      each[addressLine2Name] ||
      each[cityName] ||
      each[zipName] ||
      each[countryName])
  ) {
    if (each[countryName] && each[countryName].toLowerCase() === 'us') {
      if (!each[addressLine1Name]) addressErrors[addressLine1Name] = 'Required';
      if (!each[cityName]) addressErrors[cityName] = 'Required';
      if (!each[stateName]) addressErrors[stateName] = 'Required';
      if (!each[zipName]) {
        addressErrors[zipName] = 'Required';
      } else if (
        each[zipName] &&
        each[zipName].length !== 5 &&
        REMOVESPECIALCHAR(each[zipName]).length !== 9
      ) {
        addressErrors[zipName] = 'Should be 5 or 9 digits';
      }
    } else if (each[countryName] && each[countryName].toLowerCase() !== 'us') {
      if (!each[addressLine1Name]) addressErrors[addressLine1Name] = 'Required';
    } else {
      if (!each[addressLine1Name]) addressErrors[addressLine1Name] = 'Required';
      if (!each[cityName]) addressErrors[cityName] = 'Required';
      if (!each[stateName]) addressErrors[stateName] = 'Required';
      if (!each[zipName]) addressErrors[zipName] = 'Required';
      if (!each[countryName]) addressErrors[countryName] = 'Required';
    }
  }
  return addressErrors;
};

export const generateAddress = (data, addressFieldNames) => {
  const output = {};
  Object.keys(addressFieldNames).forEach((key) => {
    if (data[addressFieldNames[key]] !== null) {
      output[addressFieldNames[key]] = `${data[addressFieldNames[key]]}, `;
    }
  });
  if (!Object.keys(output).length) {
    return undefined;
  }
  return output;
};

export const isUserEmployee = () => getStorageData().is_employee;

export const getCpaPreferences = () => getStorageData().pref_type_options || {};

export const getSmsPhoneLineFromLocalStorage = () =>
  getStorageData().smsPhoneLine || {};

export const getFeatureGates = () => getStorageData().feature_gates || {};

export const getStages = () => {
  const storageData = getStorageData();
  const homeDataObj = storageData.pref_type_options;
  const stageData = homeDataObj.account_stage ? homeDataObj.account_stage : [];
  return stageData;
};

export const getFirmType = () => getStorageData().firm_type || 'cpa';

export const getCanViewPreferences = () => {
  const storageData = getStorageData();
  const homeDataObj = storageData.pref_type_options;
  const canViewData = homeDataObj?.can_view ? homeDataObj?.can_view : false;
  return canViewData;
};

export const getLoggedInType = () => localStorage.getItem('loggedInType');

export const getCpaName = () => getStorageData().cpa_name;

export const getCpaEmail = () => getStorageData().email;

export const getRole = () => getStorageData().role;

export const getCpaUserId = () => getStorageData().cpa_user_id;

export const getCpaId = () => getStorageData().cpa_id;

export const getMLData = () => {
  const storageData = getStorageData();
  const homeDataObj = storageData.pref_type_options;
  const liscioMLData =
    homeDataObj.liscio_ml?.length > 0
      ? {
          username: homeDataObj.liscio_ml[0].key,
          password: homeDataObj.liscio_ml[0].label,
        }
      : {};
  return liscioMLData;
};

export const labelNamebyValue = (value, options) => {
  let output = '';
  if (options) {
    options.map((each) => {
      if (each.value === value) {
        output = each.label;
      }
      return each;
    });
  }
  return output;
};

export const generateFullName = (input, lastname_first) => {
  if (lastname_first) {
    return `${input.last_name}, ${input.first_name} ${
      input.middle_name !== null ? input.middle_name : ''
    }`;
  }
  return input.first_name
    ? `${input.first_name} ${
        input.middle_name && input.middle_name !== null
          ? `${input.middle_name} `
          : ''
      }${input.last_name}`
    : null;
};

export const generateInitials = (input) =>
  input.first_name
    ? input.first_name.split('')[0] || `${input.last_name.split('')[0]}` || ''
    : '';

export const getInitials = (input) =>
  input
    ? (input.split(' ')[0] ? input.split(' ')[0].charAt(0) : '') +
      (input.split(' ')[1] ? input.split(' ')[1].charAt(0) : '')
    : '';

export const statusClassName = (input) => {
  if (!input || input === null) {
    return '';
  }
  if (input.toLowerCase() === 'new') {
    return 'is-status';
  }
  if (input.toLowerCase() === 'invited') {
    return 'is-status is-status--busy';
  }
  if (input.toLowerCase() === 'active') {
    return 'is-status is-status--online';
  }
  if (input.toLowerCase() === 'inactive') {
    return 'is-status is-status--away';
  }
  if (input.toLowerCase() === 'expired') {
    return 'is-status is-status--invite-expired';
  }
};

export const updateCountry = (values, addressFieldNames, fieldname) => {
  const {
    addressLine1Name,
    addressLine2Name,
    cityName,
    stateName,
    countryName,
    zipName,
  } = addressFieldNames;
  let each = values;
  if (fieldname && fieldname === countryName) {
    return each;
  }
  if (
    (each[addressLine1Name] ||
      each[addressLine2Name] ||
      each[cityName] ||
      each[stateName] ||
      each[zipName]) &&
    !each[countryName]
  ) {
    each = { ...each, [countryName]: 'US' };
  } else if (
    each[addressLine1Name] === undefined &&
    each[addressLine2Name] === undefined &&
    each[cityName] === undefined &&
    each[stateName] === undefined &&
    each[zipName] === undefined
  ) {
    each = { ...each, [countryName]: undefined };
  }
  return each;
};

export const getEmployeeRolesForCpa = (preferences) => {
  const test = {
    key: 'firm_frontdesk',
    value: 'firm_frontdesk',
    label: 'Firm Front Desk',
  };
  return { ...preferences, user_role: [...preferences.user_role, test] };
};

export const removeElementFromContactArray = (array, element) =>
  array.filter((e) => e.contact.id !== element.contact.id && !element.checked);

export const getBulkInviteMaxCount = (preferences, bulkactionname) => {
  let maxCount = 0;
  if (preferences) {
    preferences.map((item) => {
      if (item.key === bulkactionname.toLowerCase() && item.label) {
        maxCount = item.label;
      }
      return item;
    });
  }
  return maxCount;
};

export const generateUniqueKey = (input) => input;

export const sortSelectedList = (list, sortby, desc) => {
  list.sort((a, b) => {
    if (a.contact[sortby] === null || b.contact[sortby] === null) {
      a.contact[sortby] = a.contact[sortby] === null ? '' : a.contact[sortby];
      b.contact[sortby] = b.contact[sortby] === null ? '' : b.contact[sortby];
      if (a.contact[sortby] < b.contact[sortby]) return -1;
      if (a.contact[sortby] > b.contact[sortby]) return 1;
    } else if (typeof a.contact[sortby] === 'boolean') {
      if (a.contact[sortby] < b.contact[sortby]) return -1;
      if (a.contact[sortby] > b.contact[sortby]) return 1;
    } else {
      if (a.contact[sortby].toLowerCase() < b.contact[sortby].toLowerCase()) {
        return -1;
      }
      if (a.contact[sortby].toLowerCase() > b.contact[sortby].toLowerCase()) {
        return 1;
      }
    }
    return 0;
  });
  if (
    desc === 'desc' ||
    ((sortby === 'app_download_request_sent_at' ||
      sortby === 'is_texting_allowed') &&
      desc === 'asc')
  ) {
    return list.reverse();
  }
  return list;
};

export const updateListData = (listData, selectedData) => {
  if (selectedData.length) {
    listData.map((each) => {
      const eachone = each;
      eachone.checked = false;
      selectedData.map((e) => {
        if (e.id === each.id) {
          eachone.checked = true;
        }
        return e;
      });
      return eachone;
    });
  }
  return listData;
};

export const sortArraybyProperty = (array, property) => {
  if (array === undefined || array === null) {
    return [];
  }
  const sortArray = (a, b) => {
    if (a[property] < b[property]) {
      return -1;
    }
    if (a[property] > b[property]) {
      return 1;
    }
    // a must be equal to b
    return 0;
  };
  return array.sort(sortArray);
};

export const replaceUndefinedWithNull = (inputObject) => {
  const { co_state_ui_base_rate } = inputObject;
  Object.keys(inputObject).forEach((key) => {
    if (
      inputObject[key] === undefined ||
      (inputObject[key] !== null && inputObject[key] === '') ||
      (inputObject[key] !== null && inputObject[key] === 0) ||
      (inputObject[key] !== undefined &&
        inputObject[key] !== null &&
        inputObject[key] === 'Invalid date')
    ) {
      inputObject[key] = null;
    }
  });
  if (inputObject.id) {
    inputObject.co_state_ui_base_rate = co_state_ui_base_rate;
  }
  return inputObject;
};

export const printPdf = (content_id, iframe_id, styleElement = '') => {
  const isIE = false || !!document.documentMode;
  const content = document.getElementById(content_id);
  if (isIE) {
    const newWin = window.open();
    newWin.document.write(styleElement);
    newWin.document.write(content.innerHTML);
    newWin.location.reload();
    newWin.focus();
    newWin.print();
    newWin.close();
  } else {
    const val = document.getElementById(iframe_id).contentWindow;
    val.document.write('<html>');
    val.document.write('<body >');
    val.document.write(content.innerHTML);
    val.document.write('</body></html>');
    val.document.close();
    val.focus();
    setTimeout(() => {
      val.print();
      val.close();
    }, 700);
  }
};

export const isTextingAllowedForCpa = (preferences, text_type) => {
  let output = false;
  if (preferences) {
    preferences.map((item) => {
      if (item.key && item.key === text_type.toLowerCase()) {
        output = true;
      }
      return item;
    });
  }
  return output;
};
export const fileExtention = (item) => {
  let logo = 'icon-filetype-pdf';
  if (
    item.content_type ===
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
    item.content_type === 'application/vnd.ms-excel' ||
    item.content_type === 'application/x-ole-storage'
  ) {
    logo = 'icon-excel';
  } else if (
    item.content_type === 'application/msword' ||
    item.content_type ===
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
    item.content_type === 'application/vnd.ms-word'
  ) {
    logo = 'icon-word';
  } else if (item.content_type === 'application/zip') {
    logo = 'icon-zip';
  } else if (
    item.content_type === 'application/vnd.ms-powerpoint' ||
    item.content_type ===
      'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  ) {
    logo = 'icon-powerpoint';
  } else if (
    item.content_type === 'image/jpeg' ||
    item.content_type === 'image/png' ||
    item.content_type === 'image/gif'
  ) {
    logo = 'icon-image-preview';
  } else if (item.content_type === 'application/pdf') {
    logo = 'icon-filetype-pdf';
  } else {
    logo = 'icon-notes';
  }
  return logo;
};
export const current_logged_user_name = () => getStorageData().uname;

export const selectedIds = (array, key) => {
  const selectedlist = [];
  array.map((item) => selectedlist.push(item[key]));
  return selectedlist;
};
export const tagLabel = (tagkey) => {
  let tag = tagkey;
  tag = tag.replace(/[_-]/g, ' ');
  return tag;
};
export const removeDuplicateTags = (tags) => {
  let removeDuplicates = [];
  const storageData = getStorageData();
  const tagsData =
    storageData.pref_type_options.upload_tags &&
    storageData.pref_type_options.upload_tags !== undefined &&
    storageData.pref_type_options.upload_tags !== null
      ? storageData.pref_type_options.upload_tags
      : [];
  tags.map((item) => {
    const index = tagsData.findIndex((x) => x.key === item || x.label === item);
    if (index > -1) {
      const tagKey = tagsData[index].key;
      removeDuplicates.push(tagKey);
    } else {
      removeDuplicates.push(item);
    }
    return null;
  });
  removeDuplicates = uniq(removeDuplicates, false);
  return removeDuplicates;
};
export const dueDateColor = (dueDate, status) => {
  let output = '';
  if (dueDate && status && status !== null && status !== undefined) {
    output =
      Date.parse(dueDate) < Date.parse(moment().format('MM/DD/YYYY')) &&
      status === 'Open'
        ? ' text-danger'
        : '';
    return output;
  }
  return output;
};

export const checker = (arr, target) => target.every((v) => arr.includes(v));

export const getPageIndex = (uploadFilesList, pagination, data) => {
  let index = 1;
  if (uploadFilesList.length) {
    const selectedlist = selectedIds(uploadFilesList, 'id');
    index = pagination + 1;
    if (
      (uploadFilesList.length && checker(data, selectedlist)) ||
      (uploadFilesList.length === 1 && data.includes(uploadFilesList[0].id))
    ) {
      index = pagination === 0 ? pagination + 1 : pagination;
    } else if (pagination >= 0) {
      index = pagination + 1;
    }
    // if ((uploadFilesList.length && checker(data, selectedlist)) || (uploadFilesList.length === 1 && data.includes(uploadFilesList[0].id))) {
    //   console.log('if');
    //   index = pagination === 0 ? pagination + 1 : pagination;
    // } else {
    //   const paginate = pagination - 1;
    //   console.log('else', paginate);
    //   // index = pagination === 0 ? pagination + 1 : pagination;
    //   index = pagination === 0 ? pagination + 1 : ((paginate === 0 || pagination === -1) ? 1 : paginate);
    // }
    return index;
  }
  return index;
};
export const FORMATPHONENUMBER = (phone_number) => {
  let output = phone_number;
  if (phone_number) {
    if (phone_number.indexOf('+') < 0) {
      output = `+1${phone_number}`;
    }
    return formatPhoneNumberIntl(output);
  }

  return phone_number;
};
export const generatingContactsCsvFile = (input, filename) => {
  const data = input;
  const csvdata = [];
  csvdata.push([
    'First Name',
    'Middle Name',
    'Last Name',
    'Primary Email',
    'Status',
    'Invited On',
    'Invite Expired On',
    'Activated On',
    'Deactivated On',
    'Mobile Number',
    'Is Texting Enabled?',
    'Has User Unsubscribed?',
  ]);
  data.forEach((e) => {
    const {
      contact: {
        first_name,
        middle_name,
        last_name,
        primary_email,
        last_invite_sent_at,
        status,
        activated_at,
        deactivated_at,
      },
      invitation_expired_date,
      contact_phone,
    } = e;

    let phone_number = ' ';
    let is_texting_allowed = '';
    let has_user_unsubscribed = '';
    if (contact_phone && contact_phone.length) {
      ({ phone_number } = contact_phone[0]);
      is_texting_allowed = contact_phone[0].is_texting_allowed ? 'Yes' : 'No';
      has_user_unsubscribed = contact_phone[0].has_user_unsubscribed
        ? 'Yes'
        : 'No';
    }
    csvdata.push([
      first_name,
      middle_name,
      last_name,
      primary_email,
      status,
      last_invite_sent_at
        ? `${moment(last_invite_sent_at).format('MM/DD/YYYY')} `
        : ' ',
      invitation_expired_date
        ? `${moment(invitation_expired_date).format('MM/DD/YYYY')} `
        : '',
      activated_at ? `${moment(activated_at).format('MM/DD/YYYY')} ` : ' ',
      deactivated_at ? `${moment(deactivated_at).format('MM/DD/YYYY')} ` : ' ',
      phone_number ? FORMATPHONENUMBER(phone_number) : ' ',
      is_texting_allowed,
      has_user_unsubscribed,
    ]);
  });
  if (csvdata.length) {
    let csvContent = 'data:text/csv;charset=utf-8,';
    csvdata.forEach((rowArray) => {
      const row = rowArray.join(',');
      csvContent += `${row}\r\n`;
    });
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
  }
};

export const randomString = (length) => {
  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._';
  let result = '';
  while (length > 0) {
    const bytes = new Uint8Array(16);
    const random = window.crypto.getRandomValues(bytes);
    // eslint-disable-next-line no-loop-func
    random.forEach((c) => {
      if (length === 0) {
        return;
      }
      if (c < charset.length) {
        result += charset[c];
        length -= 1;
      }
    });
  }
  return result;
};

export const createCookie = (cookieName, cookieValue, minutesToExpire) => {
  const date = new Date();
  const trustedDomain = getTrustedDomainForCookie();

  if (trustedDomain) {
    date.setTime(date.getTime() + minutesToExpire * 60 * 1000);
    const cookie = trustedDomain.includes('localhost')
      ? `${cookieName}=${cookieValue};path=/;`
      : `${cookieName}=${cookieValue};expires=${date.toGMTString()};domain=${trustedDomain};path=/`;

    document.cookie = cookie;
  } else {
    console.error(`${window.location.host} is not a trusted domain.`);
  }
};

export const getTrustedDomainForCookie = () => {
  const { host } = window.location;

  // Browsers wont set a cookie with a port e.g. localhost:3000
  // so don't return it if localhost
  if (host.includes('localhost')) return 'localhost';

  const currentDomain = host.split('.').slice(-2).join('.');

  return TRUSTED_LISCIO_TLDS.find((domain) => domain === currentDomain);
};

export const accessCookie = (cookieName) => {
  const name = `${cookieName}=`;
  const allCookieArray = document.cookie.split(';');
  for (let i = 0; i < allCookieArray.length; i += 1) {
    const temp = allCookieArray[i].trim();
    if (temp.indexOf(name) === 0) {
      return temp.substring(name.length, temp.length);
    }
  }
  return undefined;
};

export const generateDuration = (time) => {
  const h = Math.floor(time / 60);
  const m = Math.floor(time % 60);
  if (time < 60) {
    return `${`${m}`.slice(-2)}m `;
  }
  return `${h}h ${`0${m}`.slice(-2)}m `;
};

export const getUserTimeZoneAbbr = (dateInput) => {
  const dateObject = dateInput || new Date();
  const dateString = `${dateObject}`;
  // eslint-disable-next-line operator-linebreak
  let tzAbbr =
    // Works for the majority of modern browsers
    dateString.match(/\(([^)]+)\)$/) ||
    // IE outputs date strings in a different format:
    dateString.match(/([A-Z]+) [\d]{4}$/);

  if (tzAbbr) {
    // Old Firefox uses the long timezone name (e.g., "Central
    // Daylight Time" instead of "CDT")
    tzAbbr = tzAbbr[1].match(/[A-Z]/g).join('');
  }

  return tzAbbr;
};

export const doesTagExist = (tags, newtagname, currentTagIndex) => {
  const tagIndex = tags.findIndex(
    (e, index) =>
      (e.key.toLowerCase() === newtagname.toLowerCase() ||
        (e.value &&
          e.value !== null &&
          e.value.toLowerCase() === newtagname.toLowerCase())) &&
      currentTagIndex !== index,
  );
  return tagIndex >= 0;
};

export const textTruncate = (str, length, ending) => {
  if (length == null) {
    length = 15;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str.length > length) {
    str = str.substring(0, length - ending.length) + ending;
  }
  return str;
};

// eslint-disable-next-line default-param-last
export const processedFilesLength = (len = '', docLen = '', com) => {
  if (len === docLen) {
    com.props.googleDriveLoader('false');
  }
};

// eslint-disable-next-line default-param-last
export const processedFilesLen = (len = '', docLen = '', com) => {
  console.log('d', len, docLen);
  if (len && docLen && len === docLen) {
    console.log('d in', len, docLen);
    com.googleDriveLoader('false');
  }
};

export const GOOGLE_DRIVE_SUPPORTED_MIMETYPES = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/gif',
  'application/pdf',
  'application/postscript',
  'image/x-eps',
  'image/tif',
  'image/tiff',
  'file/txt',
  'text/plain',
  'application/xml',
  'image/x-photoshop',
  'image/vnd.adobe.photoshop',
  'application/vnd.intu.qfx',
  'application/vnd.intu.qbo',
  'application/x-ofx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'application/vnd.ms-word',
  'application/octet-stream',
  'application/octet-stream\r\n',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/vnd.ms-excel',
  'application/excel',
  'application/x-ole-storage',
  'application/x-msword',
  'application/x-word',
  'application/word',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint',
  'application/powerpoint',
  'application/zip',
  'application/rtf',
  // 'application/vnd.oasis.opendocument.spreadsheet',
  // 'application/x-vnd.oasis.opendocument.spreadsheet',
  // 'application/vnd.oasis.opendocument.text',
  'video/mp4',
  'text/calendar',
];

export const truncateText = (selector, maxLength) => {
  if (selector.length > maxLength) {
    selector = `${selector.substr(0, maxLength)}...`;
    const lastElement = selector.charAt(selector.length - 4);
    if (lastElement === '<') {
      selector = `${selector.substring(
        0,
        selector.length - 4,
      )} ${selector.substring(selector.length - 4 + 1)}`;
    }
  }
  return selector;
};

export const formatAmount = (amount) => {
  if (amount === undefined) {
    return amount;
  }
  if (amount === null) {
    return undefined;
  }
  let val = amount.replace(/\D/g, '');
  let newVal = '';
  if (val.length > 3) {
    const lastThree = val.substr(val.length - 3);
    val = val.substring(0, val.length - 3);
    if (val.length > 3) {
      val = formatAmount(val);
    }
    val = `${val},${lastThree}`;
  }
  newVal += val;
  return newVal;
};

export const generatingAccountsCsvFile = (input, filename) => {
  const data = input;
  const content = [];
  content.push(Object.keys(input[0]));
  data.forEach((e) => {
    content.push(Object.values(e));
  });
  let finalVal = '';

  for (let i = 0; i < content.length; i += 1) {
    const value = content[i];
    for (let j = 0; j < value.length; j += 1) {
      const innerValue = value[j] !== null ? value[j].toString() : '';
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) result = `"${result}"`;
      if (j > 0) finalVal += ',';
      finalVal += result;
    }
    finalVal += '\n';
  }

  const pom = document.createElement('a');
  pom.setAttribute(
    'href',
    `data:text/csv;charset=utf-8,${encodeURIComponent(finalVal)}`,
  );
  pom.setAttribute('download', filename);
  pom.click();
};

/**
 * @deprecated What a terrible function this is...
 */
export function getCookie(isParse = true, name = getSiteCompanyName()) {
  return isParse ? JSON.parse(cookies.get(name)) : cookies.get(name);
}
/**
 * @deprecated This is ONLY being used to handle setting cookies for redirect_url in the
 * root src/index.js. Once that use is gone we can delee this and the above getCookie func.
 */
export function setCookie(
  value,
  options,
  isStringify = true,
  name = getSiteCompanyName(),
) {
  if (isStringify) {
    cookies.set(name, JSON.stringify(value), options);
  } else {
    cookies.set(name, value, options);
  }
}

/**
 * @deprecated Also marked for extinction. Will be deleted with the above get+set Cookie funcs.
 */
export function removeCookie(options, name = getSiteCompanyName()) {
  cookies.remove(name, options);
}

export const base64ToArrayBuffer = (base64) => {
  const binaryString = atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i += 1) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
};

export const totalPages = (
  filterBy,
  tabsArray,
  tabProperty,
  noOfRecordsInEachPage,
) => {
  let output = 0;
  tabsArray.map((each) => {
    if (each[tabProperty].toLowerCase() === filterBy.toLowerCase()) {
      output = each.noofrecords / noOfRecordsInEachPage;
    }
    return null;
  });
  return Number(Math.ceil(output));
};

export const totalRecords = (filterBy, tabsArray, tabProperty) => {
  let output = 0;
  tabsArray.map((each) => {
    if (each[tabProperty].toLowerCase() === filterBy.toLowerCase()) {
      output = each.noofrecords || 0;
    }
    return null;
  });
  return output;
};

export const isClient = () => {
  const item = getStorageData();
  return !!item && item.role === 'client';
};

export const getDay = (momentDate) => {
  const dateString = moment(momentDate, 'MM/DD/YYYY');
  const day = parseInt(dateString.format('D'), 10);
  return day;
};

// This will return the current path like this: '/inbox/:id'
export const getCurrentPath = (location) => {
  const routes = [
    { path: '/inbox/:id' },
    { path: '/alert/:id' },
    { path: '/sent/:id' },
    { path: '/draft/:id' },
    { path: '/archive/:id' },
    { path: '/pinned/:id' },
    { path: '/all_messages/:id' },
    { path: '/all_messages' },
    { path: '/message/new/modal' },
    { path: '/accountdetails/:id' },
    { path: '/contactdetails/:id' },
    { path: '/task/edit/:id' },
    { path: '/task/detail/:id' },
    { path: '/contactdetails/task/:id' },
    { path: '/accountdetails/task/:id' },
    { path: '/task/new' },
    { path: '/accountdetails/:id/emails/:email_id' },
    { path: '/task/new/modal/:id' },
    { path: '/emails' },
    { path: '/accountdetails/:id/details' },
    { path: '/accountdetails/:id/tasks' },
    { path: '/accountdetails/:id/inFocus' },
    { path: '/accountdetails/:id/messages' },
    { path: '/accountdetails/:id/notes' },
    { path: '/accountdetails/:id/payroll' },
    { path: '/accountdetails/:id/activity' },
    { path: '/accountdetails/:id/files' },
    { path: '/accountdetails/:id/logins' },
    { path: '/accountdetails/:id/emails' },
    { path: '/accountdetails/:id/relationships' },
    { path: '/accountdetails/:id/emails/:email_id' },
    { path: '/accountdetails/:id/billing/invoices' },
    { path: '/accountdetails/:id/billing/recurring' },
    { path: '/accountdetails/message/:id' },
    { path: '/inactivecontactdetails/:id' },
    { path: '/contactdetails/:id/relationships' },
    { path: '/fileupload/new' },
    { path: '/fileupload/:id' },
    { path: '/files/new' },
    { path: '/files' },
    { path: '/message/new' },
    { path: '/emails/:id' },
    { path: '/note/new' },
    { path: '/templates/new' },
    { path: '/templates/:id' },
    { path: '/note/edit/:id' },
    { path: '/billing/invoices' },
    { path: '/billing/recurring' },
    { path: '/billing/invoices/:id' },
    { path: '/billing/recurring/:id' },
    { path: '/contactdetails/:id/overview' },
    { path: '/contactdetails/:id/tasks' },
    { path: '/contactdetails/:id/messages' },
    { path: '/contactdetails/:id/notes' },
    { path: '/contactdetails/:id/files' },
    { path: '/contactdetails/:id/emails' },
    { path: '/contactdetails/:id/emails/:email_id' },
    { path: '/contactdetails/message/:id' },
    { path: '/employee/:id/accounts' },
    { path: '/admin' },
    { path: '/' },
  ];

  const match = matchRoutes(routes, location);
  if (match) {
    const [{ route }] = match;
    return route.path;
  }
  return '';
};

export const inAccOrClientView = () => {
  const locationHref = window.location.href || '';
  return (
    locationHref.includes('/accountdetails/') ||
    locationHref.includes('/contactdetails/')
  );
};

export const inInboxOrAlertView = () => {
  const locationHref = window.location.href || '';
  return locationHref.includes('/inbox/') || locationHref.includes('/alert/');
};

export const getUrlParams = (input) => {
  const params = new URLSearchParams(input);
  const obj = {};
  params.forEach((value, key) => {
    obj[key] = value;
  });
  return obj;
};

export const formatText = (text) => {
  if (text) {
    return text
      .replace(/&#39;/g, "'")
      .replace(/&lt;/g, '<')
      .replace(/&gt;/g, '>')
      .replace(/&amp;/g, '&')
      .replace(/&quot;/g, '"');
  }
  return '';
};
