export enum AuthErrorMessageTypes {
  expired_link = 'expired_link',
  invalid_link = 'invalid_link',
  inactive = 'inactive',
  invalid_credentials = 'invalid_credentials',
  invalid_sign_in_method = 'invalid_sign_in_method',
  locked_out = 'locked_out',
  magic_link_generated = 'magic_link_generated',
  password_reset_generated = 'password_reset_generated',
  password_reset_success = 'password_reset_success',
  two_factor_invalid = 'two_factor_invalid',
  unauthorized = 'unauthorized',
  unprocessable = 'unprocessable',
  password_mismatch = 'password_mismatch',
  password_strength = 'password_strength',
  password_reused = 'password_reused',
  cpa_not_found = 'cpa_not_found',
}
