/**
 * Generated by orval v7.0.1 🍺
 * Do not edit manually.
 * API V5
 * OpenAPI spec version: v5
 */
import {
  useQuery
} from '@tanstack/react-query'
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult
} from '@tanstack/react-query'
import type {
  ForbiddenErrorObject,
  GetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplates200Item,
  GetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesId200,
  Uuid
} from '.././schemas'
import getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesErrorType } from '../../configOrvalAPIV5';
import getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesIdMutator from '../../configOrvalAPIV5';
import type { ErrorType as GetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesIdErrorType } from '../../configOrvalAPIV5';



/**
 * @summary Retrieves Liscio Workflow Templates
 */
export const getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplates = (
    
 signal?: AbortSignal
) => {
      
      
      return getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesMutator<GetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplates200Item[]>(
      {url: `/api/v5/firms/workflow_templates/liscio_workflow_templates`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesQueryKey = () => {
    return [`/api/v5/firms/workflow_templates/liscio_workflow_templates`] as const;
    }

    
export const getGetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplates>>, TError = GetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesErrorType<ForbiddenErrorObject>>( options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplates>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesQueryKey();

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplates>>> = ({ signal }) => getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplates(signal);

      

      

   return  { queryKey, queryFn, ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplates>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplates>>>
export type GetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesQueryError = GetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesErrorType<ForbiddenErrorObject>


/**
 * @summary Retrieves Liscio Workflow Templates
 */

export function useGetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplates<TData = Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplates>>, TError = GetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesErrorType<ForbiddenErrorObject>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplates>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesQueryOptions(options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



/**
 * @summary Retrieves A Liscio Workflow Template
 */
export const getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesId = (
    id: Uuid,
 signal?: AbortSignal
) => {
      
      
      return getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesIdMutator<GetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesId200>(
      {url: `/api/v5/firms/workflow_templates/liscio_workflow_templates/${id}`, method: 'GET', signal
    },
      );
    }
  

export const getGetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesIdQueryKey = (id: Uuid,) => {
    return [`/api/v5/firms/workflow_templates/liscio_workflow_templates/${id}`] as const;
    }

    
export const getGetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesIdQueryOptions = <TData = Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesId>>, TError = GetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesIdErrorType<ForbiddenErrorObject>>(id: Uuid, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesId>>, TError, TData>, }
) => {

const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getGetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesIdQueryKey(id);

  

    const queryFn: QueryFunction<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesId>>> = ({ signal }) => getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesId(id, signal);

      

      

   return  { queryKey, queryFn, enabled: !!(id), ...queryOptions} as UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesId>>, TError, TData> & { queryKey: QueryKey }
}

export type GetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesIdQueryResult = NonNullable<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesId>>>
export type GetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesIdQueryError = GetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesIdErrorType<ForbiddenErrorObject>


/**
 * @summary Retrieves A Liscio Workflow Template
 */

export function useGetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesId<TData = Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesId>>, TError = GetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesIdErrorType<ForbiddenErrorObject>>(
 id: Uuid, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof getApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesId>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } {

  const queryOptions = getGetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesIdQueryOptions(id,options)

  const query = useQuery(queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey ;

  return query;
}



