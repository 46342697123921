import { Button, CircularProgress } from '@mui/material';
import { TOAST_FAILURE } from 'constants/constants';
import {
  WorkflowRequestFragment,
  WorkflowStatus,
  WorkflowWithSectionsFragment,
} from 'gql/graphql';
import {
  useReadyToSendWorkflowMutation,
  useUpdateWorkflow,
} from 'hooks/workflow-hooks';
import {
  mapWorkflowRequestToEventProperties,
  useTrackRequestEvent,
} from 'Mixpanel/useTrackRequestEvent';
import { useSearchParams } from 'react-router-dom-latest';
import { Footer } from 'ui/Footer/Footer';
import { AlertMessage } from 'utilities/utils';

export interface RequestsBuilderFooterProps {
  workflow?: WorkflowWithSectionsFragment | null;
  onClose: () => void;
  onSend: (requestId: string) => void;
  saveDisabled?: boolean;
  sendDisabled?: boolean;
}

export const RequestsBuilderFooter: React.FC<RequestsBuilderFooterProps> = ({
  workflow,
  onClose,
  onSend,
  saveDisabled,
  sendDisabled,
}: RequestsBuilderFooterProps) => {
  const [searchParams] = useSearchParams();
  const { mutate: saveAndSendWorkflowMutation, isLoading } =
    useReadyToSendWorkflowMutation();
  const { mutate: updateWorkflowStatusMutation } = useUpdateWorkflow();
  const prepareOnSave = searchParams.get('prepareOnSave');
  const { trackRequestEvent } = useTrackRequestEvent();

  if (!workflow) {
    return null;
  }

  const handleOnSave = () => {
    if (prepareOnSave) {
      handleOnSaveAndSend();
      return;
    }
    if (workflow?.status === WorkflowStatus.Draft) {
      onClose();
      return;
    }

    updateWorkflowStatusMutation(
      {
        status: WorkflowStatus.Draft,
        workflowId: workflow.id,
      },
      {
        onSuccess: (response) => {
          if (response?.updateWorkflow?.errors?.length) {
            AlertMessage(TOAST_FAILURE, 'Failed to save');
            return;
          }

          onClose();
        },
      },
    );
  };

  const handleOnSaveAndSend = () => {
    saveAndSendWorkflowMutation(
      { workflowId: workflow.id },
      {
        onSuccess: (response) => {
          if (response?.readyToSend?.errors?.length) {
            AlertMessage(TOAST_FAILURE, 'Failed to save');
            return;
          }

          // Track request_created event
          trackRequestEvent(
            'request_created',
            mapWorkflowRequestToEventProperties(
              {
                // NOTE: WE can derive some data from the workflow
                workflow,
              } as WorkflowRequestFragment,
              {
                request_id: response?.readyToSend?.workflowRequest?.id,
                source: 'blank',
              },
            ),
          );

          onSend(response?.readyToSend?.workflowRequest?.id);
        },
      },
    );
  };

  return (
    <Footer>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleOnSave}
        sx={{ marginLeft: 'auto' }}
        disabled={saveDisabled}
      >
        Save and Exit
      </Button>
      <Button
        variant="contained"
        onClick={handleOnSaveAndSend}
        disabled={isLoading || sendDisabled}
        aria-busy={isLoading}
      >
        {isLoading && <CircularProgress size="small" />}
        Preview and Send
      </Button>
    </Footer>
  );
};
