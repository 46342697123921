/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import Axios from 'axios';
import moment from 'moment-timezone';
import {
  getOffSetsAndLimit,
  isUserEmployee,
  getBulkInviteMaxCount,
  getCpaPreferences,
  removeElementFromContactArray,
  generateFullName,
  sortSelectedList,
  AlertMessage,
} from 'utilities/utils';
import Pagination from 'common/Pagination';
import {
  TEXT_LOGS,
  SEARCH_FOR_BULK_DOWNLOAD_INVITATION,
  BULK_DOWNLOAD_INVITE,
} from 'constants/constants';
import { sendMixpanelEvent } from 'Mixpanel/mixpanelfn';
import ConfirmationModal from 'common/ConfirmationModal';
import NoRecords from 'common/NoRecords';
import LoadingOverlay from 'common/LoadingOverlay';
import { withStorageData } from 'utilities/withStorageData';
import SearchSection from './SearchSection';
import SearchList from './SearchList';

const timeZone =
  Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(true);
const bulkDownloadPageTableHeaders = [
  {
    value: 'last_name',
    label: 'Contact Name',
    isSortable: true,
    className: ' col-auto flex-20 ie-px-2',
    sortValue: 'last_name',
    formatFn: (input) => generateFullName(input.contact, true),
  },
  {
    value: 'primary_email',
    label: 'Email',
    isSortable: true,
    className: ' col-auto flex-20 ie-px-1',
    sortValue: 'primary_email',
    formatFn: (input) =>
      input.contact.primary_email &&
      input.contact.primary_email !== null &&
      input.contact.primary_email.length > 0
        ? input.contact.primary_email
        : '-',
  },
  {
    value: 'is_texting_allowed',
    label: 'Texting allowed?',
    isSortable: true,
    className: ' col-auto flex-20 ie-px-1',
    sortValue: 'is_texting_allowed',
    formatFn: (input) => (input.contact.is_texting_allowed ? 'Yes' : 'No'),
  },
  {
    value: 'app_download_request_sent_at',
    label: 'Last Download Request Sent',
    isSortable: true,
    className: ' col-auto flex-22 ie-px-1',
    sortValue: 'app_download_request_sent_at',
    formatFn: (input) =>
      input.contact.app_download_request_sent_at
        ? moment
            .tz(input.contact.app_download_request_sent_at, timeZone)
            .format('MM/DD/YYYY')
        : '-',
  },
];
const updateListDataAfterPageChange = (listData, selectedData) => {
  if (selectedData.length) {
    listData.map((each) => {
      const eachone = each;
      eachone.contact.is_texting_allowed = each.is_texting_allowed;
      selectedData.map((e) => {
        if (e.contact.id === each.contact.id) {
          eachone.checked = true;
        }
        return e;
      });
      return eachone;
    });
  } else {
    listData.map((each) => {
      const eachone = each;
      eachone.contact.is_texting_allowed = each.is_texting_allowed;
      return eachone;
    });
  }
  return listData;
};
const updatedSelectedFilterValues = (
  filterValues,
  index,
  areAllFiltersSelected,
  input,
  crossclick,
) => {
  const modifiedIndex = index - 1;
  const filterValuesArray = filterValues;
  const selectedFilterValuesArray = [];
  if (modifiedIndex > -1 || crossclick) {
    if (crossclick) {
      const object = filterValuesArray.find((t) => t.key === input);
      const indexx = filterValuesArray.indexOf(object);
      filterValuesArray[indexx] = {
        ...filterValuesArray[indexx],
        checked: !filterValuesArray[indexx].checked,
      };
    } else {
      filterValuesArray[modifiedIndex] = {
        ...filterValuesArray[modifiedIndex],
        checked: !filterValuesArray[modifiedIndex].checked,
      };
    }
    filterValuesArray.map((e) => {
      if (e.checked) {
        selectedFilterValuesArray.push(e.key);
      }
      return e.key;
    });
  } else {
    filterValuesArray.map((e) => {
      const each = e;
      if (!areAllFiltersSelected) {
        each.checked = true;
        selectedFilterValuesArray.push(e.key);
      } else {
        each.checked = false;
      }
      return each;
    });
  }
  return {
    filterValuesArray,
    selectedFilterValuesArray,
    areAllFiltersSelected:
      filterValuesArray.length === selectedFilterValuesArray.length,
  };
};

class BulkRequestDownload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showOnlySelectedData: false,
      maxCount: getBulkInviteMaxCount(
        getCpaPreferences().bulk_action_limit,
        'max_invites',
      ),
      selectedEntityTypes: [],
      searchFieldText: '',
      entityTypes: [...getCpaPreferences().entity_type],
      selectedData: [],
      listData: [],
      sortBy: 'last_name',
      sortOrder: 'asc',
      pagination: 1,
      totalPages: 0,
      totalRecords: 0,
      selectedAll: false,
    };
  }

  UNSAFE_componentWillMount() {
    document.title = 'Invite to Download App';
    if (!isUserEmployee()) {
      this.props.navigate('/');
    } else {
      this.getTextLogCount();
    }
  }

  getTextLogCount = () => {
    Axios.get(TEXT_LOGS).then((res) => {
      if (res.status === 200 && res.data.status === 200 && res.data.data) {
        const { count } = res.data;
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          totalTextsSend: count,
        });
      }
    });
  };

  getRecords = () => {
    const {
      pagination,
      sortBy,
      sortOrder,
      selectedEntityTypes,
      searchFieldText,
    } = this.state;
    this.setState({ loading: true });
    Axios.post(SEARCH_FOR_BULK_DOWNLOAD_INVITATION, {
      page: pagination,
      sort_by: sortOrder,
      field: sortBy,
      entity_type: selectedEntityTypes,
      keyword: searchFieldText.toLowerCase(),
    }).then((res) => {
      this.setState({ loading: false });
      if (res.data.status === 200) {
        const { selectedData } = this.state;
        this.setState(
          {
            loading: false,
            listData: updateListDataAfterPageChange(
              res.data.data.data,
              selectedData,
            ),
            selectedAll: false,
            offset: getOffSetsAndLimit(res.data.data.total_entries, 25)[
              this.state.pagination - 1
            ],
            totalPages: Number(Math.ceil(res.data.data.total_entries / 25)),
            totalRecords: res.data.data.total_entries,
          },
          () => this.areAllContactsSelected(),
        );
      } else {
        this.setState({
          listData: null,
          totalRecords: 0,
          loading: false,
        });
      }
    });
  };

  areAllContactsSelected() {
    let x = 0;
    const { listData } = this.state;
    listData.map((e) => {
      if (e.checked) x += 1;
      return e;
    });
    const checkbox = document.getElementById('selectallcheckbox');
    if (x === listData.length) {
      this.setState({ selectedAll: true });
      if (checkbox) checkbox.indeterminate = false;
    } else {
      this.setState({ selectedAll: false });
      if (checkbox) checkbox.indeterminate = true;
    }
    if (x === 0) {
      if (checkbox) checkbox.indeterminate = '';
    }
  }

  handleFilterCheckbox = (input, index, crossclick = false) => {
    const {
      filterValuesArray: entityTypes,
      selectedFilterValuesArray: selectedEntityTypes,
      areAllFiltersSelected: areEntityTypesSelected,
    } = updatedSelectedFilterValues(
      this.state.entityTypes,
      index,
      this.state.areEntityTypesSelected,
      input,
      crossclick,
    );
    this.setState({
      entityTypes,
      selectedEntityTypes,
      areEntityTypesSelected,
    });
  };

  clearAll = () => {
    this.setState({
      entityTypes: [...getCpaPreferences().entity_type],
      searchFieldText: '',
      areEntityTypesSelected: false,
      selectedEntityTypes: [],
    });
  };

  handleSortBy = (sortBy) => {
    let { sortOrder } = this.state;
    const { selectedData, showOnlySelectedData } = this.state;
    if (this.state.sortBy === sortBy) {
      if (sortOrder === 'asc') {
        sortOrder = 'desc';
      } else {
        sortOrder = 'asc';
      }
    } else {
      sortOrder = 'asc';
    }
    if (showOnlySelectedData) {
      this.setState({
        sortOrder,
        sortBy,
        selectedData: sortSelectedList(selectedData, sortBy, sortOrder),
      });
    } else {
      this.setState(
        {
          sortOrder,
          sortBy,
          loading: true,
        },
        () => {
          this.getRecords();
        },
      );
    }
  };

  handleCheckbox = (index) => {
    const { listData } = this.state;
    let { selectedData } = this.state;
    const maxCount =
      this.state.maxCount === 0 ? 1000000 : parseInt(this.state.maxCount, 10);
    if (
      selectedData.length >= maxCount &&
      (listData[index].checked === undefined ||
        listData[index].checked === false)
    ) {
      this.setState({ showModal: true });
    } else {
      if (listData[index].checked) {
        listData[index].checked = false;
      } else {
        listData[index].checked = true;
      }
      if (listData[index].checked) {
        selectedData.push(listData[index]);
      } else {
        selectedData = removeElementFromContactArray(
          selectedData,
          listData[index],
        );
      }
      this.setState(
        {
          listData,
          selectedData,
        },
        () => this.areAllContactsSelected(),
      );
      if (
        (this.state.showerrormessgaefornextbtn ||
          this.state.showOnlySelectedData) &&
        !this.isAtleastOneContactSelected()
      ) {
        this.setState({ showOnlySelectedData: false });
      } else {
        this.setState({ showerrormessgaefornextbtn: false });
      }
    }
  };

  handleSelectAll = () => {
    const { listData, selectedAll } = this.state;
    let { selectedData } = this.state;
    const maxCount =
      this.state.maxCount === 0 ? 1000000 : parseInt(this.state.maxCount, 10);
    let isAllChecked = 0;
    let checked = false;
    listData.map((each) => {
      if (each.checked) {
        isAllChecked += 1;
      }
      return each;
    });
    if (!selectedAll && isAllChecked === 0) {
      checked = true;
    }
    if (selectedData.length === maxCount && checked) {
      this.setState({ showModal: true });
    } else {
      let count = selectedData.length;
      listData.map((each) => {
        const eachone = each;
        count += 1;
        if (count <= maxCount && checked) {
          eachone.checked = checked;
          selectedData.push(eachone);
        } else if (!checked) {
          eachone.checked = checked;
          selectedData = removeElementFromContactArray(selectedData, eachone);
        }
        return eachone;
      });
      this.setState(
        {
          listData,
          selectedData,
        },
        () => this.areAllContactsSelected(),
      );
    }
  };

  handlePageChange = (input) => {
    if (this.state.showOnlySelectedData) {
      this.updatePaginationDetails(input);
    } else {
      this.setState(
        {
          pagination: input,
          loading: true,
        },
        () => this.getRecords(),
      );
    }
  };

  handleInputField = (input) => {
    this.setState({ searchFieldText: input.target.value });
  };

  showSelectedData = (input) => {
    if (this.state.selectedData.length) {
      // if (totalTextsSend > text_limit && input && is_texting_allowed) {
      //   this.setState({
      //     showModal: true,
      //     popUpMessage: 'Warning: You have exceeded your text limit!',
      //   });
      // }
      this.setState({ showOnlySelectedData: input }, () =>
        this.updatePaginationDetails(1),
      );
    } else {
      AlertMessage(
        'error',
        'Please select atleast one contact to continue',
        3000,
      );
    }
    if (!input) {
      const { selectedData } = this.state;
      let { listData } = this.state;
      listData = updateListDataAfterPageChange(listData, selectedData);
      this.setState({ listData }, () => this.getRecords());
    }
  };

  sendDownloadRequests = () => {
    const ids = [];
    const { selectedData } = this.state;
    selectedData.map((each) => {
      ids.push(each.contact.id);
      return each;
    });
    if (ids.length) {
      Axios.post(BULK_DOWNLOAD_INVITE, { ids }).then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          AlertMessage('success', res.data.message, 2000);
          this.getTextLogCount();
          this.handleCancelBtn();
        } else {
          AlertMessage('error', res.data.message, 2000);
        }
        sendMixpanelEvent('SendBulkDownloadRequest', {
          ids,
          message: res.data && res.data.message ? res.data.message : null,
        });
      });
    }
  };

  handleDelete = (key, data) => {
    let { selectedData } = this.state;
    const eachOne = data;
    eachOne.checked = false;
    selectedData = removeElementFromContactArray(selectedData, eachOne);
    this.setState(
      {
        selectedData,
        showOnlySelectedData: selectedData.length,
      },
      () => {
        this.updatePaginationDetails(
          selectedData.length < 26 ? 1 : this.state.pagination,
        );
        if (!selectedData.length) {
          this.getRecords();
        }
      },
    );
  };

  handleCancelBtn = () => {
    this.setState({
      entityTypes: [...getCpaPreferences().entity_type],
      selectedEntityTypes: [],
      searchFieldText: '',
      sortBy: 'last_name',
      sortOrder: 'asc',
      pagination: 1,
      listData: [],
      selectedData: [],
      totalPages: 0,
      totalRecords: 0,
      showOnlySelectedData: false,
      selectedAll: false,
      areEntityTypesSelected: false,
    });
  };

  updatePaginationDetails = (pagination) => {
    let pageNumber = pagination;
    const { showOnlySelectedData, listData, selectedData } = this.state;
    const total_entries = showOnlySelectedData
      ? selectedData.length
      : listData.length;
    if (Number(Math.ceil(total_entries / 25)) < pagination) {
      pageNumber -= 1;
    }
    this.setState({
      offset: getOffSetsAndLimit(total_entries, 25)[pageNumber - 1],
      totalPages: Number(Math.ceil(total_entries / 25)),
      totalRecords: total_entries,
      pagination: pageNumber,
    });
  };

  searchKeyPress = (e) => {
    if (e.charCode === 13) {
      this.hanldeSearchBtn();
    }
  };

  hanldeSearchBtn = () => {
    this.setState(
      {
        selectedData: [],
        pagination: 1,
      },
      () => {
        this.getRecords();
      },
    );
  };

  generateCsvFile = () => {
    const {
      sortBy,
      sortOrder,
      selectedEntityTypes,
      selectedStatusTypes,
      totalRecords,
      searchFieldText,
    } = this.state;
    this.setState({ loading: true });
    Axios.post(SEARCH_FOR_BULK_DOWNLOAD_INVITATION, {
      page: 1,
      sort_by: sortOrder,
      field: sortBy,
      entity_type: selectedEntityTypes,
      status: selectedStatusTypes,
      keyword: searchFieldText.toLowerCase(),
      noofrecords: totalRecords,
    }).then((res) => {
      this.setState({ loading: false });
      if (res.data.status === 200 && res.data.data && res.data.data.data) {
        const { data } = res.data.data;
        const csvdata = [];
        csvdata.push([
          'First Name',
          'Middle Name',
          'Last Name',
          'Primary Email',
          'Last Download Request Sent',
          'Text Allowed',
        ]);
        data.forEach((e) => {
          const {
            contact: {
              first_name,
              middle_name,
              last_name,
              primary_email,
              app_download_request_sent_at,
            },
            is_texting_allowed,
          } = e;
          csvdata.push([
            first_name,
            middle_name,
            last_name,
            primary_email,
            app_download_request_sent_at
              ? `${moment(app_download_request_sent_at).format('MM/DD/YYYY')} `
              : ' ',
            is_texting_allowed ? 'Yes' : 'No',
          ]);
        });
        if (csvdata.length) {
          let csvContent = 'data:text/csv;charset=utf-8,';
          csvdata.forEach((rowArray) => {
            const row = rowArray.join(',');
            csvContent += `${row}\r\n`;
          });
          const encodedUri = encodeURI(csvContent);
          const link = document.createElement('a');
          link.setAttribute('href', encodedUri);
          link.setAttribute('download', 'BULK_DOWNLOAD_CONTACTS.csv');
          document.body.appendChild(link);
          link.click();
        }
      }
    });
  };

  render() {
    const {
      showOnlySelectedData,
      maxCount,
      selectedEntityTypes,
      searchFieldText,
      entityTypes,
      areEntityTypesSelected,
      selectedData,
      listData,
      sortBy,
      sortOrder,
      offset,
      selectedAll,
      showModal,
      totalRecords,
      popUpMessage,
      pagination,
      totalPages,
    } = this.state;
    return (
      <div className="BulkDownload">
        <header>
          <ul className="breadcrumb">
            <li className="breadcrumb-item">Bulk Action</li>
            <li className="breadcrumb-item">Invite to Download App</li>
          </ul>
        </header>
        {this.state.loading ? <LoadingOverlay /> : null}
        <div className="center-wrapper">
          <section>
            {!showOnlySelectedData ? (
              <SearchSection
                maxCount={maxCount}
                selectedEntityTypes={selectedEntityTypes}
                searchFieldText={searchFieldText}
                entityTypes={[
                  {
                    value: 'Select All',
                    label: 'Select All',
                    checked: areEntityTypesSelected,
                  },
                ].concat(entityTypes)}
                selectedData={selectedData}
                handleInputField={this.handleInputField}
                hanldeSearchBtn={this.hanldeSearchBtn}
                handleFilterCheckbox={this.handleFilterCheckbox}
                uniquekey={(input) => input.contact.id}
                clearAll={this.clearAll}
                searchKeyPress={this.searchKeyPress}
                generateCsvFile={totalRecords ? this.generateCsvFile : false}
                placeHolder="Search Contact"
              />
            ) : (
              <div className="pb-4 section-title section-title--fullwidth">
                <div className="col-container">
                  <div className="col">
                    <h5 className="text-small">
                      Invitation would be sent to the following Contacts:
                    </h5>
                  </div>
                </div>
              </div>
            )}
            {listData === undefined || listData === null ? <NoRecords /> : null}
            {listData && listData !== null && totalRecords ? (
              <div>
                <SearchList
                  tableHeaders={bulkDownloadPageTableHeaders}
                  listData={
                    showOnlySelectedData
                      ? [...selectedData.slice(offset[0] - 1, offset[1])]
                      : listData
                  }
                  sortBy={sortBy}
                  sortOrder={sortOrder}
                  handleSortBy={this.handleSortBy}
                  handleCheckbox={
                    !showOnlySelectedData ? this.handleCheckbox : () => {}
                  }
                  handleSelectAll={this.handleSelectAll}
                  selectedAll={selectedAll}
                  showCheckbox={!showOnlySelectedData}
                  showDeleteIcon={showOnlySelectedData}
                  handleDelete={this.handleDelete}
                  showActionDiv
                />
                <Pagination
                  currentPage={pagination}
                  totalPages={totalPages}
                  totalRecords={totalRecords}
                  handlePageChange={this.handlePageChange}
                  handleSelectAll={this.handleSelectAll}
                  selectedAll={selectedAll}
                  offset={offset[0]}
                  off={offset[1]}
                />
              </div>
            ) : null}
            {totalRecords && !showOnlySelectedData ? (
              <div className="formbtn BulkDownloadButtonGroup">
                <div className="btn-wrap btn--leftspace">
                  <button
                    type="button"
                    className="btn btn-outline-light"
                    onClick={this.handleCancelBtn}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.showSelectedData(true)}
                  >
                    Next Step
                  </button>
                </div>
              </div>
            ) : null}
            {showOnlySelectedData ? (
              <div className="formbtn BulkDownloadButtonGroup">
                <div className="btn-wrap btn--leftspace">
                  <button
                    type="button"
                    className="btn btn-outline-light"
                    onClick={() => this.showSelectedData(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.sendDownloadRequests}
                  >
                    Send Invitation
                  </button>
                </div>
              </div>
            ) : null}
          </section>
        </div>
        {showModal ? (
          <ConfirmationModal
            isOpen={showModal}
            messageText={
              !popUpMessage
                ? `You can select up to ${this.state.maxCount} contacts`
                : popUpMessage
            }
            noBtnClick={() => {
              this.setState({ showModal: false, popUpMessage: undefined });
            }}
            noBtnText="Close"
          />
        ) : null}
      </div>
    );
  }
}

export default withStorageData(BulkRequestDownload);
