import { Autocomplete, Box, Paper, PaperProps, TextField } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { WorkflowRequestsWorkflowRequestFragment } from 'gql/graphql';
import { useEmpAndContactOfAccount } from 'hooks/search-task-contact-hook';
import { useEffect, useState } from 'react';
import { useUpdateDraftWorkflowRequest } from 'components/Requests/hooks/useUpdateDraftWorkflowRequest';
import { TooltipStyled } from 'ui/TooltipStyled/TooltipStyled';
import { TextCuttoff } from 'ui/TextCutoff/TextCuttoff';
import { createFilterOptions } from '@mui/base';
import SearchIcon from '@mui/icons-material/Search';

type AssignedToCellProps = {
  params: GridRenderCellParams<
    WorkflowRequestsWorkflowRequestFragment,
    any,
    any,
    GridTreeNodeWithRender
  >;
};

export const AssignedToCell = ({ params }: AssignedToCellProps) => {
  const {
    assignedContacts,
    assignedAccount,
    id: workflowRequestId,
    status: workflowStatus,
  } = params.row;
  const updateWorkflowRequest = useUpdateDraftWorkflowRequest();
  const [contactData, setContactData] = useState<LegacyContact[] | undefined>();
  const [selectedContact, setSelectedContact] = useState<
    LegacyContact | undefined
  >();
  const { data: originalContactData } = useEmpAndContactOfAccount(
    assignedAccount ? assignedAccount.legacyId : undefined,
  );

  useEffect(() => {
    if (originalContactData && originalContactData.length > 0) {
      const filteredData = originalContactData.filter(
        (data) => data.type === 'contact',
      );
      setContactData(filteredData);

      if (assignedContacts && assignedContacts.length > 0)
        setSelectedContact(
          filteredData.find((item) => item.uuid === assignedContacts[0].id),
        );
    }
  }, [originalContactData, assignedContacts]);

  // used to manage when a contact or employee are selected
  const handleContactChange = (
    event: React.SyntheticEvent,
    value: LegacyContact | null,
  ) => {
    // This updating of the row is a cheat; it works; but it's a cheat.
    // All we do is provide an array with the id or an empty array to toggle
    // the Accounts.
    const updatedRow = {
      ...params.row,
      assignedContacts: value
        ? [
            {
              id: value.uuid,
            },
          ]
        : [],
    };

    params.api.updateRows([updatedRow]);

    setSelectedContact(value!);

    updateWorkflowRequest(workflowRequestId, {
      assignedContactIds: [value?.uuid],
    });
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    ignoreAccents: true,
    ignoreCase: true,
    stringify: (option: any) => option.label,
  });

  const toolTipText = !assignedAccount
    ? 'An "Account" must be selected in order to assign a contact'
    : '';

  return (
    <Box
      display="flex"
      gap="5px"
      flexDirection="column"
      alignItems="start"
      width="100%"
      onClick={(event) => event.stopPropagation()}
    >
      {workflowStatus.toUpperCase() === 'DRAFT' ? (
        <TooltipStyled title={toolTipText}>
          <Autocomplete
            key={`${workflowRequestId}-${selectedContact?.uuid}`}
            popupIcon={null}
            // eslint-disable-next-line react/no-unstable-nested-components
            PaperComponent={(props: PaperProps) => {
              if (!contactData?.length) return null;
              return <Paper {...props} />;
            }}
            noOptionsText=""
            disabled={toolTipText.length > 0}
            options={contactData || []}
            getOptionLabel={(option) => option.label}
            isOptionEqualToValue={(option, value) =>
              option.label === value.label
            }
            filterOptions={filterOptions}
            onChange={handleContactChange}
            renderInput={(inputParams) => (
              <TextField
                {...inputParams}
                fullWidth
                variant="standard"
                InputProps={{
                  ...inputParams.InputProps,
                  startAdornment: <SearchIcon sx={{ color: 'gray', mr: 1 }} />,
                }}
                placeholder="Search"
              />
            )}
            value={selectedContact}
            fullWidth
          />
        </TooltipStyled>
      ) : (
        <TextCuttoff
          text={[
            ...(assignedContacts?.map(
              (contact) =>
                `${contact?.firstName || ''} ${contact?.lastName || ''}`,
            ) || []),
          ]}
        />
      )}
    </Box>
  );
};
