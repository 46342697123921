/* eslint-disable no-nested-ternary */
import React from 'react';
import { Link } from 'react-router-dom-latest';
import CustomImage from 'common/CustomImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@awesome.me/kit-7c5308637e/icons/classic/regular';

const EachApp = ({
  appDetails,
  handleConnect,
  handleDisconnect,
  handleConnectBtnLabel,
  handleDisconnectBtnLabel,
  emailButton,
}) => (
  <div className="col-lg-6 col-sm-12 col-md-6">
    <div className="card light-outline mb-4">
      <div className="card-body">
        <h5 className="card-title d-flex align-items-center">
          <CustomImage
            src={`${appDetails.id}.png`}
            style={{
              maxHeight: '40px',
              maxWidth: '100%',
              paddingRight: '12px'
            }}
          />
          <span style={{ paddingRight: '10px' }}>
            {appDetails.name}
          </span>
          {appDetails.token_exits && (
            <div className="green-badge">
              CONNECTED
            </div>
          )}
          {appDetails.name === 'SmartVault' && appDetails.token_exits && <div className="dropdown dropupWrap dropupWrap--right ml-auto">
            <a
              href="/#"
              className="btn btn-sm btn-link btn--onlyicon dropdown-toggle"
              data-toggle="dropdown"
            >
              <FontAwesomeIcon icon={faEllipsisVertical} className="fa-xl" />
              <span className="dropupWrap--caret" />
            </a>
            <div className="dropdown-menu">
              <Link to="smartvault_account_links" className="dropdown-item">
                Manage Account Links
              </Link>
            </div>
          </div>}
        </h5>
        <div className="form-group form-inline-label">
          <label className="align-self-start" style={{ minWidth: '0' }}>
            {appDetails.email ? 'Email:' : ''}
          </label>
          <div className="labelValue">
            <span>{appDetails.email ? appDetails.email : ''}</span>
          </div>
        </div>
        {appDetails.token_exits ? (
          <div className="text-center">
            <button
              type="button"
              className="btn btn-outline-light col mt-2 card-button"
              onClick={() => handleDisconnect(appDetails)}
            >
              {handleDisconnectBtnLabel || 'Disconnect'}
            </button>
          </div>
        ) : emailButton ? (
          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary col mt-2 card-button"
              onClick={() => handleConnect(appDetails)}
            >
              {handleConnectBtnLabel || 'Connect'}
            </button>
          </div>
        ) : (
          <div className="text-center">
            <button
              type="button"
              className="btn btn-primary col mt-2 card-button"
              onClick={() => handleConnect(appDetails)}
            >
              {handleConnectBtnLabel || 'Connect'}
            </button>
          </div>
        )}
      </div>
    </div>
  </div>
)

export default EachApp;
