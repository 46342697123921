/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-script-url */
/* eslint-disable react/jsx-no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import Axios from 'axios';
import ReactTooltip from 'react-tooltip';
import { Link } from 'react-router-dom-latest';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Stack,
  Typography,
  Divider,
} from '@mui/material';

import {
  DEACTIVATE_CONTACT,
  REACTIVATE_CONTACT,
  SEND_INVITE_LINK,
  SEND_FORGOT_LINK,
  MIXPANEL_INVITE_EVENT,
  MIXPANEL_ARCHIVE_CONTACT_EVENT,
} from 'constants/constants';
import ConfirmationModal from 'common/ConfirmationModal';
import { PlusIcon, MinusIcon } from 'common/Icons';
import {
  AlertMessage,
  generateInitials,
  generateFullName,
  statusClassName,
  FORMATPHONENUMBER,
} from 'utilities/utils';
import { trackMixpanelEvent } from 'Mixpanel/mixpanelfn';
import styled from '@emotion/styled';
import { contactAddressFieldNames } from './ContactCreation/ContactUtilities';
import Activity from './Activity';
// import { CheckboxField, DisplayData } from 'common/CustomFields';

const StyledDiv = styled.div`
  display: inline-block;
  .Ext__Label {
    min-width: 0px;
    padding-left: 5px;
    text-transform: capitalize;
    color: #c4cdd5;
  }
`;
const DisplayAddress = (props) => {
  const { data } = props;
  const {
    addressLine1Name,
    addressLine2Name,
    cityName,
    stateName,
    countryName,
    zipName,
  } = props.fieldNames;
  if (
    data[addressLine1Name] !== null ||
    data[cityName] !== null ||
    data[stateName] !== null ||
    data[countryName] !== null ||
    data[zipName] !== null
  ) {
    return (
      <address>
        {data[addressLine1Name] !== null ? `${data[addressLine1Name]}, ` : null}
        {data[addressLine2Name] !== null ? `${data[addressLine2Name]}, ` : null}
        {data[addressLine1Name] !== null || data[addressLine2Name] !== null ? (
          <br />
        ) : null}
        {data[cityName] !== null ? `${data[cityName]}, ` : null}
        {data[stateName] !== null ? `${data[stateName]}, ` : null}
        {data[zipName] !== null ? `${data[zipName]}, ` : null}
        {data[cityName] !== null ||
        data[stateName] !== null ||
        data[zipName] !== null ? (
          <br />
        ) : null}
        {data[countryName]}
      </address>
    );
  }
  return '-';
};

class ContactInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contactId: this.props.contactId ? this.props.contactId : undefined,
      contact:
        this.props.data && this.props.data.contact
          ? { ...this.props.data.contact, avatar: this.props.data.avatar }
          : undefined,
      contactAddresses:
        this.props.data && this.props.data.contact_address
          ? this.props.data.contact_address
          : undefined,
      contactPhones:
        this.props.data && this.props.data.contact_phone
          ? this.props.data.contact_phone
          : undefined,
      has_mobile_app: this.props.data && this.props.data.has_mobile_app,
      contactEmails: [],
      showDeleteConfirmation: false,
      inviteInProgress: false,
      statusCss: 'is-status',
      is_invitation_expired:
        this.props.data && this.props.data.is_invitation_expired
          ? this.props.data.is_invitation_expired
          : false,
      expandedPanels: [],
    };
  }

  UNSAFE_componentWillMount() {
    this.getData(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data } = this.props;
    if (nextProps?.data?.contact?.id !== data?.contact?.id) {
      this.getData(nextProps);
    }
  }

  getData = (propsData) => {
    const contactEmails =
      propsData.data && propsData.data.contact_email
        ? propsData.data.contact_email
        : [];
    const con =
      propsData.data && propsData.data.contact
        ? { ...propsData.data.contact, avatar: propsData.data.avatar }
        : undefined;
    const invitationExp =
      propsData.data && propsData.data.is_invitation_expired
        ? propsData.data.is_invitation_expired
        : false;
    if (con.primary_email !== null && con.primary_email.length) {
      contactEmails.unshift({
        email_type: 'Primary',
        email: con.primary_email,
      });
    }
    this.setState({
      contactId: propsData.contactId ? propsData.contactId : undefined,
      contact: con,
      contactAddresses:
        propsData.data && propsData.data.contact_address
          ? propsData.data.contact_address
          : undefined,
      contactPhones:
        propsData.data && propsData.data.contact_phone
          ? propsData.data.contact_phone
          : undefined,
      has_mobile_app: propsData.data && propsData.data.has_mobile_app,
      is_invitation_expired: invitationExp,
      statusCss: statusClassName(invitationExp ? 'expired' : con.status),
      contactEmails,
    });
  };

  showDeleteConfirmationModal = () => {
    this.setState({
      showDeleteConfirmation: !this.state.showDeleteConfirmation,
    });
  };

  deleteRecord = () => {
    Axios.put(`${DEACTIVATE_CONTACT}/${this.props.contactId}`).then((res) => {
      if (res.status === 204 || res.status === 200) {
        AlertMessage('success', res.data.message, 3000);
        this.setState({
          showDeleteConfirmation: false,
          contact: { ...this.state.contact, status: 'Inactive' },
          statusCss: statusClassName('Inactive'),
        });
        trackMixpanelEvent(MIXPANEL_ARCHIVE_CONTACT_EVENT);
      } else {
        AlertMessage('error', res.data.message, 3000);
        this.setState({ showDeleteConfirmation: false });
      }
    });
  };

  sendInvite = () => {
    const { contact } = this.state;
    if (
      !contact.primary_email ||
      contact.primary_email === null ||
      !contact.primary_email.length
    ) {
      AlertMessage('error', 'Contact should have primary email', 3000);
    } else if (!this.state.inviteInProgress) {
      this.updateInviteProgress(true);
      Axios.get(`${SEND_INVITE_LINK}/${this.state.contactId}`)
        .then((res) => {
          if (res.status === 200 && res.data.status === 200) {
            AlertMessage('success', res.data.message, 3000);
            this.setState({
              is_invitation_expired: false,
              contact: { ...this.state.contact, status: 'Invited' },
              statusCss: 'is-status is-status--busy',
            });
            this.updateInviteProgress(false);
            if (res.data && res.data.cpa_user_id) {
              trackMixpanelEvent(MIXPANEL_INVITE_EVENT, {
                distinct_id: res.data.cpa_user_id,
                type: 'CONTACT_INVITE',
              });
            }
          } else {
            AlertMessage('error', res.data.message, 3000);
          }
        })
        .catch(() => {
          AlertMessage('error', 'Something went wrong', 3000);
          this.updateInviteProgress(false);
        });
    }
  };

  reactivateContact = () => {
    const contactData = {
      ...this.props.data,
      ...this.props.data.contact,
      status: 'New',
    };
    Axios.put(
      `${REACTIVATE_CONTACT}/${this.props.contactId}`,
      contactData,
    ).then((res) => {
      this.setState({
        contact: { ...this.state.contact, status: 'New' },
        statusCss: statusClassName('New'),
      });
      if (res.status === 200 && res.data.success) {
        AlertMessage('success', res.data.message, 3000);
      } else {
        AlertMessage('error', res.data.message, 3000);
      }
    });
  };

  sendForgotPassword = () => {
    if (!this.state.inviteInProgress) {
      this.updateInviteProgress(true);
      Axios.get(`${SEND_FORGOT_LINK}/${this.state.contact.cpa_user_id}`)
        .then((res) => {
          this.updateInviteProgress(false);
          if (res.status === 200 && res.data.status) {
            AlertMessage('success', res.data.message, 3000);
          } else {
            AlertMessage('error', res.data.message, 3000);
          }
        })
        .catch(() => {
          AlertMessage('error', 'Something went wrong', 3000);
          this.updateInviteProgress(false);
        });
    }
  };

  updateInviteProgress = (input) => {
    this.setState({ inviteInProgress: input });
  };

  isExpanded = (panel) => {
    const { expandedPanels } = this.state;

    return expandedPanels.includes(panel);
  };

  handleChange = (panel) => (event, expanded) => {
    const { expandedPanels } = this.state;

    if (expanded) {
      if (!expandedPanels.includes(panel)) {
        this.setState({
          expandedPanels: [...expandedPanels, panel],
        });
      }
    } else {
      const index = expandedPanels.findIndex((value) => value === panel);
      if (index !== -1) {
        const newExpandedPanels = [...expandedPanels];
        newExpandedPanels.splice(index, 1);
        this.setState({ expandedPanels: newExpandedPanels });
      }
    }
  };

  render() {
    const { contactId } = this.props;
    const {
      contact,
      contactAddresses,
      contactPhones,
      contactEmails,
      statusCss,
      is_invitation_expired,
      has_mobile_app,
    } = this.state;
    return (
      <div>
        <div className="center-wrapper overflow-x-hide">
          <div className="mb-4 section-title row">
            <div className="col-sm-8">
              <div className="d-flex">
                <div className="avatar avatar--w40 align-self-center mr-2">
                  {contact.avatar &&
                  contact.avatar !== null &&
                  contact.avatar.length ? (
                    <img
                      src={contact.avatar}
                      alt="test"
                      className="user-photo rounded-circle"
                    />
                  ) : (
                    <span className="uName">{generateInitials(contact)}</span>
                  )}
                </div>
                <div className="infoAccount">
                  <h2 className="text-large onlineStatus">
                    {generateFullName(contact)}
                    <i
                      className={statusCss}
                      data-tip
                      data-for="contact-status"
                    />
                    <ReactTooltip id="contact-status" effect="solid">
                      <span>
                        {is_invitation_expired
                          ? 'Invite Expired'
                          : contact.status}
                      </span>
                    </ReactTooltip>
                  </h2>
                  <h4 className="subHeadline text-dark text-uppercase font-weight-bold mb-0">
                    {contact ? contact.job_title : null}
                  </h4>
                </div>
              </div>
            </div>
            <div className="col-sm-4 d-flex justify-content-end align-items-center">
              {contact.status.toLowerCase() !== 'inactive' ? (
                <Link
                  className="btn btn-primary"
                  to={`/contactdetails/message/${this.props.contactId}`}
                >
                  <i>+</i> Message
                </Link>
              ) : null}
              {/* <button className="btn btn-outline-light">Re-Send Invite</button> */}

              {contact.status.toLowerCase() !== 'inactive' ? (
                <div className="dropdown dropdown--small btn-div">
                  <a
                    className="btn btn-link btn--onlyicon dropdown-toggle"
                    data-toggle="dropdown"
                    aria-expanded="true"
                  >
                    <i className="icon-more" />
                    <span className="dropupWrap--caret" />
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <Link
                      to={`/contact/edit/${this.props.contactId}`}
                      className="dropdown-item"
                    >
                      Edit Contact
                    </Link>
                    {contact.status.toLowerCase() === 'new' ||
                    contact.status.toLowerCase() === 'invited' ? (
                      <a
                        href="javascript:"
                        className="dropdown-item"
                        onClick={this.sendInvite}
                      >
                        {contact.status.toLowerCase() === 'new'
                          ? 'Send Invite'
                          : 'Resend Invite'}
                      </a>
                    ) : null}
                    {contact.status.toLowerCase() === 'active' ? (
                      <a
                        href="javascript:"
                        className="dropdown-item"
                        onClick={this.sendForgotPassword}
                      >
                        Reset Password
                      </a>
                    ) : null}
                    {contact.status.toLowerCase() !== 'inactive' ? (
                      <a
                        href="javascript:"
                        className="dropdown-item"
                        onClick={this.showDeleteConfirmationModal}
                      >
                        Archive Contact
                      </a>
                    ) : null}
                  </div>
                </div>
              ) : (
                <button
                  type="button"
                  className="btn btn-outline-light"
                  onClick={this.reactivateContact}
                >
                  Reactivate Contact
                </button>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="form-group form-inline-label col-12 align-self-start">
                  <label className="align-self-start">Email:</label>
                  <div className="labelValue">
                    {contactEmails && contactEmails.length
                      ? contactEmails.map((each) => (
                          <div key={`${each.email}_email`}>
                            <span>
                              {each.email}
                              <label className="label-small text-uppercase ml-2 vertical-middle text-light">
                                {each.email_type}
                              </label>
                            </span>
                            <br />
                          </div>
                        ))
                      : '-'}
                  </div>
                </div>
                <div className="form-group form-inline-label col-12 align-self-start">
                  <label className="align-self-start">Phone:</label>
                  <div className="labelValue">
                    {contactPhones
                      ? contactPhones.map((each) => (
                          <div className="row no-gutters">
                            <div key={`${each.id}phone`}>
                              <span>
                                {FORMATPHONENUMBER(each.phone_number)}
                                <label className="text-uppercase ml-2 vertical-middle text-light remove-min-width">
                                  {each.phone_type}
                                </label>
                              </span>
                              {each.phone_type === 'Work' && (
                                <StyledDiv className="Ext__Container">
                                  <span className="Ext__Value">
                                    {each.phone_extension}
                                  </span>
                                  <label className="Ext__Label">EXT </label>
                                </StyledDiv>
                              )}
                            </div>
                            {each.is_texting_allowed && (
                              <div className="d-flex mt-1 mb-2">
                                <label className="text-uppercase vertical-middle">
                                  Text Notifications?
                                </label>
                                <div className="checkbox checkbox-primary check-container">
                                  <label
                                    htmlFor="is_texting_allowed"
                                    className="remove-min-width"
                                  >
                                    <input
                                      name="is_texting_allowed"
                                      id="is_texting_allowed"
                                      type="checkbox"
                                      value="true"
                                      checked
                                    />
                                    <i className="checkmark" />
                                  </label>
                                </div>
                              </div>
                            )}
                            {each.is_bandwidth_texting_allowed &&
                              each.has_user_unsubscribed && (
                                <div
                                  className="d-flex s-alert-box-inner p-2 rounded alert-success"
                                  style={{
                                    marginTop: '-6px',
                                    marginBottom: '2px',
                                  }}
                                >
                                  <i className="icon-expired text-light pr-1" />
                                  <span className="text-body">
                                    User has unsubscribed from SMS Conversations
                                  </span>
                                </div>
                              )}
                          </div>
                        ))
                      : '-'}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-5">
              <div className="row">
                <div className="form-group form-inline-label col-12">
                  <label className="align-self-start">Address:</label>
                  <div className="labelValue">
                    {contactAddresses
                      ? contactAddresses.map((each) => (
                          <div className="row" key={`${each.id}address`}>
                            <div className="col-sm-8">
                              <DisplayAddress
                                data={each}
                                fieldNames={contactAddressFieldNames}
                              />
                            </div>
                            <div className="col-sm-3">
                              <label className="label-small text-uppercase ml-2 vertical-middle text-light">
                                {each.address_type}
                              </label>
                            </div>
                          </div>
                        ))
                      : '-'}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="form-group form-inline-label col">
              <label className="d-flex">
                Mobile App ?
                <div className="checkbox checkbox-primary check-container col">
                  <label htmlFor="mobile_app_installed">
                    <input
                      name="mobile_app_installed"
                      id="mobile_app_installed"
                      type="checkbox"
                      value="true"
                      readOnly
                      checked={has_mobile_app}
                    />
                    <i className="checkmark" />
                  </label>
                </div>
              </label>
            </div>
          </div>
          {this.state.showDeleteConfirmation ? (
            <ConfirmationModal
              isOpen
              messageText="Attention: The contact deactivation process will close all open tasks related. Proceed?"
              yesBtnText="Proceed"
              noBtnText="Cancel"
              yesBtnClick={this.deleteRecord}
              noBtnClick={this.showDeleteConfirmationModal}
            />
          ) : null}
        </div>
        <div className="center-wrapper overflow-x-hide">
          <div className="row">
            <div className="col-12">
              <Divider />

              <Stack alignItems="center" padding={4}>
                <Typography variant="gray_subtitle1">
                  Expand Sections Below To View Details
                </Typography>
              </Stack>
            </div>

            <div className="col-12">
              <Accordion
                disableGutters
                square
                elevation={0}
                expanded={this.isExpanded('contact_history')}
                onChange={this.handleChange('contact_history')}
              >
                <AccordionSummary
                  id="contact_history-header"
                  expandIcon={
                    this.isExpanded('contact_history') ? (
                      <MinusIcon />
                    ) : (
                      <PlusIcon />
                    )
                  }
                  aria-controls="contact_history-content"
                >
                  <Typography fontWeight="bolder" variant="h6">
                    Contact History
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Activity for="contact" id={contactId} />
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactInfo;
