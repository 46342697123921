import { useState } from 'react';
import {
  Alert,
  Select,
  SelectChangeEvent,
  MenuItem,
  Stack,
  Typography,
  useTheme,
  Box,
  Button,
} from '@mui/material';
import {
  useGetApiV5FirmsCpaWorkflowTemplates,
  usePostApiV5FirmsCpaWorkflowTemplates,
  usePutApiV5FirmsCpaWorkflowTemplatesId,
} from 'api/v5/cpa-workflow-templates/cpa-workflow-templates';
import {
  useGetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplates,
  useGetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesId,
} from 'api/v5/liscio-workflow-templates/liscio-workflow-templates';
import { toast } from 'react-toastify';
import LoadingIndicator from 'common/LoadingIndicator';
import { StandardOrganizer } from './components/StandardOrganizer/StandardOrganizer';
import { ComprehensiveOrganizer } from './components/ComprehensiveOrganizer/ComprehensiveOrganizer';
import { SimpleOrganizer } from './components/SimpleOrganizer/SimpleOrganizer';

export const OrganizerTemplates = () => {
  const theme = useTheme();
  const [selectedTemplateId, setSelectedTemplateId] = useState<string | null>(
    null,
  );

  // gets the users current saved template
  const {
    data: cpaData,
    isLoading: isLoadingCurrent,
    refetch: refetchCurrent,
  } = useGetApiV5FirmsCpaWorkflowTemplates();
  // gets the list of available templates
  const { data: templates, isLoading: isLoadingOptions } =
    useGetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplates();
  // gets the details for the currently selected template
  const { data: selectedTemplateData, isLoading: isLoadingSelection } =
    useGetApiV5FirmsWorkflowTemplatesLiscioWorkflowTemplatesId(
      selectedTemplateId ?? '',
      {
        query: { enabled: Boolean(selectedTemplateId) },
      },
    );

  const { mutate: saveNewSelectionMutation, isLoading: isLoadingSaveNew } =
    usePostApiV5FirmsCpaWorkflowTemplates();
  const {
    mutate: saveExistingSelectionMutation,
    isLoading: isLoadingSaveExisting,
  } = usePutApiV5FirmsCpaWorkflowTemplatesId();

  const currentTemplateId = cpaData?.[0]?.workflow_template?.uuid;

  function handleSelectTemplate(event: SelectChangeEvent<string>) {
    setSelectedTemplateId(event.target.value);
  }

  function handleApplyTemplate() {
    if (!selectedTemplateId) {
      return;
    }

    if (cpaData?.[0]?.uuid) {
      saveExistingSelectionMutation(
        {
          data: {
            position: 0,
            workflow_template_uuid: selectedTemplateId,
          },
          id: cpaData[0].uuid,
        },
        {
          onSuccess: handleSuccess,
          onError: handleError,
        },
      );
      return;
    }
    saveNewSelectionMutation(
      {
        data: {
          position: 0,
          workflow_template_uuid: selectedTemplateId,
        },
      },
      {
        onSuccess: handleSuccess,
        onError: handleError,
      },
    );
  }

  const handleError = () => {
    toast.error('An error occurred while saving the template.');
  };

  const handleSuccess = () => {
    refetchCurrent();
    toast.success('Template saved successfully.');
  };

  const templateToShow =
    selectedTemplateData ?? cpaData?.[0]?.workflow_template;
  let content = <div />;
  const current = selectedTemplateId
    ? selectedTemplateId === currentTemplateId
    : true;
  switch (templateToShow?.name) {
    case 'Comprehensive Tax Organizer - 2024':
      content = <ComprehensiveOrganizer current={current} />;
      break;
    case 'Simple Tax Organizer - 2024':
      content = <SimpleOrganizer current={current} />;
      break;
    case 'Standard Tax Organizer - 2024':
      content = <StandardOrganizer current={current} />;
      break;
    default:
      content = <div />;
  }

  return (
    <Box height="calc(100vh - 100px)" display="flex">
      <Stack gap="1rem" width="20rem" padding="2rem 1.5rem">
        <Box>
          <Typography variant="h5" fontWeight="bold">
            Organizer Templates
          </Typography>
          <Typography>
            Customize your firm&apos;s automated tax organizer template by
            selecting from the different versions below.
          </Typography>
        </Box>
        <Stack>
          {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
          <label htmlFor="template-version">
            <Typography component="span" fontWeight="bold">
              Template version:
            </Typography>
          </label>
          <Select
            label="Template version"
            aria-label="Template version"
            onChange={handleSelectTemplate}
            disabled={isLoadingOptions || isLoadingCurrent}
            value={selectedTemplateId || currentTemplateId || ''}
            notched={false}
          >
            {templates?.map((template) =>
              template.uuid ? (
                <MenuItem key={template.uuid} value={template.uuid}>
                  {template.name}
                </MenuItem>
              ) : null,
            )}
          </Select>
        </Stack>
        <Alert severity="info" icon={false}>
          After changing your template, all newly generated organizers will use
          the selected template. Existing organizer requests will not be
          affected.
        </Alert>
        <Box alignSelf="flex-start">
          <Button
            variant="contained"
            onClick={handleApplyTemplate}
            disabled={
              isLoadingSaveExisting || isLoadingSaveNew || !selectedTemplateId
            }
          >
            Apply
          </Button>
        </Box>
      </Stack>
      <Stack
        flex="1"
        padding="1rem"
        gap="1rem"
        bgcolor={theme.palette.grey[100]}
        overflow="auto"
        alignItems="center"
      >
        {(isLoadingCurrent ||
          (isLoadingSelection && Boolean(selectedTemplateId))) && (
          <LoadingIndicator loading />
        )}
        {templateToShow && (
          <Stack
            padding="2rem 1.5rem"
            bgcolor={theme.palette.background.paper}
            maxWidth="815px"
            borderRadius="0.5rem"
          >
            {content}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
