import {
  Stack,
  Button,
  // Dialog,
  Divider,
  Typography,
  debounce,
  Box,
  Popover,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { DUE_DATE } from 'constants/constants';
import CustomDatePicker from 'wrappers/DatePickerUtil';
import { UpdateWorkflowRequestMutationVariables } from 'gql/graphql';
import ContactSelector from 'common/ContactSelector/ContactSelector';
import { TextFieldStyled } from 'ui/Form/TextFieldStyled';
import {
  AsigneeList,
  AsigneeListContact,
} from 'components/Requests/components/SendRequest/components/AssigneeList/AssigneeList';
import { useEmpAndContactOfAccount } from 'hooks/search-task-contact-hook';
import { BulkSelectButton } from 'components/Requests/components/SendRequest/components/BulkSelectButton/BulkSelectButton';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface SendProps {
  description?: string;
  title: string;
  onUpdate: (
    variables: Partial<UpdateWorkflowRequestMutationVariables>,
  ) => void;
  onSend: (variables?: Partial<UpdateWorkflowRequestMutationVariables>) => void;
  onAssignmentChanged: (options: {
    newContacts?: AsigneeListContact[];
    newEmployees?: AsigneeListContact[];
    accountId?: string;
    isBulkAssignment?: boolean;
  }) => void;
  requestAccountId?: string;
  requestContacts?: AsigneeListContact[];
  requestEmployees?: AsigneeListContact[];
  rpaRequest?: boolean;
  requestDueDate?: string | null;
  isBulk: boolean;
  disableSend?: boolean;
  workflowType?: string | null;
}

export default function Send({
  description,
  title,
  onUpdate,
  onSend,
  requestAccountId,
  requestContacts,
  requestEmployees,
  onAssignmentChanged,
  rpaRequest,
  requestDueDate,
  isBulk,
  disableSend,
  workflowType,
}: SendProps) {
  const { enableBulkSend, hideEmployees } = useFlags();
  const [dueDate, setDueDate] = useState<Date | null>(
    requestDueDate ? new Date(requestDueDate) : null,
  );
  // const [dueDateOpen, setDueDateOpen] = useState(false);
  const [contactSelectAnchor, setContactSelectAnchor] =
    useState<HTMLDivElement | null>(null);
  const [rpaHelperText, setHelperText] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [removeRpaHelperText, setRemoveRpaHelperText] = useState(false);
  const [useErrorColor, setUseErrorColor] = useState(false);
  const [accountId, setAccountId] = useState<number | undefined | null>(
    requestAccountId ? Number(requestAccountId) : undefined,
  );
  const contactListLength =
    useEmpAndContactOfAccount(requestAccountId).data?.length;

  const contacts = useMemo(
    () => [...(requestContacts || []), ...(requestEmployees || [])],
    [requestContacts, requestEmployees],
  );

  useEffect(() => {
    if (requestDueDate) setDueDate(new Date(requestDueDate));
  }, [requestDueDate]);

  useEffect(() => {
    if (
      !removeRpaHelperText &&
      requestAccountId &&
      contactListLength &&
      rpaRequest === true
    ) {
      setHelperText('Account matched, please select recipient');
      setUseErrorColor(false);
    } else if (
      !removeRpaHelperText &&
      requestAccountId &&
      contactListLength === 1 &&
      rpaRequest === true
    ) {
      setHelperText('Mapped from ID');
      setUseErrorColor(false);
    } else if (
      !removeRpaHelperText &&
      contactListLength === null &&
      rpaRequest === true
    ) {
      setHelperText('No ID Match for client id');
      setUseErrorColor(true);
    } else {
      setHelperText('');
    }
  }, [contactListLength, removeRpaHelperText, requestAccountId, rpaRequest]);

  const updateTitle = useMemo(
    () => debounce((newTitle: string) => update({ title: newTitle }), 1000),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const handleContactsChange = (newContacts: AsigneeListContact[]) => {
    onAssignmentChanged({ newContacts, isBulkAssignment: true });
  };

  const handleContactChange = (
    value: LegacyContact,
    contactAccountId?: number | null,
  ) => {
    if (contacts.some((c) => c.uuid === value.uuid)) {
      return;
    }
    setError(null);
    // employees don't have accounts
    if (contactAccountId && requestEmployees?.length) {
      setError('Contacts must all be in the same account');
      return;
    }
    if (!accountId) {
      setAccountId(contactAccountId);
    }

    if (value?.assigne_type === 'user') {
      if (value.type === 'emp') {
        const newEmployees = [...(requestEmployees || []), value];
        onAssignmentChanged({
          newEmployees,
        });
        return;
      }
    }

    onAssignmentChanged({
      newContacts: [...(requestContacts || []), value],
      accountId:
        contactAccountId !== null &&
        contactAccountId !== undefined &&
        contactAccountId !== accountId
          ? `${contactAccountId}`
          : undefined,
    });
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateTitle(event.target.value);
  };

  const update = (
    variables: Partial<UpdateWorkflowRequestMutationVariables>,
  ) => {
    onUpdate({
      ...variables,
    });
  };

  const handleOnSend = () => {
    onSend({
      dueDate: dueDate?.toISOString(),
    });
  };

  const handleDateChange = (date: Date | null) => {
    setDueDate(date);
    if (!date) {
      return;
    }

    update({
      dueDate: date.toISOString(),
    });
  };

  const handleAddContactClicked = (event: React.MouseEvent<HTMLDivElement>) => {
    setContactSelectAnchor(event.currentTarget);
  };

  const handleRemoveContactClicked = (contact: AsigneeListContact) => {
    if (contact?.assigne_type === 'user') {
      if (contact.type === 'emp') {
        const newEmployees = requestEmployees?.filter(
          (employee) => employee.uuid !== `${contact?.uuid}`,
        ) || [contact];

        onAssignmentChanged({
          newEmployees,
        });
        return;
      }
    }
    const newContacts =
      requestContacts?.filter((c) => c.uuid !== contact?.uuid) || [];
    if (newContacts.length === 0) {
      onAssignmentChanged({ newContacts, accountId: '' });
      setAccountId(undefined);
      return;
    }
    onAssignmentChanged({ newContacts });
  };

  // it is valid if due date is set, there is a contact(s), or there are employees assigned
  const isValid =
    dueDate && (requestContacts?.length || requestEmployees?.length);

  return (
    <Stack
      width={350}
      bgcolor="grey.50"
      height="100%"
      flexDirection="column"
      marginLeft="auto"
      p="20px 20px 0 20px"
    >
      <Stack flex="0 0 auto" gap="20px">
        <Typography fontWeight={700} paddingBottom="10px" component="h3">
          Send Request
        </Typography>
        {description && <Typography>{description}</Typography>}
        <Divider />
        <TextFieldStyled
          label="Request Title"
          fullWidth
          onChange={handleTitleChange}
          variant="outlined"
          defaultValue={title}
        />
        <Stack gap="5px">
          <Typography fontWeight={700} variant="body2" id="send-due-date">
            Due Date
          </Typography>
          <div className="date-text date-calender d-flex EmailDate">
            <CustomDatePicker
              toolTipLabel={DUE_DATE}
              label={`${DUE_DATE}:`}
              selected={dueDate}
              onChange={handleDateChange}
              minDate={new Date()}
              ariaLabelledBy="send-due-date"
            />
          </div>
        </Stack>
        <Stack gap="10px">
          <Typography fontWeight={700} variant="body2">
            {`Assignees${contacts.length > 0 ? ` (${contacts.length})` : ''}`}
          </Typography>
          {rpaHelperText && (
            <Typography
              fontStyle="italic"
              color={useErrorColor ? 'error' : undefined}
            >
              {rpaHelperText}
            </Typography>
          )}
          {error && (
            <Typography fontStyle="italic" color="error">
              {error}
            </Typography>
          )}
          <Popover
            open={Boolean(contactSelectAnchor)}
            anchorEl={contactSelectAnchor}
            onClose={() => setContactSelectAnchor(null)}
          >
            <Box
              padding="30px"
              sx={(theme) => ({
                border: `2px solid ${theme.palette.common.secondary[300]}`,
                borderRadius: '2px',
              })}
            >
              <ContactSelector
                requestAccountId={accountId}
                requestContactId={requestContacts?.[0]?.value}
                requestEmployeeId={requestEmployees?.[0]?.value}
                onClose={() => setContactSelectAnchor(null)}
                handleClose={() => setContactSelectAnchor(null)}
                onSubmit={handleContactChange}
                contactInputChanged={setRemoveRpaHelperText}
                accountInputChanged={setRemoveRpaHelperText}
                filterEmployees={hideEmployees || Boolean(accountId)}
                filterContacts={requestEmployees?.length}
              />
            </Box>
          </Popover>
        </Stack>
      </Stack>
      <Stack flex="1 1 auto" overflow="auto" pb="4px" height="100%" gap="10px">
        <AsigneeList
          addContactClicked={handleAddContactClicked}
          removeContactClicked={handleRemoveContactClicked}
          contacts={contacts}
          /* we only support single assignee for contacts without an account */
          disableAddContact={
            (contacts.length > 0 &&
              accountId === undefined &&
              !requestEmployees?.length) ||
            isBulk
          }
        />
        {enableBulkSend &&
          !requestContacts?.length &&
          !requestEmployees?.length &&
          workflowType !== 'TaxDeliveryWorkflow' &&
          workflowType !== 'OrganizerWorkflow' && (
            <BulkSelectButton
              contactsUpdated={handleContactsChange}
              contacts={contacts}
            />
          )}
      </Stack>
      <Stack
        marginTop="auto"
        width="100%"
        flexDirection="column"
        flex="0 0 auto"
      >
        <Divider />
        <Stack padding="20px" justifyContent="center">
          <Button
            disabled={disableSend || !isValid}
            onClick={handleOnSend}
            variant="contained"
            size="large"
            fullWidth
          >
            {!isBulk ? 'Send' : 'Send Bulk Request'}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
}
