import { ErrorObjectArray } from 'api/v5/schemas';

/**
 * Simple util to parse a single error message from API error responses
 */
export const parseApiErrorMessage = (error: unknown) => {
  // Parse error from errors array
  const v5Error = error as ErrorObjectArray;
  if (typeof v5Error?.errors?.[0]?.message === 'string') {
    return v5Error.errors[0].message;
  }

  // Parse direct, single error message
  // NOTE: This API error shape is deprecated
  if (typeof (error as any)?.error === 'string') {
    return (error as any)?.error as string;
  }

  return undefined;
};
