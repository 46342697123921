/* eslint-disable consistent-return */
/* eslint-disable react/no-access-state-in-setstate */
import React, { Component } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import _ from 'lodash';
import axios from 'axios';
import { EditorState, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import {
  AlertMessage,
  current_logged_user_name,
  getCurrentPath,
  getStorageData,
  isUserEmployee,
} from 'utilities/utils';
import ConfirmationModal from 'common/ConfirmationModal';
import * as TaskApi from 'api/TaskApi';
import { autoTagApi } from 'api/AutoTag';
import Files from 'api/Files';
import {
  MULTIPLE_FILES_UPLOAD_FOR_SHARING,
  GET_ACCOUNTS_OF_CONTACT_API_PATH,
  MIXPANEL_FILE_UPLOAD_EVENT,
  UPDATE_NEW_DOCUMENT,
  SHARE_PRIVATE_FILES,
} from 'constants/constants';
import { CheckboxField } from 'common/CustomFields';
import { options, removeBreakElementinList } from 'common/NotesUtilities';
import { validDraftText } from 'common/DraftEditorUtilities';
import { trackMixpanelEvent } from 'Mixpanel/mixpanelfn';
import { withRouter } from 'utilities/withRouterWrapper';
import FileRecipientCreation from './FileRecipientCreation';
import FilePopupBottom from './FilePopupBottom';

const noneStyle = { display: 'none' };
const blockStyle = { display: 'block' };
class ClientFilePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formdata: {},
      allContacts: [],
      allAccounts: [],
      contacts: [],
      accounts: [],
      errors: {},
      showRecords: {},
      loading: false,
      recipientDropdownToggle: false,
      contactHasAccount: 0,
      alertText: '',
      uniqueRecords: [],
      fileUploadAccountSide: false,
      accountDetails: {},
      cstMembers: [],
      documents: [],
    };
  }

  componentDidMount() {
    const { uploadType, location, viewContext } = this.props;
    const path =
      viewContext && viewContext === 'addnew' ? '/' : getCurrentPath(location);
    if (uploadType === 'manual' && path !== '/accountdetails/:id') {
      // TODO: __HOMEDATA-AUDIT
      const storageData = getStorageData();
      // TaskApi.getOwners(this, 'clientfilepopup');
      TaskApi.getContactsOfTask(this, { limit: true, message: true }, 'notes');
      if (storageData && Object.keys(storageData).length) {
        axios
          .get(`${GET_ACCOUNTS_OF_CONTACT_API_PATH}/${storageData.contact_id}`)
          .then((res) => {
            if (res.data.status === 200) {
              const { data } = res.data;
              const { formdata } = this.state;
              formdata.accountId =
                data && data.length && data.length === 1 ? data[0] : null;
              this.setState(
                {
                  allAccounts: data,
                  accounts: data,
                  contactHasAccount: data ? data.length : 0,
                  formdata,
                },
                () => {
                  // show emp selected if there is no account
                  const { uniqueRecords, contactHasAccount } = this.state; // unique records state
                  if (contactHasAccount === 0) {
                    const object = {};
                    object.contact = {};
                    object.contact.label = storageData.default_recipient
                      ? storageData.default_recipient.label
                      : '';
                    object.contact.value = storageData.default_recipient
                      ? storageData.default_recipient.value
                      : 0;
                    // object.flag = true;
                    uniqueRecords.push(object);
                  } else if (contactHasAccount === 1) {
                    const { accounts } = this.state;
                    const { cst_member } = accounts[0];
                    if (cst_member && cst_member.length > 0) {
                      // populate with all cst members
                      cst_member.map((each) => {
                        const object = {};
                        if (each.label && each.value) {
                          object.contact = {};
                          object.contact.label = each.label;
                          object.contact.value = each.value;
                        }
                        if (
                          accounts[0] &&
                          accounts[0].label &&
                          accounts[0].value
                        ) {
                          object.account = {};
                          object.account.label = accounts[0].label;
                          object.account.value = accounts[0].value;
                        }
                        // object.flag = true;
                        uniqueRecords.push(object);
                        return object;
                      });
                      this.setState({ cstMembers: cst_member });
                    } else {
                      // populate with default employee
                      const object = {};
                      object.contact = {};
                      object.account = {};
                      object.contact.label = storageData.default_recipient
                        ? storageData.default_recipient.label
                        : '';
                      object.contact.value = storageData.default_recipient
                        ? storageData.default_recipient.value
                        : 0;
                      object.account.label = accounts[0].label;
                      object.account.value = accounts[0].value;
                      // object.flag = true;
                      uniqueRecords.push(object);
                    }
                  }
                  this.setState({ uniqueRecords });
                },
              );
            }
          });
      }
    } else if (
      uploadType === 'task' ||
      uploadType === 'message' ||
      uploadType === 'note'
    ) {
      const { parentState, fileuploadFields } = this.props;
      if (uploadType === 'task' || uploadType === 'note') {
        const object = {};
        let contactName = 'contactName';
        let accountName = 'accountName';
        if (parentState.type && parentState.type === 'todo') {
          contactName = 'refContactName';
          accountName = 'refAccountName';
        }
        if (parentState.stateValues?.[contactName]) {
          object.contact = {};
          object.contact.label = parentState.stateValues[contactName];
        }
        if (parentState.stateValues?.[accountName]) {
          object.account = {};
          object.account.label = parentState.stateValues[accountName];
        }
        if (Object.keys(object).length) {
          const { uniqueRecords } = this.state;
          uniqueRecords.push(object);
          this.setState({ uniqueRecords });
        }
      } else if (uploadType === 'message') {
        let selectedRecords = 'selectedRecords';
        if (this.props.messageComp === 'messageModal') {
          selectedRecords = 'allSelectedRecords';
        }
        if (
          parentState[selectedRecords] &&
          parentState[selectedRecords].length
        ) {
          const { uniqueRecords } = this.state;
          parentState[selectedRecords].map((each) => {
            const object = {};
            if (each.contactName) {
              object.contact = {};
              object.contact.label = each.contactName;
            }
            if (each.accountName) {
              object.account = {};
              object.account.label = each.accountName;
            }
            uniqueRecords.push(object);
            return object;
          });
          this.setState({ uniqueRecords });
        }
      }
      if (Object.keys(fileuploadFields).length) {
        const { formdata } = this.state;
        Object.entries(fileuploadFields).map((entry) => {
          if (
            entry[0] === 'year' ||
            entry[0] === 'month' ||
            entry[0] === 'tags'
          ) {
            formdata[entry[0]] = entry[1];
          }
          return entry;
        });
        this.setState({ formdata });
      }
    } else if (
      path === '/accountdetails/:id' &&
      location.hash &&
      location.hash === '#files'
    ) {
      const { id } = this.props.params;
      // TaskApi.getOwners(this, 'clientfilepopup');
      TaskApi.getContactsOfTask(this, { limit: true, message: true }, 'notes');
      Files.showSelectedAccount(this, id, true);
      this.setState({ fileUploadAccountSide: true });
    }
    if (this.props.shareFile) {
      let { documents } = this.state;
      const { formdata } = this.state;
      const { sharedFileDetails } = this.props;
      if (sharedFileDetails && sharedFileDetails.length) {
        documents = sharedFileDetails;
        let documentsName = '';
        documents.map((each) => {
          documentsName = documentsName.concat(', ', each.doc_name);
          return documentsName;
        });
        formdata.message = `User ${current_logged_user_name()} has shared following file(s): ${documentsName.replace(
          /^,/,
          '',
        )}`;
      }
      this.setState({
        documents,
        formdata,
      });
    }
  }

  recipientDropdownToggle = (toggle = undefined) => {
    const { fileUploadAccountSide, formdata } = this.state;
    this.setState(
      {
        recipientDropdownToggle:
          toggle !== undefined ? toggle : !this.state.recipientDropdownToggle,
      },
      () => {
        if (
          fileUploadAccountSide &&
          Object.keys(this.state.accountDetails).length
        ) {
          formdata.accountId = this.state.accountDetails;
        }
        this.setState({ formdata });
      },
    );
  };

  handleDropDown = (fieldname, input) => {
    let { contacts, cstMembers } = this.state;
    if (this.props.uploadType === 'manual' && fieldname === 'accountId') {
      if (!input && !this.state.formdata.contactId) {
        contacts = this.state.allContacts;
        cstMembers = [];
      } else if (input && !this.state.formdata.contactId) {
        TaskApi.getRelConAndCstOfAccounts(this, input.value);
        if (input && input.cst_member && input.cst_member.length > 0) {
          cstMembers = input.cst_member;
        }
      } else if (input && this.state.formdata.contactId) {
        const indx = this.state.allAccounts.findIndex(
          (x) => x.value === input.value,
        );
        if (indx > -1) {
          const selectedAccount = this.state.allAccounts[indx];
          if (
            selectedAccount &&
            selectedAccount.cst_member &&
            selectedAccount.cst_member.length > 0
          ) {
            cstMembers = selectedAccount.cst_member;
          }
        }
      } else {
        contacts = this.state.allContacts;
        cstMembers = [];
      }
    } else if (fieldname === 'contactId') {
      // console.log('for contact');
      if (
        (!input && !this.state.formdata.accountId) ||
        (input &&
          input.type &&
          input.type === 'emp' &&
          !this.state.formdata.accountId)
      ) {
        this.setState({ accounts: this.state.allAccounts });
      } else if (input && input.accounts && input.accounts.length) {
        this.setState({ accounts: input.accounts });
      }
    }
    this.setState({
      formdata: { ...this.state.formdata, [fieldname]: input },
      cstMembers,
      contacts,
    });
  };

  handleCancel = (block, e) => {
    e.preventDefault();
    const { formdata, contactHasAccount } = this.state;
    if (block === 'all') {
      formdata.contactId = null;
      if (contactHasAccount === 0 || contactHasAccount > 1) {
        formdata.accountId = null;
      }
      this.setState(
        {
          contacts: this.state.allContacts,
          accounts: this.state.allAccounts,
        },
        () => {
          this.recipientDropdownToggle();
        },
      );
    }
    this.setState({
      formdata,
      errors: {},
    });
  };

  handleSubmitValidation() {
    const { formdata, contactHasAccount } = this.state;
    if (
      (!formdata.contactId || formdata.contactId === null) &&
      (!formdata.accountId || formdata.accountId === null)
    ) {
      this.setState({ alertText: 'Please select or save recipient/account' });
      return false;
    }
    if (contactHasAccount > 0 && !formdata.accountId) {
      this.setState({ alertText: 'Please Select Account' });
      return false;
    }
    if (!formdata.contactId) {
      this.setState({ alertText: 'Please select Recipient' });
      return false;
    }
    return true;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { formdata, errors, contactHasAccount } = this.state;
    const validate = this.handleSubmitValidation();
    if (validate) {
      const dataObject = {};
      if (formdata.contactId) {
        dataObject.contact = formdata.contactId;
      }
      if (formdata.accountId) {
        dataObject.account = formdata.accountId;
      }
      this.setState({ showRecords: dataObject }, () => {
        this.validateUniqueRecord(); // Validates the uniqueness of records
        this.recipientDropdownToggle();
      });
      delete formdata.contactId;
      delete errors.toField;
      if (contactHasAccount === 0 || contactHasAccount > 1) {
        delete formdata.accountId;
        // show account selected if contact has only one account in relationship else delete it
      }
      this.setState({
        formdata,
        contacts: this.state.allContacts,
        accounts: this.state.allAccounts,
        errors,
      });
    }
  };

  handleUpload = () => {
    const validate = this.handleUploadValidation();
    const { uploadType } = this.props;
    if (validate) {
      if (uploadType === 'manual') {
        this.handleManualUpload();
      } else if (
        uploadType === 'task' ||
        uploadType === 'message' ||
        uploadType === 'note' ||
        uploadType === 'message_template' ||
        uploadType === 'task_template' ||
        uploadType === 'edoc_template' ||
        uploadType === 'invoice_template'
      ) {
        this.handleOtherUpload();
      }
    }
  };

  handleCancelUpload = () => {
    const { formdata, uniqueRecords } = this.state;
    const { uploadType } = this.props;
    if (
      (uniqueRecords && uniqueRecords.length) ||
      (formdata.tags && formdata.tags.length) ||
      (formdata.documents && formdata.documents.length) ||
      (formdata.month && Object.keys(formdata.month).length) ||
      (formdata.year && Object.keys(formdata.year).length) ||
      formdata.message
    ) {
      this.setState({
        cancelUploadText: 'Are you sure you want to cancel.',
        messageText: 'This action cannot be undone. Proceed?',
      });
    } else if (
      uploadType === 'task' ||
      uploadType === 'message' ||
      uploadType === 'note' ||
      uploadType === 'message_template' ||
      uploadType === 'task_template' ||
      uploadType === 'edoc_template' ||
      uploadType === 'invoice_template'
    ) {
      this.props.handleModalChange(false);
    } else {
      this.props.navigate('/');
    }
  };

  handleUploadValidation() {
    const { formdata, uniqueRecords } = this.state;
    const { uploadType } = this.props;
    let accountOnly = false;
    if (uniqueRecords && uniqueRecords.length) {
      uniqueRecords.map((each) => {
        if (!each.contact) {
          accountOnly = true;
        }
        return accountOnly;
      });
    }
    const checkboxValue = accountOnly ? true : formdata.checkbox;
    const errors = {};
    if (!formdata.makePrivate || formdata.makePrivate === false) {
      if (
        (!uniqueRecords || uniqueRecords.length === 0) &&
        uploadType === 'manual'
      ) {
        errors.toField = 'Required';
      }
      if (
        !checkboxValue &&
        (!formdata.message ||
          (formdata.message &&
            typeof formdata.message === 'object' &&
            !EditorState.createWithContent(convertFromRaw(formdata.message))
              .getCurrentContent()
              .getPlainText().length) ||
          (formdata.message &&
            typeof formdata.message !== 'object' &&
            !formdata.message.length))
      ) {
        errors.message = 'Required';
      }
    }
    if (
      (!formdata.tags || (formdata.tags && !formdata.tags.length)) &&
      isUserEmployee()
    ) {
      errors.tags = 'Required';
    }
    if (
      !formdata.documents ||
      (formdata.documents && !formdata.documents.length)
    ) {
      errors.documents = 'Required';
    }
    this.setState({ errors });
    if (Object.keys(errors).length) {
      return false;
    }
    return true;
  }

  handleManualUpload() {
    const component = this;
    const { formdata, uniqueRecords } = this.state;
    const docIds = [];
    const tagsLabels = [];
    const selectedtags = [];
    component.setState({ loading: true });
    if (formdata.tags && formdata.tags.length) {
      formdata.tags.map((item) => {
        selectedtags.push(item.key);
        tagsLabels.push(item.label);
        return selectedtags;
      });
    }
    if (formdata.documents && formdata.documents.length) {
      Promise.all(
        formdata.documents.map((doc) => {
          docIds.push(doc.id);
          return autoTagApi(
            'feedback',
            { filename: doc.doc_name, tag: tagsLabels.join(', ') },
            true,
          );
        }),
      );
    }
    const array = [];
    if (!formdata.makePrivate) {
      uniqueRecords.map((each) => {
        const newArray = [];
        if (each.contact) {
          newArray[0] = each.contact.value;
        }
        if (each.contact.assigne_type) {
          newArray[2] = each.contact.assigne_type;
        } else {
          newArray[2] = 'user';
        }
        if (each.account) {
          newArray[1] = each.account.value;
        }
        // Note: if contact is not present this line can create error.
        // Change it like employee section. if contact field is not mandatory.
        array.push(newArray);
        return array;
      });
    }
    let msg = formdata.message;
    if (
      typeof formdata.message === 'object' &&
      EditorState.createWithContent(convertFromRaw(formdata.message))
        .getCurrentContent()
        .getPlainText().length
    ) {
      msg = removeBreakElementinList(
        stateToHTML(
          EditorState.createWithContent(
            convertFromRaw(formdata.message),
          ).getCurrentContent(),
          options,
        ),
      );
    }
    const data = {
      doc_ids: docIds,
      message: formdata.makePrivate ? '' : msg,
      // send_message: !formdata.checkbox,
      send_message: !formdata.makePrivate,
      recipients: array,
      month:
        formdata.month && Object.keys(formdata.month).length
          ? formdata.month.value
          : '',
      tags: selectedtags,
      year:
        formdata.year && Object.keys(formdata.year).length
          ? formdata.year.value
          : '',
    };
    if (
      formdata.makePrivate &&
      formdata.makePrivate === true &&
      !isUserEmployee()
    ) {
      data.is_private_to_uploader = true;
    }
    const description = validDraftText(formdata.message);
    if (
      (formdata.message &&
        typeof formdata.message !== 'object' &&
        formdata.message.indexOf(
          `User ${current_logged_user_name()} has shared following file(s):`,
        ) > -1) ||
      (formdata.message &&
        typeof formdata.message === 'object' &&
        description === '')
    ) {
      // data.is_msg_alter = false;
    } else {
      data.is_msg_alter = true;
    }
    component.setState({ loading: true });
    const request = axios.post(MULTIPLE_FILES_UPLOAD_FOR_SHARING, data);
    request
      .then((res) => {
        component.setState({ loading: false });
        if (res.data.status === 200) {
          trackMixpanelEvent(MIXPANEL_FILE_UPLOAD_EVENT, {
            uploadType: 'manual',
            uploader: 'client',
          });
          AlertMessage('success', res.data.message, 3000);
          this.setState({
            formdata: { ...component.state.formdata, documents: [] },
          });
        } else {
          AlertMessage('error', res.data.message, 3000);
        }
        if (this.props.closeModal) {
          this.props.closeModal();
        } else {
          this.props.navigate('/');
        }
      })
      .catch((error) => {
        component.setState({ loading: false });
        console.log('error', error);
      });
  }

  async handleOtherUpload() {
    const component = this;
    const { formdata } = this.state;
    const { uploadType } = this.props;
    const objectToSaved = this.state.formdata;
    const docIds = [];
    const tagsLabels = [];
    const selectedtags = [];
    component.setState({ loading: true });
    if (formdata.tags && formdata.tags.length) {
      formdata.tags.map((item) => {
        selectedtags.push(item.key);
        tagsLabels.push(item.label);
        return selectedtags;
      });
    }
    if (formdata.documents && formdata.documents.length) {
      await Promise.all(
        formdata.documents.map((doc) => {
          docIds.push(doc.id);
          return autoTagApi(
            'feedback',
            { filename: doc.doc_name, tag: tagsLabels.join(', ') },
            true,
          );
        }),
      );
    }
    const data = {
      doc_ids: docIds,
      month:
        formdata.month && Object.keys(formdata.month).length
          ? formdata.month.value
          : '',
      tags: selectedtags,
      year:
        formdata.year && Object.keys(formdata.year).length
          ? formdata.year.value
          : '',
    };
    if (
      uploadType === 'task' ||
      uploadType === 'message_template' ||
      uploadType === 'task_template' ||
      uploadType === 'edoc_template' ||
      uploadType === 'invoice_template'
    ) {
      data.is_draft = true;
    }
    // component.setState({ loading: true });
    const request = axios.post(UPDATE_NEW_DOCUMENT, data);
    request
      .then((res) => {
        component.setState({ loading: false });
        if (res.data.status === 200) {
          trackMixpanelEvent(MIXPANEL_FILE_UPLOAD_EVENT, {
            uploadType,
            uploader: 'client',
          });
          AlertMessage('success', res.data.message, 3000);
          component.props.manageState(objectToSaved);
          setTimeout(() => {
            this.setState(
              { formdata: { ...component.state.formdata, documents: [] } },
              () => {
                component.props.handleModalChange(false);
              },
            );
          }, 300);
        } else {
          AlertMessage('error', res.data.message, 3000);
          component.props.handleModalChange(false);
        }
      })
      .catch((error) => {
        component.setState({ loading: false });
        console.log('error', error);
      });
  }

  handleShare = () => {
    const { formdata, uniqueRecords, documents } = this.state;
    const { uploadType } = this.props;
    const component = this;
    let checkboxValue = formdata.checkbox;
    uniqueRecords.map((each) => {
      if (!each.contact) {
        checkboxValue = true;
      }
      return checkboxValue;
    });
    const errors = {};
    if (
      (!uniqueRecords || uniqueRecords.length === 0) &&
      uploadType === 'manual'
    ) {
      errors.toField = 'Required';
    }
    if (
      !checkboxValue &&
      (!formdata.message ||
        (formdata.message &&
          typeof formdata.message === 'object' &&
          !EditorState.createWithContent(convertFromRaw(formdata.message))
            .getCurrentContent()
            .getPlainText().length) ||
        (formdata.message &&
          typeof formdata.message !== 'object' &&
          !formdata.message.length))
    ) {
      errors.message = 'Required';
    }
    if (!documents.length || documents.length === 0) {
      errors.nofiles = 'No files';
      this.setState({ alertText: 'There are no files attached to share' });
    }
    this.setState({ errors });
    if (Object.keys(errors).length) {
      return false;
    }
    const array = [];
    uniqueRecords.map((each) => {
      const newArray = [];
      if (each.contact) {
        newArray[0] = each.contact.value;
      }
      if (each.contact.assigne_type) {
        newArray[2] = each.contact.assigne_type;
      } else {
        newArray[2] = 'user';
      }
      if (each.account) {
        newArray[1] = each.account.value;
      }
      array.push(newArray);
      return array;
    });
    const docIds = [];
    documents.map((each) => {
      docIds.push(each.id);
      return docIds;
    });
    let msg = formdata.message;
    if (
      typeof formdata.message === 'object' &&
      EditorState.createWithContent(convertFromRaw(formdata.message))
        .getCurrentContent()
        .getPlainText().length
    ) {
      msg = removeBreakElementinList(
        stateToHTML(
          EditorState.createWithContent(
            convertFromRaw(formdata.message),
          ).getCurrentContent(),
          options,
        ),
      );
    }
    const data = {
      doc_ids: docIds,
      recipients: array,
      message: msg,
      send_message: true,
    };
    this.setState({ loading: true });
    const request = axios.post(SHARE_PRIVATE_FILES, data);
    request
      .then((res) => {
        component.setState({ loading: false });
        if (res.data.success === true) {
          AlertMessage('success', res.data.message, 3000);
          if (this.props.closeModal) {
            this.props.closeModal('shared', uniqueRecords);
          } else {
            this.props.navigate('/');
          }
        } else {
          AlertMessage('error', res.data.message, 3000);
        }
      })
      .catch((err) => {
        component.setState({ loading: false });
        console.log(err);
      });
  };

  removeSelectedRecord = (e, each, index) => {
    if (e) {
      e.stopPropagation();
    }
    const { formdata, contactHasAccount } = this.state;
    const selectedRecords = this.state.uniqueRecords;
    delete formdata.contactId;
    if (contactHasAccount === 0 || contactHasAccount > 1) {
      delete formdata.accountId;
      // show account selected if contact has only one account in relationship else delete it
    }
    if (index > -1) {
      selectedRecords.splice(index, 1);
    }
    this.setState({
      formdata,
      showRecords: {},
      uniqueRecords: selectedRecords,
      contacts: this.state.allContacts,
      accounts: this.state.allAccounts,
    });
  };

  updateForm = (fieldname, inputvalue) => {
    let value = inputvalue;
    if (
      fieldname === 'month' ||
      fieldname === 'year' ||
      fieldname === 'entityType'
    ) {
      value = inputvalue || {};
    }
    const message = this.updateMessage(fieldname, inputvalue);
    //  ************************************************
    this.setState({
      formdata: { ...this.state.formdata, [fieldname]: value, message },
      errors: {},
    });
  };

  updateMessage(fieldname, inputvalue) {
    // autopopulate message field when document uploaded
    const { formdata } = this.state;
    let message = formdata.message || '';
    if (fieldname === 'message') {
      message = inputvalue;
    } else if (fieldname === 'documents') {
      const description = validDraftText(formdata.message);
      if (
        !formdata.message ||
        (formdata.message &&
          typeof formdata.message !== 'object' &&
          formdata.message.indexOf(
            `User ${current_logged_user_name()} has shared following file(s):`,
          ) > -1) ||
        (formdata.message &&
          typeof formdata.message === 'object' &&
          description === '')
      ) {
        // auto populate
        let documentsName = '';
        let array = [];
        if (fieldname === 'documents') {
          array = inputvalue;
        } else if (this.props.shareFile) {
          array = this.state.documents;
        } else if (this.state.formdata.documents) {
          array = this.state.formdata.documents;
        }
        // const array = fieldname === 'documents' ? inputvalue : (this.state.formdata.documents || []);
        if (array && array.length) {
          array.map((each) => {
            documentsName = documentsName.concat(', ', each.doc_name);
            return documentsName;
          });
        }
        message = `User ${current_logged_user_name()} has shared following file(s): ${documentsName.replace(
          /^,/,
          '',
        )}`;
      }
    }
    return message;
  }

  validateUniqueRecord() {
    const { showRecords, contactHasAccount, cstMembers } = this.state;
    let { uniqueRecords } = this.state;
    if (
      this.props.uploadType === 'manual' &&
      contactHasAccount > 0 &&
      cstMembers.length
    ) {
      // condition recheck
      cstMembers.map((each) => {
        const object = {};
        object.contact = {};
        object.account = {};
        object.contact.label = each.label;
        object.contact.value = each.value;
        object.account.label = showRecords.account.label;
        object.account.value = showRecords.account.value;
        uniqueRecords.push(object);
        return object;
      });
    }
    uniqueRecords.push(showRecords); // push selected record also
    uniqueRecords = _.uniqBy(uniqueRecords, (elem) => {
      if (elem.account && elem.contact) {
        return [elem.account.value, elem.contact.value].join();
      }
      if (!elem.account) {
        return [elem.contact.value].join();
      }
      if (!elem.contact) {
        return [elem.account.value].join();
      }
    });
    this.setState({ uniqueRecords });
  }

  cancelUploadAlert = () => {
    const { uploadType } = this.props;
    if (this.props.closeModal) {
      this.props.closeModal();
    } else if (
      uploadType === 'task' ||
      uploadType === 'message' ||
      uploadType === 'note' ||
      uploadType === 'message_template' ||
      uploadType === 'task_template' ||
      uploadType === 'edoc_template' ||
      uploadType === 'invoice_template'
    ) {
      this.props.handleModalChange(false);
    } else {
      this.props.navigate('/');
    }
  };

  delDocumentstoShare = (id) => {
    const { documents } = this.state;
    const result = documents.filter((each) => each.id !== id);
    this.setState({ documents: result });
  };

  render() {
    const { openModal, uploadType, fileDropped } = this.props;
    const { formdata } = this.state;
    return (
      <div className="ClientFilePopup">
        <div className="center-wrapper">
          <Modal
            className="customModal customModal--w700 customModal--nopadding"
            isOpen={openModal}
          >
            <div
              id="loading"
              style={this.state.loading ? blockStyle : noneStyle}
            />
            <ModalHeader>
              <div className="ModalHeader">
                <button
                  onClick={
                    this.props.closeModal
                      ? this.props.closeModal
                      : this.handleCancelUpload
                  }
                  type="button"
                  className="close"
                  aria-label="Close"
                >
                  <i className="icon-close2" aria-hidden="true" />
                </button>
              </div>
            </ModalHeader>
            <ModalBody className="modalContent" style={{ maxHeight: 'none' }}>
              <div className="modalContent--inner">
                <div className="modalContent--header">
                  <h5 className="modalContent--header__heading">
                    {`${this.props.shareFile ? 'Share' : 'Upload'} File`}
                  </h5>
                </div>
                <div className="modalForm">
                  <form>
                    <div className="row">
                      {isUserEmployee() &&
                        uploadType === 'manual' &&
                        !this.props.shareFile && (
                          <div className="col-12 text-right">
                            <CheckboxField
                              id="makePrivate"
                              name="notifyViaText"
                              label="Make Private"
                              value={formdata.makePrivate}
                              onChange={() =>
                                this.updateForm(
                                  'makePrivate',
                                  !formdata.makePrivate,
                                )
                              }
                              labelclassName="text-body text-transform-none font-weight-normal"
                            />
                          </div>
                        )}
                      {uploadType === 'manual' && !formdata.makePrivate && (
                        <FileRecipientCreation
                          data={this.state}
                          formdata={formdata}
                          preferences={this.props.preferences}
                          updateForm={this.updateForm}
                          handleContactChange={this.handleDropDown}
                          handleAccountChange={this.handleDropDown}
                          handleCancel={this.handleCancel}
                          handleSubmit={this.handleSubmit}
                          removeSelectedRecord={this.removeSelectedRecord}
                          recipientDropdownToggle={this.recipientDropdownToggle}
                          // props for tasks
                          disableToField={
                            uploadType !== 'manual' ? 'disabled p-0' : ''
                          }
                        />
                      )}
                      <FilePopupBottom
                        updateForm={this.updateForm}
                        data={this.state}
                        formdata={formdata}
                        preferences={this.props.preferences}
                        closeModal={
                          this.props.closeModal
                            ? this.props.closeModal
                            : this.handleCancelUpload
                        }
                        handleUpload={this.handleUpload}
                        handleShare={this.handleShare}
                        shareFile={this.props.shareFile}
                        // props for tasks
                        uploadType={uploadType}
                        fileDropped={fileDropped || false}
                        showMessageInput={
                          uploadType === 'manual' && !formdata.makePrivate
                        }
                        delDocumentstoShare={this.delDocumentstoShare}
                        hideCheckbox
                      />
                    </div>
                  </form>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <ConfirmationModal
            isOpen={this.state.alertText && this.state.alertText.length}
            headerText={this.state.alertText}
            messageText=""
            noBtnClick={() => this.setState({ alertText: '' })}
            yesBtnClick={() => this.setState({ alertText: '' })}
            yesBtnText="OK"
          />
          <ConfirmationModal
            isOpen={
              this.state.cancelUploadText && this.state.cancelUploadText.length
            }
            headerText={this.state.cancelUploadText}
            messageText={this.state.messageText || ''}
            noBtnClick={() => this.setState({ cancelUploadText: '' })}
            yesBtnClick={this.cancelUploadAlert}
            yesBtnText="Yes"
            noBtnText="No"
          />
        </div>
      </div>
    );
  }
}

export default withRouter(ClientFilePopup);
